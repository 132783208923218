import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Select, Input, Button, Row, Col, Card } from "antd";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import GenerateOrderDetailsPDF from "./generateOrderDetailsPDF";
const { Option } = Select;

function ReadQuotation(props) {
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [spinnerState, setSpinnerState] = useState(false);
  const [profileLength, setProfileLength] = useState(0);
  const [wastage, setWastage] = useState(0);
  const [profileMap, setProfileMap] = useState({});
  let { test } = useParams();

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            await getItemById();
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (props.common.shopType === "root") navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setDetails(res);
        setOrderDetails(JSON.parse(res.orderDetails));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = () => {
    if (profileLength == "") return alert("Please select profile length");
    if (wastage === "") return alert("Please enter wastage");
    if (wastage != "" && isNaN(wastage))
      return alert("Please enter wastage in number");
    calculateProfile();
  };

  const calculateProfile = async () => {
    setSpinnerState(true);
    let tempOrder = orderDetails;
    let tempMap = {};
    let targetSum = parseFloat(profileLength * 30.48);
    console.log("targetSum out of loop " + targetSum);

    for (const [key1, value1] of Object.entries(tempOrder)) {
      let profileList = value1.profileList
        ? value1.profileList.length > 0
          ? value1.profileList
          : []
        : [];
      console.log(profileList);

      if (profileList.length > 0) {
        profileList.map((item) => {
          let profile = item.profile;
          if (tempMap[profile] == undefined) {
            tempMap[profile] = {};
            tempMap[profile].profile = profile;
            tempMap[profile].sideArray = [];
            tempMap[profile].cuttingList = [];
            tempMap[profile].combination = [];
            tempMap[profile].count = 0;
            tempMap[profile].joint = false;
          }
          let formula = item.formula.split(",");

          console.log("If");
          for (const [key, value] of Object.entries(value1.dimensionMap)) {
            if (formula[0] != "NA") {
              let widthSide = calSide(
                formula[0],
                value.width,
                value.height,
                value.panelCount,
                wastage,
                item.less,
                item.more
              );
              widthSide = widthSide.toFixed(1);
              let widthCount = calSide(
                formula[1],
                0,
                0,
                value.panelCount,
                0,
                0,
                0
              );
              console.log(widthSide);
              console.log(widthCount);
              while (widthSide > targetSum) {
                tempMap[profile].joint = true;
                widthSide = widthSide - targetSum;
                widthSide = parseFloat(widthSide.toFixed(1));
                tempMap[profile].count =
                  tempMap[profile].count +
                  value.panelCount * value.quantity * 2;
                for (let i = 0; i < value.panelCount * value.quantity * 2; i++)
                  tempMap[profile].combination.push([targetSum]);
              }
              tempMap[profile].sideArray = [
                ...tempMap[profile].sideArray,
                ...Array(widthCount * value.quantity).fill(
                  parseFloat(widthSide)
                ),
              ];
            }

            if (formula[2] != "NA") {
              let heightSide = calSide(
                formula[2],
                value.width,
                value.height,
                value.panelCount,
                wastage,
                item.less,
                item.more
              );

              heightSide = heightSide.toFixed(1);
              let heightCount = calSide(
                formula[3],
                0,
                0,
                value.panelCount,
                0,
                0,
                0
              );
              console.log(heightSide);
              console.log(heightCount);

              while (heightSide > targetSum) {
                tempMap[profile].joint = true;
                heightSide = heightSide - targetSum;
                heightSide = parseFloat(heightSide.toFixed(1));
                tempMap[profile].count =
                  tempMap[profile].count +
                  value.panelCount * value.quantity * 2;
                for (let i = 0; i < value.panelCount * value.quantity * 2; i++)
                  tempMap[profile].combination.push([targetSum]);
              }

              tempMap[profile].sideArray = [
                ...tempMap[profile].sideArray,
                ...Array(heightCount * value.quantity).fill(
                  parseFloat(heightSide)
                ),
              ];
            }
            if (formula[0] != "NA" && formula[2] != "NA") {
              let widthSide = calSide(
                formula[0],
                value.width,
                value.height,
                value.panelCount,
                0,
                item.less,
                item.more
              );
              widthSide = widthSide.toFixed(1);
              let heightSide = calSide(
                formula[2],
                value.width,
                value.height,
                value.panelCount,
                0,
                item.less,
                item.more
              );
              heightSide = heightSide.toFixed(1);
              let count = calSide(formula[1], 0, 0, value.panelCount, 0, 0, 0);
              tempMap[profile].cuttingList.push(
                "(" +
                  widthSide +
                  "cm * " +
                  heightSide +
                  "cm ) * " +
                  count * value.quantity
              );
            } else if (formula[0] != "NA" && formula[2] == "NA") {
              let widthSide = calSide(
                formula[0],
                value.width,
                value.height,
                value.panelCount,
                0,
                item.less,
                item.more
              );
              widthSide = widthSide.toFixed(1);
              let count = calSide(formula[1], 0, 0, value.panelCount, 0, 0, 0);
              tempMap[profile].cuttingList.push(
                "(" + widthSide + "cm ) * " + count * value.quantity
              );
            } else if (formula[0] == "NA" && formula[2] != "NA") {
              let heightSide = calSide(
                formula[2],
                value.width,
                value.height,
                value.panelCount,
                0,
                item.less,
                item.more
              );
              heightSide = heightSide.toFixed(1);
              let count = calSide(formula[3], 0, 0, value.panelCount, 0, 0, 0);
              tempMap[profile].cuttingList.push(
                "(" + heightSide + "cm ) * " + count * value.quantity
              );
            }
          }
        });
      } else {
        console.log("else");
        let profile = "OTHERS";
        if (tempMap[profile] == undefined) {
          tempMap[profile] = {};
          tempMap[profile].profile = profile;
          tempMap[profile].sideArray = [];
          tempMap[profile].cuttingList = [];
          tempMap[profile].combination = [];
          tempMap[profile].count = 0;
          tempMap[profile].joint = false;
        }
        for (const [key, value] of Object.entries(value1.dimensionMap)) {
          let widthSide =
            parseFloat(value.width / value.panelCount) + parseFloat(wastage);
          let heightSide = parseFloat(value.height) + parseFloat(wastage);

          widthSide = parseFloat(widthSide.toFixed(1));
          heightSide = parseFloat(heightSide.toFixed(1));
          tempMap[profile].cuttingList.push(
            "(" +
              parseFloat(value.width / value.panelCount).toFixed(1) +
              "cm * " +
              parseFloat(value.height).toFixed(1) +
              "cm ) * " +
              value.panelCount * 2 * value.quantity
          );

          while (widthSide > targetSum) {
            tempMap[profile].joint = true;
            widthSide = widthSide - targetSum;
            widthSide = parseFloat(widthSide.toFixed(1));
            tempMap[profile].count =
              tempMap[profile].count + value.panelCount * value.quantity * 2;
            for (let i = 0; i < value.panelCount * value.quantity * 2; i++)
              tempMap[profile].combination.push([targetSum]);
          }
          while (heightSide > targetSum) {
            tempMap[profile].joint = true;
            heightSide = heightSide - targetSum;
            heightSide = parseFloat(heightSide.toFixed(1));
            tempMap[profile].count =
              tempMap[profile].count + value.panelCount * value.quantity * 2;
            for (let i = 0; i < value.panelCount * value.quantity * 2; i++)
              tempMap[profile].combination.push([targetSum]);
          }
          tempMap[profile].sideArray = [
            ...tempMap[profile].sideArray,
            ...Array(value.panelCount * 2 * value.quantity).fill(widthSide),
            ...Array(value.panelCount * 2 * value.quantity).fill(heightSide),
          ];
        }
      }
    }
    console.log(JSON.stringify(tempMap));
    for (const [key, value] of Object.entries(tempMap)) {
      let sideArray = value.sideArray;
      let tempSum = targetSum;
      sideArray = sideArray.sort((a, b) => b - a);
      do {
        let arrayTotal = sideArray.reduce(function (a, b) {
          return a + b;
        });
        if (arrayTotal <= tempSum) {
          let subset = [];
          subset.push(...sideArray);
          subset.map((item) => {
            const index1 = sideArray.indexOf(item);
            if (index1 > -1) sideArray.splice(index1, 1);
          });
          value.combination.push(subset);
          value.count++;
        } else {
          let temp = subsetSum(sideArray, tempSum);
          if (temp.length > 0) {
            let subset = temp[0];
            subset.map((item) => {
              const index1 = sideArray.indexOf(item);
              if (index1 > -1) sideArray.splice(index1, 1);
            });
            value.combination.push(subset);
            value.count++;
          } else tempSum = (tempSum - 0.1).toFixed(1);
        }
      } while (sideArray.length > 0);
    }
    console.log(JSON.stringify(tempMap));
    setProfileMap(tempMap);
    setSpinnerState(false);
  };

  const subsetSum = (n, t, p = [], s = 0, r = []) => {
    if (r.length > 0) return r;
    if (s < t) {
      // n.forEach((l, i) => subsetSum(n.slice(i + 1), t, [...p, l], s + l, r));
      for (let i = 0; i < n.length; i++) {
        subsetSum(n.slice(i + 1), t, [...p, n[i]], s + n[i], r); // Recursively search for subsets
      }
    } else if (s == t) r.push(p);

    return r;
  };

  // const subsetSum = (n, t, p = [], s = 0, r = []) => (
  //   s < t
  //     ? n.forEach((l, i) => subsetSum(n.slice(i + 1), t, [...p, l], s + l, r))
  //     : s == t
  //     ? r.push(p)
  //     : 0,
  //   r
  // );

  const calSide = (formula, width, height, panelCount, wastage, less, more) => {
    let count = 0;
    count = eval(
      formula
        .replace(/width/g, width)
        .replace(/height/g, height)
        .replace(/panelCount/g, panelCount)
        .replace(/wastage/g, wastage)
        .replace(/less/g, less)
        .replace(/more/g, more)
    );
    return parseFloat(count);
  };

  const downloadPDF = async () => {
    await pdf(
      <GenerateOrderDetailsPDF
        item={details}
        profileLength={profileLength}
        wastage={wastage}
        profileMap={profileMap}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${details.randomId}_ProfileDetails.pdf`);
      });
  };

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <p className="bill-heading">Order Details: {details.randomId}</p>
        <div style={{ margin: "5px" }}>
          <Card style={{ width: "100%" }}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div className="addBannerFieldStyle">
                  <span className="addBannerFieldName">
                    Select Profile length (feet)
                  </span>

                  <Select
                    className="addProdSelectStyle"
                    value={profileLength}
                    onChange={(value) => {
                      setProfileLength(value);
                      setProfileMap({});
                    }}
                  >
                    <Option value="">Select</Option>
                    <Option value="7">7 Feet</Option>
                    <Option value="12">12 Feet</Option>
                    <Option value="16">16 Feet</Option>
                  </Select>
                </div>
              </Col>
              <Col span={8}>
                <div className="addBannerFieldStyle">
                  <span className="addBannerFieldName">
                    Wastage per cut (in cm)
                  </span>
                  <Input
                    className="addProdInputStyle"
                    value={wastage}
                    onChange={(e) => {
                      setWastage(e.target.value);
                      setProfileMap({});
                    }}
                    maxLength={6}
                  />
                </div>
              </Col>
              <Col span={8}>
                <Button
                  type="primary"
                  onClick={() => checkInput()}
                  style={{ marginTop: 10 }}
                >
                  Calculate Profile
                </Button>
              </Col>
            </Row>
          </Card>
          <Card style={{ width: "100%", marginTop: 10 }}>
            <Row gutter={[16, 16]}>
              <Col span={4} style={{ fontWeight: "bold" }}>
                Profile
              </Col>
              <Col style={{ fontWeight: "bold" }} span={4}>
                Count
              </Col>
              <Col style={{ fontWeight: "bold" }} span={8}>
                Combination
              </Col>
              <Col style={{ fontWeight: "bold" }} span={8}>
                Cutting List
              </Col>
            </Row>
            {Object.entries(profileMap).map(([key, value]) => {
              return (
                <Row gutter={[16, 16]}>
                  <Col span={4}>
                    <span>{key}</span>
                  </Col>
                  <Col span={4}>
                    <div className="addBannerFieldStyle">
                      <span>{value.count}</span>
                      {value.joint && (
                        <span style={{ color: "red" }}>Joint required</span>
                      )}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      {value.combination.map((item, index) => (
                        <p>
                          {index + 1} -{" "}
                          {item.map(
                            (value1) =>
                              parseFloat(value1 - wastage).toFixed(1) + "cm "
                          )}{" "}
                          -{" "}
                          {(
                            parseFloat(profileLength * 30.48) -
                            item.reduce(function (a, b) {
                              return a + b;
                            })
                          ).toFixed(1) + "cm wastage"}
                        </p>
                      ))}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      {value.cuttingList.map((item, index) => (
                        <p>
                          {index + 1} - {item}
                        </p>
                      ))}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Card>
        </div>

        <Card style={{ marginTop: 20 }}>
          <div style={{ backgroundColor: "white" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderBottom: "1px ",
                backgroundColor: "#d1d6dc",
              }}
            >
              <p style={{ fontWeight: "bold", width: "30%" }}>Product Name</p>
              <p style={{ fontWeight: "bold", width: "30%" }}>Instructions</p>
              <p style={{ fontWeight: "bold", width: "20%" }}>Width (cm)</p>
              <p style={{ fontWeight: "bold", width: "20%" }}>Height (cm)</p>
              <p style={{ fontWeight: "bold", width: "20%" }}>Panel Count</p>
              <p style={{ fontWeight: "bold", width: "20%" }}>Quantity</p>
            </div>

            {Object.entries(orderDetails).length > 0 &&
              Object.entries(orderDetails).map(([key, value]) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      borderBottom: "1px solid lightgray",
                    }}
                  >
                    <p style={{ width: "30%" }}>
                      {value.itemName}
                      {/* ({value.itemDescription}) */}
                    </p>
                    <p style={{ width: "30%" }}>
                      {value.instructions != "" ? value.instructions : "NA"}
                    </p>
                    <div
                      style={{
                        flexDirection: "row",
                        width: "80%",
                      }}
                    >
                      {Object.values(value.dimensionMap)
                        .sort((a, b) => a.timestamp - b.timestamp)
                        .map((value1) => {
                          return (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  borderBottom: "1px solid lightgray",
                                }}
                              >
                                <p style={{ width: "20%" }}>{value1.width}</p>
                                <p style={{ width: "20%" }}>{value1.height}</p>
                                <p style={{ width: "20%" }}>
                                  {value1.panelCount}
                                </p>
                                <p style={{ width: "20%" }}>
                                  {value1.quantity}{" "}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </Card>
      </div>
      {!isObjectEmpty(profileMap) && (
        <Button style={{ margin: 10 }} onClick={downloadPDF} type="button">
          PDF
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReadQuotation);
