// firebaseConfig.js
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBfnUGF2VUL28ym-7paGp7PXp6YvYNmVW8",
  // authDomain: "your-auth-domain",
  projectId: "royal-fabrics-mobile",
  storageBucket: "royal-fabrics-mobile.appspot.com",
  messagingSenderId: "915282243193",
  appId: "1:915282243193:android:ac2f9b6673c47a5ce8aea8",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
