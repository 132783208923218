import React, { useState, useEffect } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import "../styles/addBill.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, DatePicker, Select, Card, Table } from "antd";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { getItemByOutletIdPagination } from "./functionCall";
const { Option } = Select;

function DebitReceipt() {
  const navigate = useNavigate();
  const [updatedBy, setupdatedBy] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const [bankName, setBankName] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [invoiceCal, setInvoiceCal] = useState([]);
  let test = useParams();

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "10%",
      ellipsis: true,
      render: (totalAmount) => totalAmount.toFixed(2),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "10%",
      ellipsis: true,
      render: (paymentStatus) => (paymentStatus == "false" ? "Due" : "Done"),
    },
    {
      title: "Payment Done",
      dataIndex: "paymentDone",
      key: "paymentDone",
      width: "10%",
      ellipsis: true,
      render: (paymentDone) => paymentDone.toFixed(2),
    },
    {
      title: "Payment Pending",
      dataIndex: "paymentPending",
      key: "paymentPending",
      width: "10%",
      ellipsis: true,
      render: (paymentPending) => paymentPending.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          getAllInvoice(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("SalesPayments")) navigate("/homePage");
  }, []);

  const updatePaymentDetails = async () => {
    if (parseFloat(installmentAmount) > parseFloat(test.from))
      return alert("Payment pending is less than installment amount");
    if (paymentMode == "") return alert("Please select payment mode");

    setSpinnerState(true);

    try {
      let update = await API.graphql({
        query: mutations.paymentsOutlet,
        variables: {
          id: test.test,
          updatedBy: updatedBy,
          installmentAmount: installmentAmount,
          paymentMode: paymentMode,
          paymentId: paymentId,
          shopId: shopId,
          outletShopId: outletShopId,
          date: date.format("YYYY-MM-DD"),
          bankName: bankName,
        },
      });
      message.error("Payment Details added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const getAllInvoice = async (outletShopId) => {
    let allItems = await getItemByOutletIdPagination(
      "SalesInvoice",
      "live",
      outletShopId,
      test.test
    );
    allItems = allItems
      .filter((item) => item.paymentStatus == "false")
      .reverse();
    setAllInvoice(allItems);
    setInvoiceCal(allItems);
  };

  const getUpdatedInvoiceList = async () => {
    if (installmentAmount == "")
      return alert("Please enter installment amount");
    if (isNaN(installmentAmount))
      return alert("Please enter valid installment amount");
    if (parseFloat(installmentAmount) > parseFloat(test.from))
      return alert("Payment pending is less than installment amount");
    console.log("getUpdatedInvoiceList" + installmentAmount);
    let counter = 0;
    let invoiceList = allInvoice.map((invoice) => ({ ...invoice }));
    let amount = parseFloat(installmentAmount);

    do {
      let PIAmount =
        amount >= invoiceList[counter].paymentPending
          ? invoiceList[counter].paymentPending
          : amount;
      let paymentPending = invoiceList[counter].paymentPending;
      console.log("PIAmount " + PIAmount);
      let newStatus =
        amount >= invoiceList[counter].paymentPending ? "true" : "false";
      invoiceList[counter].paymentDone =
        invoiceList[counter].paymentDone + PIAmount;
      invoiceList[counter].paymentPending =
        invoiceList[counter].paymentPending - PIAmount;
      invoiceList[counter].paymentStatus = newStatus;

      amount = amount - (amount >= paymentPending ? paymentPending : amount);
      console.log("amount " + amount);
      counter++;
    } while (amount > 0);

    setInvoiceCal(invoiceList);
  };

  useEffect(() => {
    if (installmentAmount != "") getUpdatedInvoiceList();
  }, [installmentAmount]);

  return (
    <div style={{ padding: "20px" }}>
      <div>
        <div style={{ fontWeight: "bold" }}>
          <h2>Payment Pending: {test.from}</h2>
        </div>
        <div className="addProductFormContainer">
          <div className="fieldStyle">
            <span className="fieldName">Installment Amount</span>
            <input
              className="addProdInputStyle"
              value={installmentAmount}
              onChange={(e) => setInstallmentAmount(e.target.value)}
              maxLength={6}
            />
          </div>
          <div className="fieldStyle">
            <span className="fieldName">Payment Mode</span>
            <select
              className="addProdInputStyle"
              value={paymentMode}
              onChange={(e) => setPaymentMode(e.target.value)}
            >
              <option value="">Select Payment Mode</option>;
              <option value="Cash">Cash</option>;
              <option value="Net-Banking">Net-Banking</option>;
              <option value="Debit/Credit Card">Debit/Credit Card</option>;
              <option value="G-pay/UPI">G-pay/UPI</option>;
              <option value="Others">Others</option>;
            </select>
          </div>
          <div className="fieldStyle">
            <span className="fieldName">Transaction Id</span>
            <input
              className="addProdInputStyle"
              value={paymentId}
              onChange={(e) => setPaymentId(e.target.value)}
              maxLength={100}
            />
          </div>
          <div className="fieldStyle">
            <span className="fieldName">Date</span>
            <DatePicker
              onChange={(date) => setDate(date)}
              value={date}
              className="addProdInputStyle"
            />
          </div>
          <div className="fieldStyle">
            <span className="fieldName">Bank Name</span>
            <Select
              className="addProdInputStyle"
              value={bankName}
              onChange={(value) => {
                setBankName(value);
              }}
            >
              <Option value="">Select a bank</Option>
              <Option value="HDFC Bank">HDFC Bank</Option>;
              <Option value="Indus Bank">Indus Bank</Option>;
            </Select>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            className="button"
            type="primary"
            disabled={spinnerState}
            style={{ margin: 10, width: 200 }}
            onClick={() => updatePaymentDetails()}
          >
            Pay
          </Button>
        </div>

        <Card style={{ width: "100%" }}>
          <Table
            columns={Columns}
            dataSource={invoiceCal}
            scroll={{ y: 520 }}
            pagination={false}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <strong>Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <strong>
                    {invoiceCal
                      .reduce((sum, bill) => sum + bill.totalAmount, 0)
                      .toFixed(2)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <strong>
                    {
                      invoiceCal.filter((item) => item.paymentStatus == "false")
                        .length
                    }
                    -Due
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <strong>
                    {invoiceCal
                      .reduce((sum, bill) => sum + bill.paymentDone, 0)
                      .toFixed(2)}
                  </strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <strong>
                    {invoiceCal
                      .reduce((sum, bill) => sum + bill.paymentPending, 0)
                      .toFixed(2)}
                  </strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(DebitReceipt);
