import { useNavigate, useParams, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import { TextField, InputAdornment } from "@mui/material";
import { CloseOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { api } from "../index";
import { v4 as uuidv4 } from "uuid";
import GenerateSQFTPDF from "./generateSQFTPDF";
import GenerateDeliveryChalan from "./generateDeliveryChalan";
import GenerateWarranty from "./generateWarranty";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PaymentModal from "./paymentModal";
import QuotHistoryModal from "./quotHistoryModal";
import CustomerDetailsForm from "./customerDetailsForm";
import {
  Row,
  Col,
  Table,
  Button,
  DatePicker,
  Input,
  Select,
  Modal,
  message,
  Descriptions,
  Divider,
  Card,
} from "antd";
import "../styles/addBill.css";
import dayjs from "dayjs";
import {
  getAllItemsPagination,
  getItemsByTypeFunc,
  getAllItemsPagination10,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
const axios = require("axios");

const { Option } = Select;

function AddSQFTCompQuot(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [deliveryNotes, setDeliveryNotes] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [allGroup, setAllGroup] = useState([]);
  const [allComponents, setAllComponents] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [term1, setTerm1] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [modalAddGroups, setModalAddGroups] = useState(false);
  const [modalCalculatePrice, setModalCalculatePrice] = useState(false);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [key, setkey] = useState("");
  const [term, setTerm] = useState("");
  const [randomId, setRandomId] = useState("");
  const [followUpLog, setFollowUpLog] = useState("");
  const [billPrefix, setBillPrefix] = useState("");
  const [status, setStatus] = useState("");
  const [scheduleDate, setScheduleDate] = useState(
    dayjs(moment(new Date()).toDate())
  );
  const [visitDate, setVisitDate] = useState(new Date());
  const [visitTime, setVisitTime] = useState("");
  const [nextStatus, setNextStatus] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [estimatedDelivery, setEstimatedDelivery] = useState(
    dayjs(moment(new Date()).toDate())
  );
  const [deliveryExecutive, setDeliveryExecutive] = useState("");
  const [quotationTrail, setQuotationTrail] = useState("");
  const [historyModal, setHistoryModal] = useState(false);
  const [oldDetails, setOldDetails] = useState([]);
  const [paymentDueDate, setPaymentDueDate] = useState(
    dayjs(moment(new Date()).toDate())
  );
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [distance, setDistance] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [modal, setModal] = useState(false);
  const [outletDetails, setOutletDetails] = useState("");
  const [tinyURL, setTinyURL] = useState("");
  const [leadId, setLeadId] = useState("");
  const [pricePerSQFT, setPricePerSQFT] = useState(0);
  const [PDFNotes, setPDFNotes] = useState("");
  const [paymentConditions, setPaymentConditions] = useState("");
  const [tAndC, setTAndC] = useState("");
  const [pdfModal, setpdfModal] = useState(false);
  const [fromDnldPDF, setFromDnldPDF] = useState(false);
  const [leadSource, setLeadSource] = useState("");
  const [meshColor, setMeshColor] = useState("Other");
  const [profileColor, setProfileColor] = useState("Other");
  const [images, setImages] = useState([]);
  const [imagesURI, setImagesURI] = useState([]);
  const [imagesBase64, setImagesBase64] = useState([]);
  const [areaInSqFt, setAreaInSqFt] = useState("");
  const [calProfileFlag, setCalProfileFlag] = useState("false");
  const [deliveryChalanModal, setDeliveryChalanModal] = useState(false);
  const [driverDetails, setDriverDetails] = useState({
    name: "",
    phone: "",
    vehicleNumber: "",
  });
  const [assignToUser, setToUser] = useState("");
  const [assignToUserId, setToUserId] = useState("");
  const [userList, setUserList] = useState({});
  const [imagesInstall, setImagesInstall] = useState([]);
  const [imagesURIInstall, setImagesURIInstall] = useState([]);
  const [imagesBase64Install, setImagesBase64Install] = useState([]);
  const [dimensionMap, setDimensionMap] = useState({});
  const [instructions, setInstructions] = useState("");
  const [canEdit, setCanEdit] = useState(true);
  const [quotationId, setQuotationId] = useState("");
  const [prodStatus, setProdStatus] = useState("");
  const [allQuotes, setAllQuotes] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [installDt, setInstallDt] = useState(
    dayjs(moment(new Date()).toDate())
  );
  const [type, setType] = useState("");
  const [modalMaterial, setModalMaterial] = useState(false);
  const [color, setColor] = useState("Other");
  const [count, setCount] = useState(1);
  const [SOTotal, setSOTotal] = useState(null);

  let { test, from } = useParams();

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "Profile Color",
      dataIndex: "profileColor",
      key: "profileColor",
      ellipsis: true,
      render: (profileColor) => (profileColor != "" ? profileColor : "NA"),
    },
    {
      title: "Mesh Color",
      dataIndex: "meshColor",
      key: "meshColor",
      ellipsis: true,
      render: (meshColor) => (meshColor != "" ? meshColor : "NA"),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
      render: (color) => (color != "" ? color : "NA"),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      ellipsis: true,
      render: (count) => (count != "" ? count : "NA"),
    },
    {
      title: "Area in sqft",
      dataIndex: "areaInSqFt",
      key: "areaInSqFt",
      ellipsis: true,
    },
    ...(from !== "production"
      ? [
          {
            title: "Price per sqft",
            dataIndex: "pricePerSQFT",
            key: "pricePerSQFT",
            ellipsis: true,
            render: (pricePerSQFT) =>
              pricePerSQFT != "" ? pricePerSQFT : "NA",
          },
        ]
      : []),
    ...(from !== "production"
      ? [
          {
            title: "Total",
            dataIndex: "itemTotal",
            key: "itemTotal",
            ellipsis: true,
            render: (itemTotal) => (
              <a>
                {props.common.symbol} {parseFloat(itemTotal).toFixed(2)}
              </a>
            ),
          },
        ]
      : []),
    {
      title: "View",
      dataIndex: "key",
      key: "key",
      ellipsis: true,
      render: (key, record) =>
        !record.type || record.type == "Group" ? (
          <Button type="primary" onClick={() => editProductGroup(key, record)}>
            {canEdit ? "Edit" : "View"}
          </Button>
        ) : (
          <p />
        ),
    },

    ...(canEdit
      ? [
          {
            title: "Delete",
            dataIndex: "key",
            key: "key",
            ellipsis: true,
            render: (key) => (
              <Button
                type="primary"
                danger
                onClick={() => props.removeFromBill(key)}
              >
                Delete
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListGroups = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (key, record) => (
        <Button type="primary" onClick={() => getComponentDetails(record)}>
          Add
        </Button>
      ),
    },
  ];

  const componentListItems = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
      render: (color, record) => (
        <Select
          style={{ width: "90%" }}
          onChange={(value) => {
            props.changeComponentColor({
              color: value,
              id: record.key ?? record.id,
            });
          }}
          value={color}
        >
          {props.common.colorArray
            .concat(props.common.meshColorArray)
            .map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      ellipsis: true,
      render: (count, record) => (
        <Input
          value={count}
          onChange={(e) => {
            if (e.target.value == ".") {
              props.changeComponentCount({
                count: e.target.value,
                id: record.key ?? record.id,
              });
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else
              props.changeComponentCount({
                count: e.target.value,
                id: record.key ?? record.id,
              });
          }}
          maxLength={10}
        />
      ),
    },
    ...(canEdit
      ? [
          {
            title: "Remove",
            dataIndex: "key",
            key: "key",
            ellipsis: true,
            render: (key) => (
              <Button
                type="primary"
                danger
                onClick={() => props.removeComponent({ id: key })}
              >
                Remove
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "masterPP",
      key: "masterPP",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    ...(props.sqft.componentList && type == "Group"
      ? [
          {
            title: "Add",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id, record) => (
              <Button
                type="primary"
                style={{ width: "70%" }}
                onClick={() => props.addComponent({ value: record })}
              >
                Add
              </Button>
            ),
          },
        ]
      : [
          {
            title: "Add",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id, record) => (
              <Button
                type="primary"
                danger={Object.keys(props.sqft.items).includes(id) ? true : ""}
                style={{ width: "70%" }}
                onClick={() => {
                  if (Object.keys(props.sqft.items).includes(id))
                    props.removeFromBill(id);
                  else {
                    setSelectedGroup(record);
                    setModalMaterial(true);
                  }
                  setModalAddComponents(false);
                }}
              >
                {Object.keys(props.sqft.items).includes(id) ? "Remove" : "Add"}
              </Button>
            ),
          },
        ]),
  ];

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            setAddedBy(user.attributes.email);
            setUpdatedBy(user.attributes.email);
            setShopId(user.attributes["custom:shopId"]);
            setOutletShopId(user.attributes["custom:outletShopId"]);

            let outletDetails = [];

            let allItems = await getItemsByTypeFunc(
              "Component",
              user.attributes["custom:shopId"]
            );

            setAllComponents(
              allItems.sort(function (a, b) {
                if (a.itemName.toLowerCase() < b.itemName.toLowerCase())
                  return -1;
                if (a.itemName.toLowerCase() > b.itemName.toLowerCase())
                  return 1;
                return 0;
              })
            );

            let allGroup = await getItemsByTypeFunc(
              "Group",
              user.attributes["custom:shopId"]
            );

            setAllGroup(
              allGroup.sort(function (a, b) {
                if (a.itemName.toLowerCase() < b.itemName.toLowerCase())
                  return -1;
                if (a.itemName.toLowerCase() > b.itemName.toLowerCase())
                  return 1;
                return 0;
              })
            );

            try {
              const allTodos1 = await API.graphql({
                query: queries.getItemById,
                variables: { id: user.attributes["custom:outletShopId"] },
              });
              let res1 = allTodos1.data.getItemById;
              setOutletDetails(res1);
              outletDetails = res1;
              setBillPrefix(res1.billPrefix);
              setDeliveryExecutive(res1.deliveryExecutive);
            } catch (error) {
              console.log(JSON.stringify(error));
            }
            if (test === "addNewQuotation") {
              let allItems = await getAllItemsPagination(
                "Quotation",
                user.attributes["custom:outletShopId"]
              );
              setAllQuotes(allItems);

              if (from === "quotation")
                props.setBillItems({
                  orderDetails: {},
                  totalAmount: 0,
                  subTotal: 0,
                  GST: 0,
                  GSTAmount: 0,
                  discountValue: 0,
                  discountType: "flat",
                  discountAmount: 0,
                  extraCharges: 0,
                });
              else
                await getItemById(
                  outletDetails,
                  user.attributes["custom:shopId"],
                  user.attributes["custom:outletShopId"]
                );
            } else {
              await getItemById(
                outletDetails,
                user.attributes["custom:shopId"],
                user.attributes["custom:outletShopId"]
              );
            }
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (
      !checkAccess("Quotation") &&
      !checkAccess("Order") &&
      !checkAccess("Production") &&
      !checkAccess("Installation")
    )
      navigate("/homePage");
  }, []);

  const getItemById = async (outletDetails, shopId, outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) {
        navigate(-1);
      } else {
        setCanEdit(
          (from === "quotation" && res.status !== "Convert to Order") ||
            (from === "order" &&
              res.status === "live" &&
              res.outletShopId == outletShopId) ||
            (from === "production" &&
              (res.status === "live" ||
                res.status === "Cutting Complete" ||
                res.status === "Mesh Process"))
            ? true
            : false
        );
        setOldDetails(res);
        setName(res.name);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setEmail(res.email);
        setAddress(res.address);
        setShippingAddress(res.shippingAddress);
        setDate(res.addedDate);
        setTime(res.time);
        setAddedBy(res.addedBy);
        setNotes(res.notes);
        setDeliveryNotes(res.deliveryNotes);
        setStatus(res.status);
        setDistance(res.distance);
        setFollowUpLog(JSON.parse(res.followUpLog) ?? {});
        setQuotationTrail(
          res.quotationTrail ? JSON.parse(res.quotationTrail) : {}
        );
        setTinyURL(res.tinyURL ?? "");
        setLeadId(res.leadId ?? "");

        setPDFNotes(res.PDFNotes ?? outletDetails.PDFNotes);
        setPaymentConditions(
          res.paymentConditions ?? outletDetails.paymentConditions
        );
        setTAndC(res.tAndC ?? outletDetails.tAndC);
        setPaymentDetails(JSON.parse(res.paymentDetails));
        if (res.visitDate) setVisitDate(res.visitDate);
        if (res.visitTime) setVisitTime(res.visitTime);
        setLeadSource(res.leadSource ?? "");
        setImages(res.itemImages ?? []);
        setImagesURI(res.itemImages ?? []);
        setImagesBase64(res.itemImages ?? []);
        setImagesInstall(res.itemImagesInstall ?? []);
        setImagesURIInstall(res.itemImagesInstall ?? []);
        setRandomId(res.randomId);
        setQuotationId(res.quotationId ?? "");
        if (res.estimatedDelivery != null)
          setEstimatedDelivery(dayjs(moment(res.estimatedDelivery).toDate()));
        else setEstimatedDelivery("");
        if (res.installDt != null)
          setInstallDt(dayjs(moment(res.installDt).toDate()));
        else setInstallDt("");
        if (res.deliveryExecutive) setDeliveryExecutive(res.deliveryExecutive);
        // console.log("600" + res.orderDetails);
        if (res.driverDetails) setDriverDetails(JSON.parse(res.driverDetails));
        props.setBillItems({
          orderDetails: JSON.parse(res.orderDetails),
          totalAmount: res.totalAmount,
          subTotal: res.subTotal,
          GST: res.GST,
          GSTAmount: res.GSTAmount,
          discountValue: res.discountValue,
          discountType: res.discountType,
          discountAmount: res.discountAmount,
          extraCharges: res.extraCharges,
        });
        if (res.assignToUserId) setToUser(res.assignToUser);

        if (
          res.status == "Delivered" ||
          res.status == "Assign To RF" ||
          res.status == "Pending"
        ) {
          let allItems = await getItemsByTypeFunc(
            props.common.shopName + " User",
            shopId
          );
          if (props.common.shopType == "outlet")
            allItems = allItems.filter(
              (item) => item.outletShopId == outletShopId
            );
          let temp = {};
          allItems.map((item) => {
            temp[item.id] = {};
            temp[item.id].id = item.id;
            temp[item.id].email = item.email;
          });
          setUserList(temp);
        }
        if (res.prodOrderId) await getProdStatus(res.prodOrderId);
        if (res.orderId) await getSOTotal(res.orderId);
      }
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };

  const getSOTotal = async (id) => {
    try {
      const allTodos1 = await API.graphql({
        query: queries.getItemById,
        variables: { id: id },
      });
      let res = allTodos1.data.getItemById;
      setSOTotal(res.totalAmount);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getProdStatus = async (id) => {
    try {
      const allTodos1 = await API.graphql({
        query: queries.getItemById,
        variables: { id: id },
      });
      let res = allTodos1.data.getItemById;
      setProdStatus(res.status);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async (image, imageInstall) => {
    let tempHistory = [];
    let tempTrail = quotationTrail;

    if (oldDetails.name !== name)
      tempHistory.push({
        name: "Customer Name",
        oldValue: oldDetails.name,
        newValue: name,
      });

    if (oldDetails.phoneNumber.slice(3, 13) !== phoneNumber)
      tempHistory.push({
        name: "Customer Phone",
        oldValue: oldDetails.phoneNumber,
        newValue: "+91" + phoneNumber,
      });

    if (oldDetails.email !== email)
      tempHistory.push({
        name: "Customer Email",
        oldValue: oldDetails.email,
        newValue: email,
      });

    if (oldDetails.notes !== notes)
      tempHistory.push({
        name: "Notes",
        oldValue: oldDetails.notes,
        newValue: notes,
      });
    if (oldDetails.deliveryNotes !== deliveryNotes)
      tempHistory.push({
        name: "Delivery Notes",
        oldValue: oldDetails.deliveryNotes,
        newValue: deliveryNotes,
      });
    if (oldDetails.distance !== distance)
      tempHistory.push({
        name: "Distance",
        oldValue: oldDetails.distance,
        newValue: distance,
      });
    if (oldDetails.address !== address)
      tempHistory.push({
        name: "Billing Address",
        oldValue: oldDetails.address,
        newValue: address,
      });
    if (oldDetails.shippingAddress !== shippingAddress)
      tempHistory.push({
        name: "Shipping Address",
        oldValue: oldDetails.shippingAddress,
        newValue: shippingAddress,
      });
    if (oldDetails.leadSource !== leadSource)
      tempHistory.push({
        name: "Lead Source",
        oldValue: oldDetails.leadSource,
        newValue: leadSource,
      });
    if (oldDetails.status !== status)
      tempHistory.push({
        name: "Status",
        oldValue: oldDetails.status,
        newValue: status,
      });
    if ((oldDetails.discountType ?? 0) != props.sqft.discountType)
      tempHistory.push({
        name: "Discount Type",
        oldValue: oldDetails.discountType ?? 0,
        newValue: props.sqft.discountType,
      });
    if ((oldDetails.discountValue ?? 0) != props.sqft.discountValue)
      tempHistory.push({
        name: "Discount Value",
        oldValue:
          props.common.symbol +
            parseFloat(oldDetails.discountValue).toFixed(2) ?? 0,
        newValue:
          props.common.symbol + parseFloat(props.sqft.discountValue).toFixed(2),
      });
    if ((oldDetails.discountAmount ?? 0) != props.sqft.discountAmount)
      tempHistory.push({
        name: "Discount Amount",
        oldValue:
          props.common.symbol +
            parseFloat(oldDetails.discountAmount).toFixed(2) ?? 0,
        newValue:
          props.common.symbol +
          parseFloat(props.sqft.discountAmount).toFixed(2),
      });
    if ((oldDetails.extraCharges ?? 0) != props.sqft.extraCharges)
      tempHistory.push({
        name: "Extra Charges",
        oldValue: oldDetails.extraCharges ?? 0,
        newValue: props.sqft.extraCharges,
      });
    if ((oldDetails.GST ?? 0) != props.sqft.GST)
      tempHistory.push({
        name: "GST",
        oldValue: oldDetails.GST ?? 0 + "%",
        newValue: props.sqft.GST + "%",
      });
    if ((oldDetails.totalAmount ?? 0) != props.sqft.total)
      tempHistory.push({
        name: "Total",
        oldValue:
          props.common.symbol + parseFloat(oldDetails.totalAmount).toFixed(2) ??
          0,
        newValue: props.common.symbol + parseFloat(props.sqft.total).toFixed(2),
      });

    var oldItems = JSON.parse(oldDetails.orderDetails);
    var items = { ...props.sqft.items };
    Object.entries(oldItems).map(([key1, value1]) => {
      if (Object.keys(items).includes(key1)) {
        if (value1.itemTotal != items[key1].itemTotal) {
          tempHistory.push({
            name: "Modified: " + value1.itemName,
            oldValue: value1.itemTotal,
            newValue: items[key1].itemTotal,
          });
          delete items[key1];
        } else delete items[key1];
      } else
        tempHistory.push({
          name: "Deleted: " + value1.itemName,
          oldValue: value1.itemTotal,
          newValue: "",
        });
    });

    if (Object.entries(items).length > 0) {
      Object.entries(items).map(([key2, value2]) => {
        tempHistory.push({
          name: "Added: " + value2.itemName,
          oldValue: "",
          newValue: value2.itemTotal,
        });
      });
    }

    // return alert(JSON.stringify(tempHistory))

    if (tempHistory.length > 0) {
      const id = uuidv4();
      tempTrail[id] = {};
      tempTrail[id].id = id;
      tempTrail[id].updatedBy = updatedBy;
      tempTrail[id].date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      tempTrail[id].history = tempHistory;
    }

    let paymentDetailsTemp = { ...paymentDetails };
    if ((oldDetails.totalAmount ?? 0) != props.sqft.total) {
      paymentDetailsTemp.totalAmount = Math.round(props.sqft.total);
      paymentDetailsTemp.paymentPending = Math.round(
        props.sqft.total - paymentDetails.paymentDone
      );
      paymentDetails.status = "Unpaid";
    }

    setPaymentDetails(paymentDetailsTemp);

    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          email: email,
          address: address,
          shippingAddress: shippingAddress,
          notes: notes,
          deliveryNotes: deliveryNotes,
          distance: distance,
          orderDetails: JSON.stringify(props.sqft.items),
          updatedBy: updatedBy,
          totalAmount: props.sqft.total,
          subTotal: props.sqft.subTotal,
          GST: props.sqft.GST,
          GSTAmount: props.sqft.GSTAmount,
          discountValue: props.sqft.discountValue,
          discountType: props.sqft.discountType,
          discountAmount: props.sqft.discountAmount,
          extraCharges: props.sqft.extraCharges,
          quotationTrail: JSON.stringify(tempTrail),
          addedDate: date,
          status: status,
          time: time,
          itemImages: image.length > 0 ? image : images,
          itemImagesInstall:
            imageInstall.length > 0 ? imageInstall : imagesInstall,
          paymentDetails: JSON.stringify(paymentDetailsTemp),
          leadSource: leadSource,
        },
      });
      if (from === "quotation") {
        alert("Quotation updated");
        navigate(-1);
      }
      if (from === "production" || from === "install") {
        alert("Order updated");
        navigate(-1);
      } else {
        setFromDnldPDF(false);
        setpdfModal(true);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editPDFDetails = async () => {
    console.log("editPDFDetails");
    if (PDFNotes == "" || PDFNotes == null)
      return alert("Please enter PDF notes");
    if (paymentConditions == "" || paymentConditions == null)
      return alert("Please enter payment conditions");
    if (tAndC == "" || tAndC == null)
      return alert("Please enter terms and conditions");
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test,
          PDFNotes: PDFNotes,
          paymentConditions: paymentConditions,
          tAndC: tAndC,
        },
      });
      setpdfModal(false);
      setModal(false);
      if (fromDnldPDF) downloadPDF();
      else generatePDF();
      // else updateStatus(status);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editDriverDetails = async () => {
    if (driverDetails.name == "") return alert("Please enter driver name");
    if (driverDetails.phone == "") return alert("Please enter phone number");
    if (driverDetails.vehicleNumber == "")
      return alert("Please enter vehicle number");
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test,
          driverDetails: JSON.stringify(driverDetails),
        },
      });
      setDeliveryChalanModal(false);
      alert("Details updated");
      await pdf(
        <GenerateDeliveryChalan
          item={oldDetails}
          outletDetails={outletDetails}
          PDFNotes={PDFNotes}
          paymentConditions={paymentConditions}
          tAndC={tAndC}
          symbol={props.common.symbol}
          paymentDetails={paymentDetails}
          driverDetails={driverDetails}
        />
      )
        .toBlob()
        .then(async (blob) => {
          saveAs(blob, `Delivery Challan ${randomId}.pdf`);
        });
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const updateAssignInstall = async (value) => {
    if (value == "") return alert("Please select a user");
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test,
          assignToUser: userList[value].email,
          assignToUserId: value,
          updatedBy: updatedBy,
        },
      });
      alert("Assined to " + userList[value].email);
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async (image, imageInstall) => {
    const today = new Date();
    const year = today.getFullYear();
    let lastQtNo = undefined;

    let allItems = await getAllItemsPagination10("Quotation", outletShopId);

    if (allItems.length > 0) lastQtNo = allItems[0].randomId;
    else lastQtNo = "QT-" + billPrefix.toUpperCase() + "-" + year + "-0";

    let tempCounter =
      lastQtNo.slice(7, 11) == year ? parseInt(lastQtNo.slice(12)) + 1 : 1;
    let tempId =
      "QT-" + billPrefix.toUpperCase() + "-" + year + "-" + tempCounter;

    let paymentDetails = {
      totalAmount: Math.round(props.sqft.total),
      paymentPending: Math.round(props.sqft.total),
      paymentDueDate: moment(paymentDueDate).add(1, "M").format("YYYY-MM-DD"),
      paymentDone: 0.0,
      details: {},
      status: "Unpaid",
    };

    try {
      let bill = await API.graphql({
        query: mutations.addItem,
        variables: {
          type: "Quotation",
          addedBy: addedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          email: email,
          address: address,
          shippingAddress: shippingAddress,
          notes: notes,
          deliveryNotes: deliveryNotes,
          distance: distance,
          orderDetails: JSON.stringify(props.sqft.items),
          totalAmount: props.sqft.total,
          time: moment(new Date()).format("HH:mm:ss"),
          subTotal: props.sqft.subTotal,
          GST: props.sqft.GST,
          GSTAmount: props.sqft.GSTAmount,
          discountValue: props.sqft.discountValue,
          discountType: props.sqft.discountType,
          discountAmount: props.sqft.discountAmount,
          extraCharges: props.sqft.extraCharges,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: tempId,
          paymentDetails: JSON.stringify(paymentDetails),
          itemImages: image.length > 0 ? image : images,
          itemImagesInstall:
            imageInstall.length > 0 ? imageInstall : imagesInstall,
          leadSource: leadSource,
          orderLocation: props.common.location,
        },
      });
      alert("Quotation Added");
      navigate(-1);
      let allUsers = await getItemsByTypeFunc(
        props.common.shopName + " User",
        shopId
      );
      allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

      let tokenMobile = allUsers
        .filter((item) => item.token && item.token !== "")
        .map((item) => item.token);
      let tokenWeb = allUsers
        .filter((item) => item.tokenWeb && item.tokenWeb !== "")
        .map((item) => item.tokenWeb);
      let tokenTemp = tokenMobile.concat(tokenWeb);

      // let tokenTemp = allUsers
      //   .filter((item) => item.token && item.token !== "")
      //   .map((item) => item.token);

      if (tokenTemp.length > 0)
        await sendNotificationStatus(
          tokenTemp,
          "Quotation added for customer " + name,
          "Quotation added"
        );
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const generatePDF = async () => {
    console.log("generatePDF");
    let newDetails = {
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      address: address,
      shippingAddress: shippingAddress,
      randomId: randomId,
      addedDate: date,
      orderDetails: JSON.stringify(props.sqft.items),
      subTotal: props.sqft.subTotal,
      discountAmount: props.sqft.discountAmount,
      extraCharges: props.sqft.extraCharges,
      GSTAmount: props.sqft.GSTAmount,
      totalAmount: props.sqft.total,
    };
    const blob = await pdf(
      <GenerateSQFTPDF
        item={newDetails}
        outletDetails={outletDetails}
        PDFNotes={PDFNotes}
        paymentConditions={paymentConditions}
        tAndC={tAndC}
        symbol={props.common.symbol}
        paymentDetails={paymentDetails}
        from={from}
        SOTotal={SOTotal}
      />
    ).toBlob();

    const arrayBuffer = await blob.arrayBuffer();
    const pdfContent = new Uint8Array(arrayBuffer);
    await axios
      .get(api + "getPDFLink", {
        params: {
          id: test,
        },
      })
      .then((response) => {
        const url = response.data.body;
        // Use the pre-signed URL to upload the file
        return axios.put(url, pdfContent, {
          headers: {
            "Content-Type": "application/pdf",
          },
        });
      })
      .then(async (uploadResponse) => {
        console.log("File uploaded successfully:", uploadResponse.data);
        try {
          await API.graphql({
            query: mutations.generatePDF,
            variables: {
              id: test,
            },
          });
          if (from === "order") {
            alert("Order Updated");
            navigate(-1);
          } else {
            updateStatus(status);
          }
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const checkInput = async () => {
    if (name === "") return message.error("Please enter customer name");
    if (phoneNumber === "") return message.error("Please enter customer phone");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");
    // if (email === "") return message.error("Please enter email");
    if (leadSource === "") return message.error("Please select lead source");
    var mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== "" && mailReg.test(email) === false)
      return message.error("Please enter valid email");
    if (Object.entries(props.sqft.items).length == 0)
      return message.error("Item list cannot be empty");
    if (props.sqft.GST === "") return message.error("Please select GST");
    if (props.sqft.discountValue === "")
      return message.error("Please enter discount");
    if (
      props.sqft.discountValue != "" &&
      props.sqft.discountValue > 0 &&
      props.sqft.discountType === ""
    )
      return message.error("Please select type of discount");
    if (isNaN(props.sqft.total)) return;
    setSpinnerState(true);
    var tempImage = [...imagesURI];
    let tempArray = oldDetails.itemImages ?? [];
    if (imagesBase64.length > 0)
      for (let i = 0; i < imagesURI.length; i++) {
        if (
          (test === "addNewQuotation" && imagesURI.length > 0) ||
          (test != "addNewQuotation" && !tempArray.includes(imagesURI[i]))
        ) {
          try {
            const response = await fetch(api + "uploadImage", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                base64: imagesBase64[i],
                fileName: uuidv4() + ".jpeg",
              }),
            });
            const responseJson = await response.json();
            tempImage[i] = responseJson.Location;
            // counter++;
            console.log("Image changed at " + i);
          } catch (error) {
            console.error(error);
          }
        }
      }

    var tempImageInstall = [];
    if (
      (test === "addNewQuotation" && imagesURIInstall.length > 0) ||
      (test != "addNewQuotation" && imagesURIInstall != imagesInstall)
    )
      // alert("Image upload" + images);
      await Promise.all(
        imagesBase64Install.map(async (item) => {
          try {
            const response = await fetch(api + "uploadImage", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                base64: item,
                fileName: uuidv4() + ".jpeg",
              }),
            });
            const responseJson = await response.json();
            console.log(responseJson.Location);
            tempImageInstall.push(responseJson.Location);
          } catch (error) {
            console.error(error);
          }
        })
      );
    // return alert("hiii");
    if (test === "addNewQuotation") addItem(tempImage, tempImageInstall);
    else editItem(tempImage, tempImageInstall);
  };

  const onImageChange = (event) => {
    if (imagesURI.length + event.target.files.length > 6)
      return alert("Upto 6 images allowed");
    const files = event.target.files;
    const newImagesURI = [...imagesURI];
    const newImagesBase64 = [...imagesBase64];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      newImagesURI.push(URL.createObjectURL(files[i]));
      reader.onload = (e) => {
        newImagesBase64.push(
          e.target.result.replace(/^data:image\/\w+;base64,/, "")
        );
      };
    }
    setImagesBase64(newImagesBase64);
    setImagesURI(newImagesURI);
  };

  const onImageChangeInstall = (event) => {
    if (event.target.files.length > 3) return alert("Upto 3 images allowed");
    const files = event.target.files;
    const newImagesURI = [];
    const newImagesBase64 = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.readAsDataURL(files[i]);
      newImagesURI.push(URL.createObjectURL(files[i]));

      reader.onload = (e) => {
        newImagesBase64.push(
          e.target.result.replace(/^data:image\/\w+;base64,/, "")
        );
        if (newImagesBase64.length === files.length) {
          setImagesBase64Install(newImagesBase64);
          setImagesURIInstall(newImagesURI);
        }
      };
    }
  };

  const updateStatus = async (nextStatus) => {
    console.log("updateStatus");
    setSpinner(true);
    try {
      await API.graphql({
        query: mutations.updateStatus,
        variables: {
          id: test,
          visitDate:
            nextStatus === "Follow Up"
              ? scheduleDate.format("YYYY-MM-DD")
              : visitDate,
          visitTime:
            nextStatus === "Follow Up"
              ? scheduleDate.format("HH:mm:ss")
              : visitTime,
          status: nextStatus,
          rejectReason: rejectReason,
          updatedBy: updatedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          email: email,
          address: address,
          shippingAddress: shippingAddress,
          notes: notes,
          deliveryNotes: deliveryNotes,
          distance: distance,
          totalAmount: props.sqft.total,
          orderDetails: JSON.stringify(props.sqft.items),
          subTotal: props.sqft.subTotal,
          GST: props.sqft.GST,
          GSTAmount: props.sqft.GSTAmount,
          discountValue: props.sqft.discountValue,
          discountType: props.sqft.discountType,
          discountAmount: props.sqft.discountAmount,
          time: new Date().toTimeString().substr(0, 8),
          paymentDueDate: paymentDueDate.toISOString().substr(0, 10),
          randomId: randomId,
          shopId: shopId,
          outletShopId: outletShopId,
          quotationTrail: JSON.stringify(quotationTrail),
          leadId: leadId,
          outletType: props.common.outletType,
          location: props.common.location,
          PDFNotes: PDFNotes,
          paymentConditions: paymentConditions,
          tAndC: tAndC,
          paymentDetails: JSON.stringify(paymentDetails),
          itemImages: images,
          extraCharges: props.sqft.extraCharges,
          leadSource: leadSource,
        },
      });

      alert("Status updated");
      setSpinner(false);
      setSpinnerState(false);
      setModal(false);
      navigate("/ListQuotations/homepage");

      console.log("Notification start");
      let allUsers = await getItemsByTypeFunc(
        props.common.shopName + " User",
        shopId
      );
      allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

      let tokenMobile = allUsers
        .filter((item) => item.token && item.token !== "")
        .map((item) => item.token);
      let tokenWeb = allUsers
        .filter((item) => item.tokenWeb && item.tokenWeb !== "")
        .map((item) => item.tokenWeb);
      let tokenTemp = tokenMobile.concat(tokenWeb);

      // let tokenTemp = allUsers
      //   .filter((item) => item.token && item.token !== "")
      //   .map((item) => item.token);

      if (tokenTemp.length > 0)
        await sendNotificationStatus(
          tokenTemp,
          "Quotation " + randomId + " status changed to " + nextStatus,
          "Quotation status updated"
        );
      console.log("Notification end");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const updateOrderStatus = async (nextStatus) => {
    if (estimatedDelivery == "") return alert("Please select delivery date");
    if (deliveryExecutive == "") return alert("Enter delivery executive name");
    if (installDt == "") return alert("Please select installation date");
    setSpinner(true);
    try {
      await API.graphql({
        query: mutations.updateOrderStatus,
        variables: {
          id: test,
          status: nextStatus,
          updatedBy: updatedBy,
          estimatedDelivery: estimatedDelivery
            ? estimatedDelivery.format("YYYY-MM-DD")
            : "",
          deliveryExecutive: deliveryExecutive,
          rejectReason: rejectReason,
          installDt: installDt ? installDt.format("YYYY-MM-DD") : "",
        },
      });

      if (nextStatus == "In-Production") await moveToProduction(nextStatus);
      else if (nextStatus == "Assign To RF") await assignToMaster(nextStatus);
      else {
        message.success("Status changed to " + nextStatus);
        navigate(-1);
        let allUsers = await getItemsByTypeFunc(
          props.common.shopName + " User",
          shopId
        );
        allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

        let tokenMobile = allUsers
          .filter((item) => item.token && item.token !== "")
          .map((item) => item.token);
        let tokenWeb = allUsers
          .filter((item) => item.tokenWeb && item.tokenWeb !== "")
          .map((item) => item.tokenWeb);
        let tokenTemp = tokenMobile.concat(tokenWeb);

        // let tokenTemp = allUsers
        //   .filter((item) => item.token && item.token !== "")
        //   .map((item) => item.token);

        if (tokenTemp.length > 0)
          await sendNotificationStatus(
            tokenTemp,
            "Order " + randomId + " status changed to " + nextStatus,
            "Order status updated"
          );
      }
      setModal(false);
      setSpinnerState(false);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const moveToProduction = async () => {
    setSpinner(true);
    try {
      let prodOrderId = await API.graphql({
        query: mutations.addItem,
        variables: {
          type: "Production",
          addedBy: addedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          email: email,
          address: address,
          shippingAddress: shippingAddress,
          notes: notes,
          deliveryNotes: deliveryNotes,
          estimatedDelivery: estimatedDelivery
            ? estimatedDelivery.format("YYYY-MM-DD")
            : "",
          distance: distance,
          orderDetails: JSON.stringify(props.sqft.items),
          totalAmount: props.sqft.total,
          time: moment(new Date()).format("HH:mm:ss"),
          subTotal: props.sqft.subTotal,
          GST: props.sqft.GST,
          GSTAmount: props.sqft.GSTAmount,
          discountValue: props.sqft.discountValue,
          discountType: props.sqft.discountType,
          discountAmount: props.sqft.discountAmount,
          extraCharges: props.sqft.extraCharges,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: randomId,
          paymentDetails: JSON.stringify(paymentDetails),
          orderId: test,
          quotationId: quotationId,
          visitDate: visitDate,
          visitTime: visitTime,
          paymentDueDate: paymentDueDate.toISOString().substr(0, 10),
          quotationTrail: JSON.stringify(quotationTrail),
          leadId: leadId,
          PDFNotes: PDFNotes,
          paymentConditions: paymentConditions,
          tAndC: tAndC,
          itemImages: images,
          leadSource: leadSource,
          orderBy: oldDetails.orderBy,
          installDt: installDt ? installDt.format("YYYY-MM-DD") : "",
        },
      });

      try {
        await API.graphql({
          query: mutations.editItem,
          variables: {
            id: test,
            prodOrderId: prodOrderId.data.addItem.id,
          },
        });
        message.success("Moved to production");
        navigate("/ListOrder");
        let allUsers = await getItemsByTypeFunc(
          props.common.shopName + " User",
          shopId
        );
        allUsers = allUsers.filter(
          (item) =>
            item.outletShopId == shopId &&
            (item.role == "Owner" ||
              item.role == "Production" ||
              item.role == "ProdManager")
        );

        let tokenMobile = allUsers
          .filter((item) => item.token && item.token !== "")
          .map((item) => item.token);
        let tokenWeb = allUsers
          .filter((item) => item.tokenWeb && item.tokenWeb !== "")
          .map((item) => item.tokenWeb);
        let tokenTemp = tokenMobile.concat(tokenWeb);

        // let tokenTemp = allUsers
        //   .filter((item) => item.token && item.token !== "")
        //   .map((item) => item.token);

        if (tokenTemp.length > 0)
          await sendNotificationStatus(
            tokenTemp,
            "Work order " + randomId + " moved to production",
            "WO moved to production"
          );
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const assignToMaster = async () => {
    setSpinner(true);

    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let billPrefix = "";
    try {
      const allTodos1 = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      let res = allTodos1.data.getItemById;

      billPrefix = res.billPrefix;
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    let allItems = await getAllItemsPagination10("Order", shopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "WO-" + billPrefix.toUpperCase() + "-" + year + "-0";

    let tempCounter =
      lastNumber.slice(7, 11) == year ? parseInt(lastNumber.slice(12)) + 1 : 1;
    let tempId =
      "WO-" + billPrefix.toUpperCase() + "-" + year + "-" + tempCounter;

    let total = 0;
    var temp = { ...props.sqft.items };

    Object.entries(temp).map(([key, value]) => {
      allGroup.map((item) => {
        if (item.id == value.id) {
          let subTotal = 0;
          subTotal =
            parseFloat(value.areaInSqFt) *
            parseFloat(JSON.parse(item.outletSQFTMap)[shopId].pricePerSQFT);
          total = total + subTotal;
          value.itemTotal = subTotal;
          value.pricePerSQFT = JSON.parse(item.outletSQFTMap)[
            shopId
          ].pricePerSQFT;
        }
      });
    });

    let paymentDetails = {
      totalAmount: Math.round(total),
      paymentPending: Math.round(total),
      paymentDueDate: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
      paymentDone: 0,
      details: {},
      status: "Unpaid",
    };

    try {
      let newOrderId = await API.graphql({
        query: mutations.addItem,
        variables: {
          type: "Order",
          status: "live",
          addedBy: addedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          email: email,
          address: address,
          shippingAddress: shippingAddress,
          notes: notes,
          deliveryNotes: deliveryNotes,
          distance: distance,
          visitDate: visitDate,
          visitTime: visitTime,
          randomId: tempId,
          quotationId: quotationId,
          time: moment(new Date()).format("HH:mm:ss"),
          orderDetails: JSON.stringify(temp),
          totalAmount: total,
          subTotal: total,
          GST: 0,
          GSTAmount: 0,
          discountValue: 0,
          discountType: "flat",
          discountAmount: 0,
          extraCharges: 0,
          shopId: shopId,
          outletShopId: shopId,
          paymentDetails: JSON.stringify(paymentDetails),
          orderId: test,
          orderBy: outletShopId,
          orderLocation: props.common.location,
          itemImages: images,
          estimatedDelivery: estimatedDelivery
            ? estimatedDelivery.format("YYYY-MM-DD")
            : "",
          deliveryExecutive: deliveryExecutive,
          leadSource: leadSource,
          installDt: installDt ? installDt.format("YYYY-MM-DD") : "",
        },
      });

      try {
        await API.graphql({
          query: mutations.editItem,
          variables: {
            id: test,
            masterOrderId: newOrderId.data.addItem.id,
          },
        });
        message.success("Assigned to RF");
        navigate("/ListOrder");
        let allUsers = await getItemsByTypeFunc(
          props.common.shopName + " User",
          shopId
        );
        allUsers = allUsers.filter(
          (item) =>
            item.outletShopId == shopId &&
            (item.role == "Owner" ||
              item.role == "Order" ||
              item.role == "Executive")
        );

        let tokenMobile = allUsers
          .filter((item) => item.token && item.token !== "")
          .map((item) => item.token);
        let tokenWeb = allUsers
          .filter((item) => item.tokenWeb && item.tokenWeb !== "")
          .map((item) => item.tokenWeb);
        let tokenTemp = tokenMobile.concat(tokenWeb);

        // let tokenTemp = allUsers
        //   .filter((item) => item.token && item.token !== "")
        //   .map((item) => item.token);

        if (tokenTemp.length > 0)
          await sendNotificationStatus(
            tokenTemp,
            "Work order recevied from outlet " + props.common.location,
            "New work order received"
          );
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const updateProductionStatus = async (nextStatus) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test,
          status: nextStatus,
          updatedBy: updatedBy,
          updatedDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      });
      if (nextStatus == "Packed")
        try {
          await API.graphql({
            query: mutations.updateBillInventory,
            variables: {
              id: test,
            },
          });
        } catch (error) {
          console.log(JSON.stringify(error));
        }

      message.success("Status changed to " + nextStatus);
      if (from == "install") navigate("/ListInstallation");
      else navigate("/listProduction");

      let allUsers = await getItemsByTypeFunc(
        props.common.shopName + " User",
        shopId
      );
      allUsers = allUsers.filter(
        (item) =>
          item.outletShopId == shopId &&
          (item.role == "Owner" ||
            item.role == "Production" ||
            item.role == "ProdManager")
      );

      let tokenMobile = allUsers
        .filter((item) => item.token && item.token !== "")
        .map((item) => item.token);
      let tokenWeb = allUsers
        .filter((item) => item.tokenWeb && item.tokenWeb !== "")
        .map((item) => item.tokenWeb);
      let tokenTemp = tokenMobile.concat(tokenWeb);

      // let tokenTemp = allUsers
      //   .filter((item) => item.token && item.token !== "")
      //   .map((item) => item.token);

      if (tokenTemp.length > 0)
        await sendNotificationStatus(
          tokenTemp,
          "Production status of " + randomId + " changed to " + nextStatus,
          "Production status changed"
        );
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const takeInputs = (nextStatus) => {
    var oldItems = JSON.parse(oldDetails.orderDetails);

    let flag = false;

    if (nextStatus == "In-Production")
      Object.entries(oldItems).map(async ([key, value]) => {
        if (!value.type || value.type == "Group")
          if (Object.entries(value.dimensionMap).length == 0) {
            flag = true;
            return alert("Please enter dimensions for " + value.itemName);
          }
      });

    if (flag) return;

    let flag1 = false;

    if (nextStatus == "Packed")
      Object.entries(oldItems).map(async ([key, value]) => {
        Object.entries(value.componentList).map(([key1, value1]) => {
          if (value1.count == "" || value1.count == 0 || value1.color == "") {
            flag1 = true;
            return alert(
              "Please enter count and color for " +
                value1.itemName +
                " for product " +
                value.itemName +
                ".\nSAVE ORDER and try again"
            );
          }
        });
        if (flag1 == true) return;
      });

    if (flag1) return;

    var msgText = "Are you sure you want to ";
    var statusText = "";
    if (nextStatus === "live") statusText = "make it live again?";
    if (nextStatus === "Follow Up") statusText = "schedule a visit?";
    if (nextStatus === "Convert to Order")
      statusText = "convert quotation to order?";
    if (nextStatus === "Rejected") statusText = "reject this quotation?";
    if (nextStatus === "In-Production")
      statusText = "start production for this order?";
    if (nextStatus === "Assign To RF")
      statusText = "assign this order to master?";
    if (nextStatus === "Cutting Complete")
      statusText = "mark this order cutting complete?";
    if (nextStatus === "Mesh Process")
      statusText = "mark this order mesh process complete?";
    if (nextStatus === "Packed") statusText = "mark this order packed?";
    if (nextStatus === "Dispatched") statusText = "mark this order dispatched?";
    if (nextStatus === "Delivered") statusText = "mark this order Delivered?";
    if (nextStatus === "Installed") statusText = "mark this order installed?";
    if (nextStatus === "Pending") statusText = "mark this order pending?";

    if (window.confirm(msgText + statusText)) {
      setStatus(nextStatus);
      setSpinnerState(true);
      if (from === "quotation") {
        if (
          nextStatus === "Follow Up" ||
          nextStatus === "Rejected" ||
          nextStatus === "Convert to Order"
        )
          setModal(true);
        else {
          updateStatus(nextStatus);
        }
      } else if (from === "order") {
        if (
          nextStatus == "In-Production" ||
          nextStatus == "Assign To RF" ||
          nextStatus == "Pending"
        )
          setModal(true);
        else {
          updateOrderStatus(nextStatus);
        }
      } else if (from === "production") updateProductionStatus(nextStatus);
      else if (from === "install") setModal(true);
    }
  };

  const getComponentDetails = async (group) => {
    let temp = JSON.parse(group.componentList);
    allComponents.map((item) => {
      if (Object.keys(temp).includes(item.id)) {
        temp[item.id].itemName = item.itemName;
        temp[item.id].itemDescription = item.itemDescription;
        temp[item.id].itemUOM = item.itemUOM;
        temp[item.id].HSN = item.HSN ?? "";
        temp[item.id].count = "";
        temp[item.id].color = "Other";
        if (item.calBasedOn) temp[item.id].calBasedOn = item.calBasedOn;
        if (item.unitPerPanel) temp[item.id].unitPerPanel = item.unitPerPanel;
      }
    });

    let tempSQFTMap = JSON.parse(group.outletSQFTMap);
    let tempPricePerSQFT =
      tempSQFTMap[outletShopId] != undefined
        ? tempSQFTMap[outletShopId].pricePerSQFT
        : 0;

    group.profileList = group.profileList
      ? typeof group.profileList == "string"
        ? JSON.parse(group.profileList)
        : group.profileList
      : [];
    props.setComponents({
      componentList: temp,
      groupTotal: 0,
    });
    setPricePerSQFT(tempPricePerSQFT);
    setAreaInSqFt(0);
    setSelectedGroup(group);
    setMeshColor("Other");
    setProfileColor("Other");
    setInstructions("");
    setDimensionMap({});
    setModalCalculatePrice(true);
    setType("Group");
  };

  const editProductGroup = async (key, item) => {
    let flag = "true";
    await Promise.all(
      Object.entries(item.componentList).map(([key, value]) => {
        if (value.count != "") {
          return (flag = "false");
        }
      })
    );
    let temp = item.dimensionMap;
    const sortedKeys = Object.keys(temp).sort((a, b) => {
      const aTimestamp = temp[a]?.timestamp || 0;
      const bTimestamp = temp[b]?.timestamp || 0;
      return aTimestamp - bTimestamp;
    });
    const sortedObject = {};
    sortedKeys.forEach((key) => {
      sortedObject[key] = temp[key];
    });
    setDimensionMap(sortedObject);
    setCalProfileFlag(flag);
    setSelectedGroup(item);
    setMeshColor(item.meshColor);
    setProfileColor(item.profileColor);
    setPricePerSQFT(item.pricePerSQFT);
    setAreaInSqFt(item.areaInSqFt);
    setInstructions(item.instructions);
    setkey(key);
    props.setComponents({
      componentList: item.componentList,
      groupTotal: item.itemTotal,
    });
    setModalCalculatePrice(true);
    setType("Group");
  };

  const addGroupInput = async () => {
    var numReg = /^[0-9]*$/;
    if (from != "quotation" && meshColor == "")
      return alert("Please select mesh color");
    if (from != "quotation" && profileColor == "")
      return alert("Please select profile color");

    if (pricePerSQFT == 0) return alert("Please enter price");
    if (isNaN(pricePerSQFT)) return alert("Please enter valid price");
    if (areaInSqFt == 0) return alert("Please enter area in sqft");
    if (isNaN(areaInSqFt)) return alert("Please enter valid area");

    if (
      from != "quotation" &&
      props.common.shopType == "master" &&
      Object.entries(dimensionMap).length == 0
    )
      return message.error("Please add dimensions");

    let flag = false;
    Object.entries(dimensionMap).map(([key, value]) => {
      if (
        from != "quotation" &&
        props.common.shopType == "master" &&
        value.width == ""
      )
        flag = true;
      if (isNaN(value.width)) flag = true;
      if (
        from != "quotation" &&
        props.common.shopType == "master" &&
        value.height == ""
      )
        flag = true;
      if (isNaN(value.height)) flag = true;
      if (
        from != "quotation" &&
        props.common.shopType == "master" &&
        value.panelCount == ""
      )
        flag = true;
      if (numReg.test(value.panelCount) === false) flag = true;
      if (
        from != "quotation" &&
        props.common.shopType == "master" &&
        value.quantity == ""
      )
        flag = true;
      if (numReg.test(value.quantity) === false) flag = true;
    });

    if (flag) message.error("Please enter dimensions");

    if (Object.entries(props.sqft.componentList).length == 0)
      return message.error("Material list cannot be empty");

    Object.entries(props.sqft.componentList).map(([key, value]) => {
      if (
        from == "production" &&
        props.common.shopType == "master" &&
        value.count === ""
      ) {
        message.error(value.count + "Enter count for " + value.itemName);
        flag = true;
      }
    });

    if (flag) return;

    props.addToBill({
      item: selectedGroup,
      total: props.sqft.groupTotal,
      componentList: props.sqft.componentList,
      key: key,
      meshColor: meshColor,
      profileColor: profileColor,
      pricePerSQFT: pricePerSQFT,
      areaInSqFt: areaInSqFt,
      dimensionMap: dimensionMap,
      instructions: instructions,
      type: "Group",
      count: "NA",
      color: "NA",
    });

    setModalCalculatePrice(false);
    setModalAddGroups(false);
    setCalProfileFlag("false");
    setInstructions("");
    setDimensionMap({});
    setPricePerSQFT(0);
    setAreaInSqFt(0);
    setSelectedGroup([]);
  };

  const addMaterial = () => {
    props.addToBill({
      item: selectedGroup,
      total: selectedGroup.masterPP * count,
      componentList: {},
      key: selectedGroup.id,
      meshColor: "NA",
      profileColor: "NA",
      pricePerSQFT: 0,
      areaInSqFt: 0,
      dimensionMap: {},
      instructions: "",
      type: "Component",
      count: count,
      color: color,
    });
    setModalMaterial(false);
    setColor("Other");
    setCount(0);
  };

  useEffect(() => {
    if (areaInSqFt !== "" && pricePerSQFT != "") calculatePrice();
    else {
      console.log("useEffect SQFT else");
      props.setComponents({
        componentList: props.sqft.componentList,
        groupTotal: parseFloat(areaInSqFt * pricePerSQFT).toFixed(2),
      });
    }
  }, [areaInSqFt, pricePerSQFT]);

  const calculatePrice = () => {
    props.setComponents({
      componentList: props.sqft.componentList,
      groupTotal: parseFloat(pricePerSQFT * areaInSqFt).toFixed(2),
    });
  };

  const downloadPDF = async () => {
    await pdf(
      <GenerateSQFTPDF
        item={oldDetails}
        outletDetails={outletDetails}
        PDFNotes={PDFNotes}
        paymentConditions={paymentConditions}
        tAndC={tAndC}
        symbol={props.common.symbol}
        paymentDetails={paymentDetails}
        from={from}
        SOTotal={SOTotal}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${randomId}.pdf`);
      });
  };

  const downloadWarrantyPDF = async () => {
    await pdf(
      <GenerateWarranty
        item={oldDetails}
        outletDetails={outletDetails}
        paymentDetails={paymentDetails}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `Warranty-${randomId}.pdf`);
      });
  };

  const addDimension = () => {
    let temp = { ...dimensionMap };
    let id = uuidv4();
    temp[id] = {};
    temp[id].id = id;
    temp[id].width = "";
    temp[id].height = "";
    temp[id].panelCount = "";
    temp[id].location = "";
    temp[id].quantity = 1;
    temp[id].timestamp = new Date().getTime();
    setDimensionMap(temp);
  };

  const removeDimension = (key) => {
    let temp = { ...dimensionMap };
    delete temp[key];
    setDimensionMap(temp);
  };

  const changeDimension = (key, type, value) => {
    let temp = { ...dimensionMap };
    temp[key][type] = value;
    if (type == "width" || type == "height" || type == "quantity")
      temp[key].sqft = (
        parseFloat(temp[key].width / 30.48).toFixed(2) *
        parseFloat(temp[key].height / 30.48).toFixed(2) *
        temp[key].quantity
      ).toFixed(2);
    setDimensionMap(temp);
  };

  const sendNotificationStatus = async (tokenArray, message, title) => {
    try {
      await API.graphql({
        query: mutations.notification,
        variables: {
          message: message,
          title: title,
          tokenArray: tokenArray,
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div className="bill">
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              marginRight: 20,
            }}
          >
            <div>
              <h3 className="bill-heading">
                {from === "quotation" ? "Quotation" : "Order"} Details{" "}
                {randomId}
              </h3>
              {oldDetails.orderLocation && (
                <h3>Outlet : {oldDetails.orderLocation}</h3>
              )}
            </div>
          </Col>
        </Row>
        <div className="divider2"></div>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Customer Name:
              </label>
              <Input
                style={{ width: "60%" }}
                placeholder="Enter customer name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setShowSuggestion(true);
                }}
                maxLength={100}
              />
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {canEdit && (
              <Button
                style={{ marginRight: "8px", paddingRight: 25 }}
                onClick={() => {
                  setDimensionMap({});
                  setInstructions("");
                  setModalAddGroups(true);
                  setType("Group");
                }}
              >
                {" "}
                <PlusOutlined /> Add Product
              </Button>
            )}
            {/* {canEdit && (
              <Button
                style={{ marginRight: "8px", paddingRight: 25 }}
                onClick={() => {
                  setDimensionMap({});
                  setInstructions("");
                  setModalAddComponents(true);
                  setType("Component");
                }}
              >
                {" "}
                <PlusOutlined /> Add Material
              </Button>
            )} */}
            {test != "addNewQuotation" &&
              oldDetails.orderDetails &&
              Object.keys(JSON.parse(oldDetails.orderDetails)).length > 0 && (
                <Button
                  style={{ paddingRight: 25 }}
                  onClick={() => {
                    setpdfModal(true);
                    setFromDnldPDF(true);
                  }}
                >
                  Download PDF
                </Button>
              )}
            {(status == "Packed" || status == "Dispatched") && (
              <Button
                style={{ paddingRight: 25 }}
                onClick={() => {
                  setDeliveryChalanModal(true);
                }}
              >
                Download Delivery Chalan
              </Button>
            )}
            {/* {status == "Installed" && (
              <Button
                style={{ paddingRight: 25 }}
                onClick={() => {
                  downloadWarrantyPDF(true);
                }}
              >
                Download Warranty PDF
              </Button>
            )} */}
          </Col>
        </Row>
        {name != "" && showSuggestion && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8} lg={8}>
              <div
                style={{ marginLeft: 45, height: "100px", overflowY: "auto" }}
              >
                {allQuotes
                  .filter(
                    (item) =>
                      item.name.toString().toLowerCase().indexOf(name) > -1
                  )
                  .map((item) => (
                    <p
                      className="suggestion-item"
                      onClick={() => {
                        setName(item.name);
                        setPhoneNumber(item.phoneNumber.slice(3, 13));
                        setAddress(item.address);
                        setShowSuggestion(false);
                      }}
                      key={item}
                    >
                      {item.name}
                    </p>
                  ))}
              </div>
            </Col>
          </Row>
        )}
        <div className="divider2"></div>
        <CustomerDetailsForm
          status={status}
          test={test}
          from={from}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          showSuggestion={showSuggestion}
          setShowSuggestion={setShowSuggestion}
          address={address}
          setAddress={setAddress}
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          distance={distance}
          setDistance={setDistance}
          email={email}
          setEmail={setEmail}
          notes={notes}
          setNotes={setNotes}
          deliveryNotes={deliveryNotes}
          setDeliveryNotes={setDeliveryNotes}
          visitDate={visitDate}
          visitTime={visitTime}
          estimatedDelivery={estimatedDelivery}
          installDt={installDt}
          deliveryExecutive={deliveryExecutive}
          date={date}
          addedBy={addedBy}
          leadSource={leadSource}
          setLeadSource={setLeadSource}
          quotationTrail={quotationTrail}
          setHistoryModal={setHistoryModal}
          imagesURI={imagesURI}
          setImagesURI={setImagesURI}
          imagesBase64={imagesBase64}
          setImagesBase64={setImagesBase64}
          onImageChange={onImageChange}
          imagesURIInstall={imagesURIInstall}
          onImageChangeInstall={onImageChangeInstall}
          prodStatus={prodStatus}
          oldDetails={oldDetails}
        />
        <div className="divider2"></div>
        {Object.keys(props.sqft.items).length > 0 && (
          <div style={{ marginTop: 30, marginBottom: 20 }}>
            <Table
              dataSource={Object.values(props.sqft.items)}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card>
              {Object.entries(followUpLog).length > 0 && (
                <div style={{ marginTop: -15 }}>
                  <h3>Follow Up Details</h3>
                  {Object.entries(followUpLog).map(([key, value]) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <p style={{ width: "30%" }}>{value.updatedBy}</p>
                        <p style={{ width: "10%" }}>
                          {moment(key).format("DD MMM YY")}
                        </p>
                        <p style={{ width: "60%" }}>{value.followUpNotes} by</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </Card>
          </Col>
          {from !== "production" && (
            <Col span={12}>
              <Card style={{ width: "100%" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    marginLeft: 0,
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>
                        Sub Total:
                      </td>
                      <td style={{ padding: "8px", width: "55%" }}></td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        {props.common.symbol}
                        {parseFloat(props.sqft.subTotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>
                        Apply discount:
                      </td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Select
                          style={{ width: "35%", marginRight: 10 }}
                          value={props.sqft.discountType}
                          onChange={(value) => {
                            props.setExtraCharges({
                              GST: props.sqft.GST,
                              discountValue: props.sqft.discountValue,
                              discountType: value,
                              discountAmount: props.sqft.discountAmount,
                              extraCharges: props.sqft.extraCharges,
                            });
                          }}
                        >
                          <Option value="flat">Flat</Option>
                          <Option value="percentage">Percentage</Option>
                        </Select>
                        <Input
                          style={{ width: "40%" }}
                          placeholder="Enter discount amount"
                          value={props.sqft.discountValue}
                          onChange={(e) => {
                            if (e.target.value < 0)
                              return message.error(
                                "Please enter positive value"
                              );
                            props.setExtraCharges({
                              GST: props.sqft.GST,
                              discountValue: e.target.value,
                              discountType: props.sqft.discountType,
                              discountAmount: props.sqft.discountAmount,
                              extraCharges: props.sqft.extraCharges,
                            });
                          }}
                          maxLength={10}
                        />
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        - {parseFloat(props.sqft.discountAmount).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>
                        Transportation Charges:
                      </td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Input
                          style={{ width: "80%" }}
                          placeholder="Enter extra charges"
                          value={props.sqft.extraCharges}
                          onChange={(e) => {
                            if (e.target.value < 0)
                              return message.error(
                                "Please enter positive value"
                              );
                            props.setExtraCharges({
                              GST: props.sqft.GST,
                              discountValue: props.sqft.discountValue,
                              discountType: props.sqft.discountType,
                              discountAmount: props.sqft.discountAmount,
                              extraCharges: e.target.value,
                            });
                          }}
                          maxLength={10}
                        />
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        + {parseFloat(props.sqft.extraCharges).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>GST:</td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Select
                          style={{ width: "80%" }}
                          value={props.sqft.GST}
                          onChange={(value) => {
                            props.setExtraCharges({
                              GST: value,
                              discountValue: props.sqft.discountValue,
                              discountType: props.sqft.discountType,
                              discountAmount: props.sqft.discountAmount,
                              extraCharges: props.sqft.extraCharges,
                            });
                          }}
                        >
                          <Option value="0">0%</Option>
                          <Option value="18">18%</Option>
                          <Option value="12">12%</Option>
                        </Select>
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        + {parseFloat(props.sqft.GSTAmount).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </Col>
          )}
        </Row>
        <div className="divider2"></div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Card style={{ width: "50%" }}>
            <Row>
              {(oldDetails.orderDetails &&
                Object.keys(JSON.parse(oldDetails.orderDetails)).length > 0 &&
                Object.keys(props.sqft.items).length > 0 &&
                status !== "Convert to Order" &&
                status !== "In-Production" &&
                test != "addNewQuotation" &&
                oldDetails.outletShopId == outletShopId && (
                  <Col span={8} style={{ marginTop: "3%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {(from === "quotation" && (
                        <Select
                          style={{
                            width: "100%",
                            backgroundColor: "white",
                            flex: 1,
                          }}
                          value={status}
                          placeholder="Select Status"
                          onChange={(value) => {
                            setNextStatus(value);
                            takeInputs(value);
                          }}
                        >
                          <Option value="">Change Status</Option>
                          <Option value="live">New</Option>
                          <Option value="Follow Up">Follow Up</Option>
                          <Option value="Convert to Order">
                            Convert to Order
                          </Option>
                          <Option value="Rejected">Rejected</Option>
                        </Select>
                      )) ||
                        ((from === "order" || from == "install") && (
                          <Select
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                              flex: 1,
                            }}
                            value={status}
                            placeholder="Select Status"
                            onChange={(value) => {
                              setNextStatus(value);
                              takeInputs(value);
                            }}
                          >
                            <Option value="live">New</Option>
                            {(props.common.shopType == "master" && (
                              <Option value="In-Production">
                                In-Production
                              </Option>
                            )) || (
                              <>
                                {from === "order" && (
                                  <Option value="Assign To RF">
                                    Assign To RF
                                  </Option>
                                )}
                                {from == "install" && (
                                  <>
                                    <Option value="Pending">Pending</Option>
                                    <Option value="Installed">Installed</Option>
                                  </>
                                )}
                              </>
                            )}
                          </Select>
                        )) ||
                        (from === "production" && (
                          <Select
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                              flex: 1,
                            }}
                            value={status}
                            placeholder="Select Status"
                            onChange={(value) => {
                              setNextStatus(value);
                              takeInputs(value);
                            }}
                          >
                            <Option disabled={status != "live"} value="live">
                              New
                            </Option>
                            {props.common.prodStatusArray.map((item, index) => {
                              return (
                                <Option
                                  disabled={
                                    index -
                                      props.common.prodStatusArray.indexOf(
                                        status
                                      ) !=
                                    1
                                  }
                                  value={item}
                                >
                                  {item}
                                </Option>
                              );
                            })}
                          </Select>
                        ))}
                    </div>
                  </Col>
                )) ||
                (test != "addNewQuotation" && (
                  <Col>
                    <p>Status: {status}</p>
                  </Col>
                ))}
              {(status == "Delivered" ||
                status == "Assign To RF" ||
                status == "Pending") && (
                <Col span={8} style={{ marginTop: "3%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        flex: 1,
                      }}
                      value={assignToUser}
                      placeholder="Assign Person"
                      onChange={(value) => {
                        setToUser(userList[value].email);
                        setToUserId(value);
                        updateAssignInstall(value);
                      }}
                    >
                      <Option value="">Select a person</Option>
                      {Object.entries(userList).map(([key, value]) => {
                        return <Option value={key}>{value.email}</Option>;
                      })}
                    </Select>
                  </div>
                </Col>
              )}
              {test != "addNewQuotation" &&
                from !== "production" &&
                oldDetails.outletShopId == outletShopId && (
                  <Col span={4} offset={2} style={{ marginTop: "3%" }}>
                    <Link onClick={() => setPaymentModal(true)}>
                      Payment Details
                    </Link>
                  </Col>
                )}
              {(canEdit ||
                (from == "install" && status == "Installed") ||
                (from == "order" && status == "Assign To RF")) &&
                Object.keys(props.sqft.items).length > 0 && (
                  <Col span={4} style={{ marginTop: "3%" }}>
                    <Button
                      style={{
                        marginRight: "8px",
                        paddingRight: 25,
                        marginLeft: "20px",
                      }}
                      disabled={spinnerState}
                      onClick={() => checkInput()}
                    >
                      {from === "quotation"
                        ? test === "addNewQuotation"
                          ? `Add Quotation`
                          : `Update Quotation`
                        : "Save Order"}
                    </Button>
                  </Col>
                )}
            </Row>
          </Card>
          {from !== "production" && (
            <Card style={{ width: "50%" }}>
              <Row>
                <Col span={7} offset={17}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>Total Amount:</div>
                    <div>
                      {props.common.symbol}{" "}
                      {isNaN(props.sqft.total)
                        ? ""
                        : parseFloat(props.sqft.total).toFixed(2)}
                    </div>
                  </div>
                </Col>
              </Row>
              {test != "addNewQuotation" && (
                <Row>
                  <Col span={7} offset={17}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>Advance Amount:</div>
                      <div>
                        {props.common.symbol}{" "}
                        {parseFloat(paymentDetails.paymentDone).toFixed(2)}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {test != "addNewQuotation" && (
                <Row>
                  <Col span={7} offset={17}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>Balance Amount:</div>
                      <div>
                        {props.common.symbol}{" "}
                        {parseFloat(paymentDetails.paymentPending).toFixed(2)}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Card>
          )}
        </div>
      </div>
      <Modal
        visible={modal}
        style={{ width: "100%" }}
        onCancel={() => {
          setModal(false);
          setNextStatus("");
        }}
        footer={null}
      >
        <div className="bill">
          <div className="bill-container">
            {from === "quotation" && nextStatus === "Follow Up" && (
              <>
                <span>Select customer visit date</span>
                <div className="input-container">
                  <DatePicker
                    className="input-box"
                    placeholder="Select schedule date and time"
                    onChange={(date) => setScheduleDate(date)}
                    value={scheduleDate}
                    showTime
                  />
                </div>
              </>
            )}
            {from === "quotation" && nextStatus === "Convert to Order" && (
              <>
                <span>Select Payment due date</span>
                <div className="input-container">
                  <DatePicker
                    className="input-box"
                    placeholder="Select schedule date and time"
                    onChange={(date) => setPaymentDueDate(date)}
                    value={paymentDueDate}
                  />
                </div>
              </>
            )}
            {from === "quotation" && nextStatus === "Rejected" && (
              <>
                <TextField
                  id="outlined-basic"
                  label="Reason For rejection"
                  variant="outlined"
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  inputProps={{
                    maxLength: 200,
                  }}
                  size="small"
                  style={{ width: "100%" }}
                  autoComplete="off"
                />
              </>
            )}

            {from === "order" && (
              <>
                <span>Estimated delivery date</span>
                <div className="input-container">
                  <DatePicker
                    className="input-box"
                    placeholder="Estimated Delivery Date"
                    onChange={(date) => setEstimatedDelivery(date)}
                    value={estimatedDelivery}
                  />
                </div>
                <span>Delivery Executive:</span>
                <TextField
                  label="Delivery executive name"
                  size="small"
                  variant="outlined"
                  style={{ width: "90%" }}
                  margin="normal"
                  value={deliveryExecutive}
                  onChange={(e) => setDeliveryExecutive(e.target.value)}
                  inputProps={{
                    maxLength: 200,
                  }}
                  autoComplete="off"
                />
              </>
            )}
            {(from === "order" || from === "install") && (
              <div className="input-container">
                <DatePicker
                  className="input-box"
                  placeholder="Install Date"
                  onChange={(date) => setInstallDt(date)}
                  value={installDt}
                />
              </div>
            )}
          </div>
          <Col xs={24} sm={12} md={8} lg={6}>
            <div className="discount">
              <Button
                type="primary"
                disabled={spinner}
                onClick={() => {
                  from === "quotation"
                    ? nextStatus == "Convert to Order"
                      ? setpdfModal(true)
                      : updateStatus(nextStatus)
                    : updateOrderStatus(nextStatus);
                }}
              >
                Ok
              </Button>
            </div>
          </Col>
        </div>
      </Modal>
      <Modal
        visible={modalAddGroups}
        style={{ width: "100%" }}
        onCancel={() => setModalAddGroups(false)}
        footer={null}
      >
        <div className="bill">
          <div className="bill-container">
            <TextField
              label="Search by product name"
              value={term1}
              onChange={(e) => setTerm1(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              size="small"
              style={{ width: "90%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
            <Row>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term1 !== ""
                      ? allGroup.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term1.toLowerCase()) > -1
                        )
                      : allGroup
                  }
                  columns={columnsListGroups}
                  className="my-table"
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <Modal
        visible={modalCalculatePrice}
        onCancel={() => {
          setModalCalculatePrice(false);
          setPricePerSQFT(0);
          setAreaInSqFt(0);
          setDimensionMap({});
          setInstructions("");
        }}
        footer={null}
        width={1200}
        title={<h2 style={{ fontSize: "20px" }}>Product Details</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />

        <Row style={{ marginTop: -10 }}>
          {/* <Col xs={24} sm={12} lg={12} offset={1}> */}
          <Col>
            <Descriptions column={{ xs: 1, sm: 2, md: 4, lg: 1 }} size="small">
              <Descriptions.Item
                label={
                  <span style={{ color: "black" }}>
                    <p style={{ marginTop: -10 }}>Name</p>
                  </span>
                }
                style={{ textAlign: "right" }}
              >
                <div style={{ paddingLeft: "12%", marginTop: -20 }}>
                  <h3>{selectedGroup.itemName}</h3>
                </div>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <span style={{ color: "black" }}>
                    <p style={{ marginTop: -10 }}>Description</p>
                  </span>
                }
                style={{ textAlign: "right" }}
              >
                <div style={{ paddingLeft: "12%", marginTop: -20 }}>
                  <h3>{selectedGroup.itemDescription}</h3>
                </div>
              </Descriptions.Item>
              {from !== "production" && (
                <Descriptions.Item
                  label={
                    <span style={{ color: "black", marginTop: -10 }}>
                      <p style={{ marginTop: -10 }}>Total</p>
                    </span>
                  }
                >
                  <div style={{ paddingLeft: "12%", marginTop: -20 }}>
                    <h3>{parseFloat(props.sqft.groupTotal).toFixed(2)}</h3>
                  </div>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={9}>
            <TextField
              label="Enter quantity in sqft"
              size="small"
              variant="outlined"
              style={{ width: "90%" }}
              margin="normal"
              value={areaInSqFt}
              onChange={(e) => setAreaInSqFt(e.target.value)}
              inputProps={{
                maxLength: 200,
              }}
              autoComplete="off"
            />
          </Col>
          {from !== "production" && (
            <Col xs={24} sm={12} lg={9} offset={1}>
              <TextField
                label="Enter price per sqft"
                size="small"
                variant="outlined"
                style={{ width: "90%" }}
                margin="normal"
                value={pricePerSQFT}
                onChange={(e) => setPricePerSQFT(e.target.value)}
                inputProps={{
                  maxLength: 12,
                }}
                autoComplete="off"
              />
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={9}>
            <TextField
              label="Instructions"
              size="small"
              variant="outlined"
              style={{ width: "90%" }}
              margin="normal"
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              inputProps={{
                maxLength: 200,
              }}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={9}>
            <label style={{ marginTop: 10 }}>Profile Color</label>
            <Select
              style={{ width: "90%", marginTop: 5 }}
              onChange={(value) => {
                setProfileColor(value);
              }}
              value={profileColor}
            >
              {props.common.colorArray.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} lg={9} offset={1}>
            <label style={{ marginTop: 10 }}>Mesh Color</label>
            <Select
              style={{ width: "90%", marginTop: 5 }}
              onChange={(value) => {
                setMeshColor(value);
              }}
              value={meshColor}
            >
              {props.common.meshColorArray.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        {from != "quotation" && (
          <>
            {Object.entries(dimensionMap).length > 0 &&
              Object.entries(dimensionMap).map(([key, value]) => {
                return (
                  <Row gutter={[16, 16]} align="middle">
                    <Col xs={10} sm={7} lg={5}>
                      <TextField
                        label="Location"
                        size="small"
                        variant="outlined"
                        style={{ width: "90%" }}
                        margin="normal"
                        value={value.location}
                        onChange={(e) =>
                          changeDimension(key, "location", e.target.value)
                        }
                        inputProps={{
                          maxLength: 12,
                        }}
                        autoComplete="off"
                      />
                    </Col>
                    <Col xs={5} sm={4} lg={3}>
                      <TextField
                        label="Panel Count"
                        size="small"
                        variant="outlined"
                        style={{ width: "90%" }}
                        margin="normal"
                        value={value.panelCount}
                        onChange={(e) =>
                          changeDimension(key, "panelCount", e.target.value)
                        }
                        inputProps={{
                          maxLength: 12,
                        }}
                        autoComplete="off"
                      />
                    </Col>
                    <Col xs={9} sm={6} lg={3}>
                      <TextField
                        label="Width(in cm)"
                        size="small"
                        variant="outlined"
                        style={{ width: "90%" }}
                        margin="normal"
                        value={value.width}
                        onChange={(e) =>
                          changeDimension(key, "width", e.target.value)
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                        autoComplete="off"
                      />
                    </Col>
                    <Col xs={9} sm={6} lg={3}>
                      <TextField
                        label="Height(in cm)"
                        size="small"
                        variant="outlined"
                        style={{ width: "90%" }}
                        margin="normal"
                        value={value.height}
                        onChange={(e) =>
                          changeDimension(key, "height", e.target.value)
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                        autoComplete="off"
                      />
                    </Col>
                    <Col xs={5} sm={4} lg={3}>
                      <TextField
                        label="Qty"
                        size="small"
                        variant="outlined"
                        style={{ width: "90%" }}
                        margin="normal"
                        value={value.quantity}
                        onChange={(e) =>
                          changeDimension(key, "quantity", e.target.value)
                        }
                        inputProps={{
                          maxLength: 10,
                        }}
                        autoComplete="off"
                      />
                    </Col>
                    <Col xs={5} sm={4} lg={3}>
                      <p>SQFT: {!isNaN(value.sqft) ? value.sqft : 0}</p>
                    </Col>
                    <Col xs={5} sm={4} lg={3}>
                      <Button
                        danger
                        type="primary"
                        onClick={() => {
                          removeDimension(key);
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            {canEdit && props.common.shopType == "master" && (
              <Row>
                <Button
                  style={{ marginTop: 15, marginBottom: 15 }}
                  type="primary"
                  onClick={() => {
                    addDimension();
                  }}
                >
                  Add Dimension
                </Button>
              </Row>
            )}
          </>
        )}

        {from == "production" &&
          props.common.shopType == "master" &&
          props.sqft.componentList && (
            <Table
              dataSource={Object.values(props.sqft.componentList)}
              columns={componentListItems}
              className="my-table"
              pagination={false}
            />
          )}
        <Row justify="space-between" marginTop="10" style={{ marginTop: 15 }}>
          {canEdit &&
            from == "production" &&
            props.common.shopType == "master" && (
              <Col>
                <Button
                  type="primary"
                  onClick={() => setModalAddComponents(true)}
                >
                  Add Materials
                </Button>
              </Col>
            )}
          <Col>
            <Button
              type="primary"
              onClick={() => {
                addGroupInput();
              }}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={modalAddComponents}
        width={900}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        <div className="bill">
          <div className="bill-container">
            <TextField
              label="Search by product name"
              size="small"
              variant="outlined"
              style={{ width: "95%" }}
              margin="normal"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              inputProps={{
                maxLength: 100,
              }}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <Row>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term == ""
                      ? allComponents
                      : allComponents.filter(
                          (value) =>
                            value.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={8} lg={6} offset={21}>
                <div className="discount">
                  <Button
                    type="primary"
                    onClick={() => setModalAddComponents(false)}
                    style={{ width: "30%" }}
                  >
                    Ok
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      {quotationTrail && (
        <QuotHistoryModal
          historyModal={historyModal}
          setHistoryModal={setHistoryModal}
          quotationTrail={quotationTrail}
        />
      )}
      {paymentModal && (
        <PaymentModal
          id={test}
          updatedBy={updatedBy}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          paymentDetails={paymentDetails}
          setPaymentDetails={setPaymentDetails}
          canEdit={canEdit}
          from={from}
        />
      )}
      <Modal
        visible={pdfModal}
        onCancel={() => {
          setpdfModal(false);
        }}
        footer={null}
        width={1000}
        title={<h2 style={{ fontSize: "20px" }}>PDF Details</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />

        <Row style={{ marginBottom: "16px" }}>
          <Col>
            <TextField
              label="PDF notes"
              variant="outlined"
              style={{ width: "90%" }}
              value={PDFNotes}
              onChange={(e) => setPDFNotes(e.target.value)}
              multiline
              rows={3}
              InputProps={{
                style: { minHeight: "100px" }, // Adjust the height as needed
              }}
            />
          </Col>

          <Col>
            <TextField
              label="Payment Conditions"
              variant="outlined"
              style={{ width: "90%" }}
              value={paymentConditions}
              onChange={(e) => setPaymentConditions(e.target.value)}
              multiline
              rows={3}
              InputProps={{
                style: { minHeight: "100px" }, // Adjust the height as needed
              }}
            />
          </Col>
          <Col>
            <TextField
              label="Terms and Conditions"
              variant="outlined"
              style={{ width: "90%" }}
              value={tAndC}
              onChange={(e) => setTAndC(e.target.value)}
              multiline
              rows={3}
              InputProps={{
                style: { minHeight: "100px" }, // Adjust the height as needed
              }}
            />
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              disabled={spinner}
              onClick={() => editPDFDetails()}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={deliveryChalanModal}
        onCancel={() => {
          setDeliveryChalanModal(false);
        }}
        footer={null}
        width={800}
        title={<h2 style={{ fontSize: "20px" }}>Delivery Details</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={9} offset={1}>
            <label>Driver Name</label>
            <Input
              placeholder="Driver Name"
              style={{ width: "90%", margin: 10 }}
              value={driverDetails.name}
              onChange={(e) =>
                setDriverDetails({ ...driverDetails, name: e.target.value })
              }
              autoComplete="off"
              maxLength={100}
            />
          </Col>

          <Col xs={24} sm={12} lg={9}>
            <label>Driver Phone</label>
            <Input
              placeholder="Driver Phone"
              style={{ width: "90%", margin: 10 }}
              value={driverDetails.phone}
              onChange={(e) =>
                setDriverDetails({ ...driverDetails, phone: e.target.value })
              }
              autoComplete="off"
              maxLength={10}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={9} offset={1}>
            <label>Vehicle Details</label>
            <Input
              placeholder="Vehicle Details"
              style={{ width: "90%", margin: 10 }}
              value={driverDetails.vehicleNumber}
              onChange={(e) =>
                setDriverDetails({
                  ...driverDetails,
                  vehicleNumber: e.target.value,
                })
              }
              autoComplete="off"
              maxLength={100}
            />
          </Col>
        </Row>
        <div className="discount" style={{ paddingLeft: "90%" }}>
          <Button type="primary" onClick={() => editDriverDetails()}>
            Ok
          </Button>
        </div>
      </Modal>
      <Modal
        visible={modalMaterial}
        onCancel={() => {
          setModalMaterial(false);
        }}
        footer={null}
        width={800}
        title={<h2 style={{ fontSize: "20px" }}>Material Details</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={9} offset={1}>
            <label>Quantity</label>
            <Input
              placeholder="Quantity"
              style={{ width: "90%", margin: 10 }}
              value={count}
              onChange={(e) => setCount(e.target.value)}
              autoComplete="off"
              maxLength={100}
            />
          </Col>

          <Col xs={24} sm={12} lg={9}>
            <label>Material Color</label>
            <Select
              style={{ width: "90%", marginTop: 5 }}
              onChange={(value) => {
                setColor(value);
              }}
              value={color}
            >
              {props.common.colorArray
                .concat(props.common.meshColorArray)
                .map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>

        <div className="discount" style={{ paddingLeft: "90%" }}>
          <Button type="primary" onClick={() => addMaterial()}>
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
    sqft: state.sqft,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addToBill: (data) => dispatch({ type: "ADD_SQFT_ITEM", payload: data }),
    removeFromBill: (data) =>
      dispatch({ type: "REMOVE_SQFT_ITEM", payload: data }),
    setBillItems: (data) =>
      dispatch({ type: "SET_SQFT_BILL_ITEMS", payload: data }),
    setComponents: (data) =>
      dispatch({ type: "SET_SQFT_COMPONENTS", payload: data }),
    addComponent: (data) =>
      dispatch({ type: "ADD_SQFT_COMPONENT", payload: data }),
    removeComponent: (data) =>
      dispatch({ type: "REMOVE_SQFT_COMPONENT", payload: data }),
    changeComponentCount: (data) =>
      dispatch({ type: "CHANGE_SQFT_COM_COUNT", payload: data }),
    changeComponentColor: (data) =>
      dispatch({ type: "CHANGE_SQFT_COM_COLOR", payload: data }),
    setExtraCharges: (data) =>
      dispatch({ type: "SET_SQFT_EXTRA_CHARGES", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSQFTCompQuot);
