import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../images/Logo.jpg";
import Warranty from "../images/warranty.jpg";
import moment from "moment";
import customFont from "../fonts/arial.ttf";
import QRCode from "../images/Mannady.png";
Font.register({ family: "Arial", src: customFont });

// Create Document Component
export default function GenerateSQFTPDF(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View>
              <Image src={Logo} style={{ width: 70, height: 70 }} />
              <Text style={styles.shopName}>
                {props.outletDetails.shopName.toUpperCase()}
              </Text>
              <Text>{props.outletDetails.address}</Text>
              <Text>GSTIN: {props.outletDetails.GSTNumber}</Text>
              <Text>Email: {props.outletDetails.email}</Text>
              <Text>Website: www.royalmosquitonets.com</Text>
            </View>
            <View>
              <Image src={Warranty} style={{ width: 70, height: 70 }} />
            </View>
          </View>
          <Text style={styles.heading}>INSTALLATION REPORT</Text>
          <View style={styles.custDetails}>
            <View style={styles.topSection}>
              <Text style={styles.topContent}>
                Customer Name: {props.item.name}
              </Text>
              <Text style={styles.topContent}>{props.item.phoneNumber}</Text>
              <Text style={styles.topContent}>
                Billing Address: {props.item.address}
              </Text>
              <Text style={styles.topContent}>
                Shipping Address: {props.item.shippingAddress}
              </Text>
            </View>
            <View style={styles.topSection}>
              <Text style={styles.topContent}>
                SR NO : {props.item.randomId}
              </Text>
              <Text style={styles.topContent}>Warranty Period: </Text>
              <Text style={styles.topContent}>
                Date: {moment(new Date()).format("DD MMM YY")}
              </Text>
              <Text style={styles.topContent}>Offer: </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={styles.sNo}>S No.</Text>
              <Text style={styles.countBold}>Name</Text>
              <Text style={styles.count}>Description</Text>
              <Text style={styles.count}>Profile Color</Text>
              <Text style={styles.count}>Mesh Color</Text>
              <Text style={styles.number}>Qty</Text>
              <Text style={styles.amount}>Completed</Text>
            </View>

            {Object.entries(JSON.parse(props.item.orderDetails)).map(
              ([key, value], index) => {
                return (
                  <>
                    <View style={styles.tableData}>
                      <Text style={styles.sNo}>{index + 1}</Text>
                      <Text style={styles.countBold}>{value.itemName}</Text>
                      <Text style={styles.count}>{value.itemDescription}</Text>
                      <Text style={styles.count}>{value.profileColor}</Text>
                      <Text style={styles.count}>{value.meshColor}</Text>
                      <Text style={styles.number}>{value.areaInSqFt}</Text>
                      <Text style={styles.amount}></Text>
                    </View>
                  </>
                );
              }
            )}
          </View>
          <View style={styles.custDetails}>
            <View style={styles.topSection}>
              <Text style={{ fontSize: 12 }}>Payment Details</Text>
              <View style={styles.custDetails}>
                <View style={styles.topSection}>
                  <Text>Payment Done:</Text>
                  <Text>Payment Pending:</Text>
                  <Text>Total Payment:</Text>
                </View>
                <View style={styles.topSection}>
                  <Text style={{ textAlign: "right" }}>
                    {parseFloat(props.paymentDetails.paymentDone).toFixed(2)}
                  </Text>
                  <Text style={{ textAlign: "right" }}>
                    {parseFloat(props.paymentDetails.paymentPending).toFixed(2)}
                  </Text>
                  <Text style={{ textAlign: "right" }}>
                    {parseFloat(props.item.totalAmount).toFixed(2)}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.topSection}>
              <Text>{"  "}___ Online Payment</Text>
              <Text>{"  "}___ Cash Payment</Text>
            </View>
          </View>
          <View style={styles.custDetails}>
            <View style={styles.topSection}>
              <Text>Remarks</Text>
              <Text>Please rate this call by choosing an option</Text>
            </View>
            <View style={styles.topSection}>
              <Text>{"  "}___ Extremely Satisfied</Text>
              <Text>{"  "}___ Satisfied</Text>
              <Text>{"  "}___ Dissatisfied</Text>
              <Text>{"  "}___ Annoyed</Text>
              <Text>{"               "}</Text>
            </View>
          </View>
          <View style={styles.signature}>
            <Text style={{ fontSize: 10 }}>Terms and conditions:</Text>
            <Text>One year of company warranty</Text>
            <Text>
              Warranty covered for System Mechanism and SS black-coated mesh
              (not for fiber glass mesh and PP mesh)
            </Text>
            <Text>
              Warranty is not coverd if damage is caused by accidents, misuse,
              or unauthorised repairs
            </Text>
            <Text>For any queries, +91 87549 06217</Text>
            <Text>{"                "}</Text>
            <Text>__________________</Text>
            <Text style={{ fontSize: 12 }}>Customer Signature</Text>
          </View>
          <View style={styles.QRCode}>
            <Image
              src={QRCode}
              style={{
                width: 60,
                height: 60,
              }}
            />
            <Text>Scan me</Text>
          </View>
          <View style={styles.QRCodeText}>
            <Text style={{ fontSize: 10 }}>Google Review</Text>
            <Text>
              If you provide me with some information about the business or
              service you want to review , as well as specific details or
              aspects you'd like to highlight, I can assist you in writting well
              written review.
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 20,
    marginRight: 20,
    fontSize: 8,
  },
  custDetails: {
    flexDirection: "row",
    alignContent: "space-around",
  },
  heading: {
    textAlign: "center",
    marginTop: 10,
    padding: 5,
    fontSize: 16,
    border: "1px solid black",
  },
  topSection: {
    justifyContent: "space-around",
    fontSize: 10,
    border: "1px solid black",
    width: "50%",
  },
  topContent: {
    borderBottom: "1px solid black",
    padding: 2,
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataGrey: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#D3D3D3",
  },
  name: {
    padding: 2,
    width: 150,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  countBold: {
    padding: 2,
    width: 80,
    textAlign: "center",
    fontWeight: "bold",
  },
  number: {
    padding: 2,
    width: 20,
    textAlign: "center",
  },
  sNo: {
    padding: 2,
    width: 10,
    textAlign: "center",
  },
  image: {
    padding: 2,
    width: 70,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 60,
    textAlign: "right",
    fontFamily: "Arial",
  },
  color: {
    padding: 2,
    width: 65,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  notes: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
  },
  belowDetails: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
    fontFamily: "Times-Roman",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
    fontFamily: "Times-Bold",
  },
  leftPadding: {
    paddingLeft: 10,
  },
  signature: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 300,
    fontSize: 8,
    paddingLeft: 20,
  },
  QRCode: {
    position: "absolute",
    bottom: 20,
    left: 301,
    right: 469,
    fontSize: 8,
    textAlign: "center",
  },
  QRCodeText: {
    position: "absolute",
    bottom: 20,
    left: 370,
    right: 0,
    fontSize: 8,
    paddingRight: 20,
    textAlign: "right",
  },
  shopName: {
    fontSize: 12,
    fontWeight: "bold",
  },
});
