import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button } from "antd";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";

function ListST() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);

  const Columns = [
    {
      title: "ST No.",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
      ellipsis: true,
    },
    {
      title: "Items",
      dataIndex: "componentList",
      key: "componentList",
      ellipsis: true,
      render: (componentList) => (
        <a>
          {Object.entries(JSON.parse(componentList)).map(([key, value]) => {
            return (
              <p style={{ color: "black" }}>
                {value.itemName}-{value.quantity}
              </p>
            );
          })}
        </a>
      ),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => <a onClick={() => navigate(`../AddST/${id}`)}>View</a>,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("StockTransfer")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("StockTransfer", shopId);
    setAllItems(allItems);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <div></div>
          <Button type="primary">
            <Link to="../AddST/addNewST">New Stock Transfer</Link>
          </Button>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table columns={Columns} dataSource={allItems} />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListST);
