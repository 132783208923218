import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import "../styles/test.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Input,
  message,
  Card,
  Select,
} from "antd";
import "../styles/addBill.css";
import dayjs from "dayjs";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc, getAllItemsPagination10 } from "./functionCall";
import { TextField } from "@mui/material";
import ZoomImage from "./zoomImage";
import { api } from "../index";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
function AddTicket(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [orderId, setOrderId] = useState("");
  const [complaint, setComplaint] = useState("");
  const [priority, setPriority] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [randomId, setRandomId] = useState("");
  const [followUpNotes, setFollowUpNotes] = useState("");
  const [followUpLog, setFollowUpLog] = useState("");
  const [followUpDate, setFollowUpDate] = useState("");
  const [addedDate, setAddedDate] = useState("");
  const [status, setStatus] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [assignToLocation, setToLocation] = useState("");
  const [assignToUser, setToUser] = useState("");
  const [userByOutlet, setUserByOutlet] = useState({});
  const [userId, setUserId] = useState("");
  const [images, setImages] = useState([]);
  const [imagesURI, setImagesURI] = useState([]);
  const [imagesBase64, setImagesBase64] = useState([]);
  const [isHovered, setHovered] = useState(false);
  const [oldDetails, setOldDetails] = useState([]);
  const [zoomURL, setZoomURL] = useState("");

  let test = useParams();

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            setAddedBy(user.attributes.email);
            setUpdatedBy(user.attributes.email);
            setShopId(user.attributes["custom:shopId"]);
            setOutletShopId(user.attributes["custom:outletShopId"]);
            setUserId(user.username);
            await getUsersByOutlet(user.attributes["custom:shopId"]);
            if (test.test != "addNewTicket") await getItemById();
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (!checkAccess("Ticket")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) {
        navigate(-1);
      } else {
        setOldDetails(res);
        setName(res.name);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setAddress(res.address);
        setOrderId(res.orderId);
        setComplaint(res.complaint);
        setPriority(res.priority);
        setDate(res.addedDate);
        setTime(res.time);
        setAddedBy(res.addedBy);
        setAddedDate(res.addedDate);
        setStatus(res.status);
        setFollowUpLog(JSON.parse(res.followUpLog) ?? {});
        setFollowUpDate(dayjs(moment(res.followUpDate).toDate()));
        setRandomId(res.randomId);
        setToLocation(
          res.assignToLocation
            ? res.assignToLocation != "NA"
              ? res.assignToLocation
              : ""
            : ""
        );
        setToUser(
          res.assignToUser
            ? res.assignToUser != "NA"
              ? res.assignToUser
              : ""
            : ""
        );
        setImages(res.itemImages ?? []);
        setImagesURI(res.itemImages ?? []);
        setImagesBase64(res.itemImages ?? []);
      }
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };

  const getUsersByOutlet = async (shopId) => {
    let temp = {};
    let allItems = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );

    allItems.map((item) => {
      if (item.location) {
        if (temp[item.location] == undefined) temp[item.location] = {};
        temp[item.location].outletShopId = item.outletShopId;
        if (temp[item.location].users == undefined)
          temp[item.location].users = {};
        temp[item.location].users[item.email] = {};
        temp[item.location].users[item.email].id = item.id;
      }
    });
    setUserByOutlet(temp);
  };

  const editItem = async (image) => {
    let temp = { ...followUpLog };
    let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    temp[date] = {};
    temp[date].followUpNotes = followUpNotes;
    temp[date].updatedBy = updatedBy;
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          address: address,
          orderId: orderId,
          complaint: complaint,
          priority: priority,
          updatedBy: updatedBy,
          status: status,
          followUpLog: JSON.stringify(temp),
          followedUpDate: moment(new Date()).format("YYYY-MM-DD"),
          followUpDate: followUpDate.format("YYYY-MM-DD"),
          assignToLocation:
            assignToLocation !== "" ? assignToLocation : props.common.location,
          assignToUser: assignToUser != "" ? assignToUser : addedBy,
          outletShopId:
            assignToLocation != ""
              ? userByOutlet[assignToLocation].outletShopId
              : outletShopId,
          assignToUserId:
            assignToLocation != ""
              ? assignToUser != ""
                ? userByOutlet[assignToLocation].users[assignToUser].id
                : "NA"
              : userId,
          itemImages: image.length > 0 ? image : images,
        },
      });
      alert("Ticket updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async (image) => {
    let billPrefix = "";

    try {
      const allTodos1 = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res1 = allTodos1.data.getItemById;
      billPrefix = res1.billPrefix;
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10("Ticket", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "RT-" + billPrefix.toUpperCase() + "-" + year + "-0";

    let tempCounter =
      lastNumber.slice(7, 11) == year ? parseInt(lastNumber.slice(12)) + 1 : 1;
    let tempId =
      "RT-" + billPrefix.toUpperCase() + "-" + year + "-" + tempCounter;

    try {
      let bill = await API.graphql({
        query: mutations.addItem,
        variables: {
          type: "Ticket",
          addedBy: addedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          address: address,
          orderId: orderId,
          complaint: complaint,
          priority: priority,
          time: moment(new Date()).format("HH:mm:ss"),
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: tempId,
          followUpDate: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
          addedByOutletShopId: outletShopId,
          addedByLocation: props.common.location,
          assignToLocation: props.common.location,
          assignToUser: addedBy,
          assignToUserId: userId,
          itemImages: image.length > 0 ? image : images,
        },
      });
      alert("Ticket Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (name === "") return message.error("Please enter customer name");
    if (phoneNumber === "") return message.error("Please enter customer phone");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");
    if (orderId === "") return message.error("Please enter order id");
    if (complaint === "")
      return message.error("Please enter customer complaint");
    if (priority === "") return message.error("Please select priority");
    if (
      test.test != "addNewTicket" &&
      status != "Closed" &&
      followUpNotes === ""
    )
      return message.error("Please enter follow up notes");
    if (
      test.test != "addNewTicket" &&
      status != "Closed" &&
      followUpDate.diff(new Date(), "days") < 0
    )
      return message.error("Please select follow up date greater than today");
    setSpinnerState(true);
    var tempImage = [...imagesURI];
    let tempArray = oldDetails.itemImages ?? [];
    if (imagesBase64.length > 0)
      for (let i = 0; i < imagesURI.length; i++) {
        if (
          (test === "addNewTicket" && imagesURI.length > 0) ||
          (test != "addNewTicket" && !tempArray.includes(imagesURI[i]))
        ) {
          try {
            const response = await fetch(api + "uploadImage", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                base64: imagesBase64[i],
                fileName: uuidv4() + ".jpeg",
              }),
            });
            const responseJson = await response.json();
            tempImage[i] = responseJson.Location;
            // counter++;
            console.log("Image changed at " + i);
          } catch (error) {
            console.error(error);
          }
        }
      }

    if (test.test === "addNewTicket") addItem(tempImage);
    else editItem(tempImage);
  };

  const onImageChange = (event) => {
    if (imagesURI.length + event.target.files.length > 6)
      return alert("Upto 6 images allowed");
    const files = event.target.files;
    const newImagesURI = [...imagesURI];
    const newImagesBase64 = [...imagesBase64];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      newImagesURI.push(URL.createObjectURL(files[i]));
      reader.onload = (e) => {
        newImagesBase64.push(
          e.target.result.replace(/^data:image\/\w+;base64,/, "")
        );
      };
    }
    setImagesBase64(newImagesBase64);
    setImagesURI(newImagesURI);
  };

  const deleteImage = (index) => {
    let temp = [...imagesURI];
    let tempBase64 = [...imagesBase64];
    temp.splice(index, 1);
    tempBase64.splice(index, 1);
    setImagesURI(temp);
    setImagesBase64(tempBase64);
  };

  return (
    <div>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="bill-heading">Ticket Details {randomId}</h3>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginRight: "8px", paddingRight: 25 }}
              disabled={spinnerState}
              onClick={() => checkInput()}
            >
              {" "}
              <PlusOutlined />
              {test.test === "addNewTicket" ? `Add Ticket` : `Update Ticket`}
            </Button>
          </Col>
          <div className="divider"></div>
        </Row>

        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Customer Name:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Customer Number"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Phone Number:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength={10}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Address:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  maxLength={300}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Order No:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Order ID"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  maxLength={30}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Complaint:
                </label>
                <TextField
                  style={{ flex: 1 }}
                  value={complaint}
                  onChange={(e) => setComplaint(e.target.value)}
                  multiline
                  rows={3}
                  placeholder="Complaint Details"
                  margin="normal"
                  size="small"
                  inputProps={{
                    maxLength: 500,
                  }}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Priority
                </label>
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    flex: 1,
                  }}
                  value={priority}
                  onChange={(value) => {
                    setPriority(value);
                  }}
                >
                  <Option value="">Select Priority</Option>
                  {props.common.priorityArray.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </Col>
            {test.test != "addNewTicket" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Status
                  </label>
                  <Select
                    style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                    value={status}
                    onChange={(value) => {
                      setStatus(value);
                    }}
                  >
                    {props.common.tcktStatusArray.map((key) => (
                      <Option value={key}>{key}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
            )}
          </Row>

          {test.test != "addNewTicket" && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Assign to outlet
                  </label>
                  <Select
                    style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                    value={assignToLocation}
                    onChange={(value) => {
                      setToLocation(value);
                      setToUser("");
                    }}
                  >
                    <Option value="">Select Outlet</Option>
                    {Object.keys(userByOutlet).map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              {assignToLocation != "" && (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0",
                    }}
                  >
                    <label
                      style={{
                        width: "120px",
                        marginRight: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Assign to person
                    </label>
                    <Select
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        flex: 1,
                      }}
                      value={assignToUser}
                      onChange={(value) => {
                        setToUser(value);
                      }}
                    >
                      <Option value="">Select User</Option>
                      {Object.keys(userByOutlet[assignToLocation].users).map(
                        (item) => (
                          <Option value={item}>{item}</Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
              )}
            </Row>
          )}
          <Row gutter={[16, 16]}>
            {imagesURI && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Image:
                  </label>
                  <div>
                    <input
                      type="file"
                      multiple
                      name="myImage"
                      accept=".png, .jpg, .jpeg"
                      onChange={onImageChange}
                    />
                    <div
                      style={{
                        marginTop: -25,
                        marginLeft: "78%",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      {imagesURI.map((uri, index) => (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setHovered(true)}
                          onMouseLeave={() => setHovered(false)}
                        >
                          <img
                            key={index}
                            src={uri}
                            alt={`Image ${index}`}
                            style={{
                              width: "70px",
                              height: "70px",
                              objectFit: "cover",
                              border: "1px solid #ccc",
                            }}
                            onClick={() => {
                              setZoomURL(uri);
                            }}
                          />
                          {isHovered && (
                            <DeleteOutlined
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                background: "black",
                                color: "white",
                                border: "none",
                                padding: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteImage(index);
                              }}
                            >
                              Delete
                            </DeleteOutlined>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Card>

        <div className="divider2"></div>
        {test.test !== "addNewTicket" && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card>
                {status !== "Cancelled" && (
                  <>
                    <h3>
                      Added By: {addedBy} on{" "}
                      {moment(date, "YYYY-MM-DD").format("DD MMM")}
                    </h3>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginBottom: 16 }}>
                        <span>Follow up notes: </span>
                        <Input
                          style={{ width: "200px", marginTop: 8 }}
                          maxLength={100}
                          value={followUpNotes}
                          onChange={(e) => setFollowUpNotes(e.target.value)}
                          placeholder="Follow-Up notes.."
                        />
                      </div>
                      <div style={{ marginBottom: 16, marginLeft: 20 }}>
                        <span>Next Follow up: </span>
                        <DatePicker
                          style={{ width: "200px", marginTop: 8 }}
                          placeholder="Next Follow Up Date"
                          onChange={(date) => setFollowUpDate(date)}
                          value={followUpDate}
                        />
                      </div>
                    </div>
                  </>
                )}
                {Object.entries(followUpLog).length > 0 && (
                  <div style={{ marginTop: -15 }}>
                    <h3>Follow Up Details</h3>
                    {Object.entries(followUpLog).map(([key, value]) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <p style={{ width: "30%" }}>{value.updatedBy}</p>
                          <p style={{ width: "10%" }}>
                            {moment(key).format("DD MMM YY")}
                          </p>
                          <p style={{ width: "60%" }}>
                            {value.followUpNotes} by
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        )}
      </div>
      {zoomURL != "" && <ZoomImage setZoomURL={setZoomURL} zoomURL={zoomURL} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddTicket);
