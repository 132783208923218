import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, message, Card, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import { getItemsByOutletPagination, getAttndByYearFunc } from "./functionCall";
import GeneratePayroll from "./generatePayroll";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
const { Option } = Select;

function AddLoan(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [empId, setEmpId] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentDone, setPaymentDone] = useState(0);
  const [paymentPending, setPaymentPending] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [installmentCount, setInstallmentCount] = useState(0);
  const [installmentDone, setInstallmentDone] = useState(0);
  const [installmentLog, setInstallmentLog] = useState({});
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [allItems, setAllItems] = useState({});
  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewRecord") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Payroll")) navigate("/homePage");
    getUser();
  }, []);

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      "Employee",
      "live",
      outletShopId
    );
    let temp = {};
    allItems.map((item) => {
      temp[item.id] = {};
      temp[item.id].id = item.id;
      temp[item.id].name = item.name;
    });
    setAllItems(temp);
  };

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setName(res.name);
        setTotalAmount(res.totalAmount);
        setPaymentDone(res.paymentDone);
        setPaymentPending(res.paymentPending);
        setPaymentStatus(res.paymentStatus);
        setInstallmentAmount(res.installmentAmount);
        setInstallmentCount(res.installmentCount);
        setInstallmentDone(res.installmentDone);
        setInstallmentLog(JSON.parse(res.installmentLog));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async () => {
    let randomId = Math.floor(Math.random() * 10000000000) + 1;
    let installmentAmount =
      Math.round((totalAmount / installmentCount) * 100) / 100;
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: randomId,
          addedBy: addedBy,
          type: "Loan",
          empId: empId,
          name: name,
          totalAmount: totalAmount,
          paymentDone: 0,
          paymentPending: totalAmount,
          paymentStatus: paymentStatus,
          installmentAmount: installmentAmount,
          installmentCount: installmentCount,
          installmentDone: 0,
          installmentLog: JSON.stringify({}),
        },
      });
      editItem(empId, installmentAmount);
      message.error("Loan details Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async (id, installmentAmount) => {
    let temp = {};
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: id },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        temp = JSON.parse(res.salaryDetails);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
    console.log(temp.loanAdvance);
    let amount =
      parseFloat(temp.loanAdvance == "" ? 0 : temp.loanAdvance) +
      installmentAmount;
    temp.loanAdvance = amount.toFixed(2);
    console.log(temp.loanAdvance);

    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: id,
          salaryDetails: JSON.stringify(temp),
          updatedBy: updatedBy,
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (name == "") return message.error("Please select employee");
    if (totalAmount != "" && isNaN(totalAmount))
      return message.error("Please enter valid total");
    if (installmentCount != "" && isNaN(installmentCount))
      return message.error("Please enter valid count");
    setSpinnerState("true");

    if (test.test === "addNewRecord") addItem();
    // else editItem();
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Loan</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            {" "}
            <PlusOutlined />
            {test.test === "addNewRecord" ? "Add Loan" : "Update Loan"}
          </Button>
        </Col>
      </Row>
      <div className="divider"></div>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "180px",
                  // marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Name
              </label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    flex: 1,
                  }}
                  value={name}
                  onChange={(value) => {
                    setName(allItems[value].name);
                    setEmpId(value);
                  }}
                >
                  <Option value="">Select a person</Option>
                  {Object.entries(allItems).map(([key, value]) => {
                    return <Option value={key}>{value.name}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Total Amount
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter amount"
                onChange={(e) => setTotalAmount(e.target.value)}
                maxLength={10}
                value={totalAmount}
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Number of installments
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter number of installment"
                onChange={(e) => setInstallmentCount(e.target.value)}
                maxLength={5}
                value={installmentCount}
              />
            </div>
          </Col>
        </Row>
      </Card>
      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Payment Done
              </label>
              <label>{paymentDone}</label>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Payment Pending
              </label>
              <label>{paymentPending}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Payment Status
              </label>
              <label>{paymentStatus}</label>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Installment Amount
              </label>
              <label>{installmentAmount}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <div style={{ display: "flex" }}>
              <label
                style={{
                  width: "180px",
                  fontWeight: "bold",
                }}
              >
                Installment Done
              </label>
              <label>{installmentDone}</label>
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row>
          <Col span={24}>
            {installmentLog.length > 0 &&
              installmentLog.map(([key, value]) => {
                return (
                  <div>
                    <p>test</p>
                  </div>
                );
              })}
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddLoan);
