import React from "react";
import "../styles/dashBoard.css";
import { connect } from "react-redux";

function Dashboard(props) {
  return (
    <div className="Dashboard">
      <div className="dashboard-container">
        <div>
          <h3 className="card-heading">Welcome to Royal Fabrics CRM Portal</h3>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
