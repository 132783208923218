import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button, message } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";

function ListGroups(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [shopId, setShopId] = useState("");
  const [term, setTerm] = useState("");

  const Columns = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "Edit",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddGroups/${id}`)}>Edit</a>
      ),
    },
    ...(props.common.shopType === "master"
      ? [
          {
            title: "Delete",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id) => (
              <a onClick={() => deleteItem(id)} style={{ color: "red" }}>
                Delete
              </a>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemsByType(user.attributes["custom:shopId"], "false");
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Product")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Group", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const deleteItem = async (id) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: { id: id, status: "deleted" },
      });
      message.error("Group Deleted");
      getItemsByType(shopId, "false");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <label style={{ fontSize: 16, fontWeight: "bold", paddingLeft: 10 }}>
            {term == ""
              ? allItems.length
              : allItems.filter(
                  (item) =>
                    item.itemName
                      .toString()
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1
                ).length}{" "}
            Products(s)
          </label>
          <TextField
            id="outlined-basic"
            label="Search by name"
            name="search by name"
            variant="outlined"
            size="small"
            style={{ width: "60%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          {props.common.shopType === "master" && (
            <Button type="primary">
              <Link to="../AddGroups/addNewGroup">
                <PlusOutlined /> Add New Product
              </Link>
            </Button>
          )}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.itemDescription
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ x: "50vw", y: 620 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
