import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../styles/signIn.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Button, message, Select, Input, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getItemsByTypeFunc } from "./functionCall";

const { Option } = Select;

function SignUp(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [shopName, setShopName] = useState("");
  const [role, setRole] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (props.common.shopType === "master") {
      setShopName(props.common.shopName);
      setShopId(props.common.shopId);
    }
    if (props.common.shopType === "outlet") navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc(
      props.common.shopType === "root" ? "Company" : "Outlet",
      shopId
    );
    setAllItems(allItems);
  };

  const signUpUser = async () => {
    if (shopId === "NA") return;
    try {
      const { user } = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "custom:shopName": shopName,
          "custom:shopType":
            props.common.shopType === "root" ? "master" : "outlet",
          "custom:role": role,
          "custom:shopId": shopId,
          "custom:outletShopId":
            props.common.shopType === "root" ? shopId : outletShopId,
        },
      });
      message.error("User Created");
      setEmail("");
      setPassword("");
      setShopName("");
      setRole("");
      setShopId("");
      setOutletShopId("");
      navigate("/ListUsers/homepage/homePage");
    } catch (error) {
      if (error.code === "UsernameExistsException")
        message.error("You already have an account, Please SignIn");
      else console.log("error signing up:", error);
    }
  };

  const checkInput = () => {
    if (email === "") return message.error("Please enter email");
    var mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== "" && mailReg.test(email) === false)
      return message.error("Please enter valid email");
    if (password === "") return message.error("Please enter password");
    var passReg =
      /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g;
    if (passReg.test(password) === false)
      return message.error("Please enter a valid password");
    if (selectedItem === "" && props.common.shopType === "root")
      return message.error("Please select company name");
    if (selectedItem === "" && props.common.shopType === "master")
      return message.error("Please select shop name");
    if (role === "") return message.error("Please enter role");
    setSpinnerState(true);
    signUpUser();
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Enter User Details</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            {" "}
            <PlusOutlined /> Add User
          </Button>
        </Col>
        <div className="divider"></div>
      </Row>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Email:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter  Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                maxLength={100}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Password:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Name"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                maxLength={100}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                {props.common.shopType === "root"
                  ? "Select Company Name"
                  : "Select Shop Location"}
              </label>
              {(props.common.shopType === "root" && (
                <Select
                  style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                  value={selectedItem}
                  onChange={(value) => {
                    let temp = JSON.parse(value);
                    setShopId(temp.id);
                    setShopName(temp.shopName);
                    setSelectedItem(value);
                  }}
                >
                  <Option value="">
                    Select{" "}
                    {props.common.shopType === "root" ? "Company" : "Shop"}
                  </Option>
                  {allItems.map((option) => (
                    <Option key={option.value} value={JSON.stringify(option)}>
                      {option.shopName}
                    </Option>
                  ))}
                </Select>
              )) || (
                <Select
                  style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                  value={selectedItem}
                  onChange={(value) => {
                    let temp = JSON.parse(value);
                    setOutletShopId(temp.id);
                    setSelectedItem(value);
                  }}
                >
                  <Option value="">Select Location</Option>
                  {allItems.map((option) => (
                    <Option key={option.value} value={JSON.stringify(option)}>
                      {option.location}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Select Role:
              </label>
              <Select
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                value={role}
                onChange={(value) => {
                  setRole(value);
                }}
              >
                <Option value="">Select Role</Option>
                {props.common.roleArray.map((key) => (
                  <Option value={key}>{key}</Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
