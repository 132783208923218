import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Button, message, Card, Input, Select } from "antd";
import { checkAccess } from "./AuthorizationService";
import { getItemIdByTypeFunc } from "./functionCall";
const { Option } = Select;

function AddComponents(props) {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [lowStockValue, setLowStockValue] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [color, setColor] = useState("Other");
  const [itemTags, setItemTag] = useState([]);
  const [rawMaterial, setRawMaterial] = useState(0);
  let test = useParams();

  const filteredOptions = props.common.productTags.filter(
    (o) => !itemTags.includes(o)
  );

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setUpdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getLowStockValue(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Inventory")) navigate("/homePage");
    getUser();
  }, []);

  const getLowStockValue = async (outletShopId) => {
    let allItems = await getItemIdByTypeFunc("Inventory", test.test);
    allItems = allItems.filter((item) => item.outletShopId == outletShopId);
    if (allItems.length == 0) {
      setLowStockValue(0);
      setItemTag([]);
    } else {
      setLowStockValue(allItems[0].lowStockValue);
      setItemTag(allItems[0].itemTags ?? []);
      setRawMaterial(allItems[0].rawMaterial);
    }
  };

  const editItem = async (id, quantityLog) => {
    let tempLog = quantityLog;
    if (tempLog[color] === undefined) {
      tempLog[color] = {};
      tempLog[color].incomingQuantity = 0;
      tempLog[color].remainingQuantity = 0;
      tempLog[color].soldQuantity = 0;
      tempLog[color].lowStockValue = 0;
    }

    tempLog[color].incomingQuantity =
      parseFloat(tempLog[color].incomingQuantity) + parseFloat(quantity);
    tempLog[color].remainingQuantity =
      parseFloat(tempLog[color].remainingQuantity) + parseFloat(quantity);
    tempLog[color].updatedBy = updatedBy;
    tempLog[color].date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    try {
      await API.graphql({
        query: mutations.updateInventory,
        variables: {
          id: id,
          inventoryQuantity: quantity,
          lowStockValue: lowStockValue,
          updatedBy: updatedBy,
          time: moment(new Date()).format("HH:mm:ss"),
          quantityLog: JSON.stringify(tempLog),
          itemTags: itemTags,
          rawMaterial: rawMaterial,
        },
      });
      await addInventoryLog();
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async () => {
    let tempLog = {};

    tempLog[color] = {};
    tempLog[color].incomingQuantity = parseFloat(quantity);
    tempLog[color].remainingQuantity = parseFloat(quantity);
    tempLog[color].soldQuantity = 0;
    tempLog[color].lowStockValue = 0;
    tempLog[color].updatedBy = updatedBy;
    tempLog[color].date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          itemId: test.test,
          shopId: shopId,
          outletShopId: outletShopId,
          incomingQuantity: quantity,
          remainingQuantity: quantity,
          soldQuantity: 0,
          lowStockValue: lowStockValue == "" ? 0 : lowStockValue,
          updatedBy: updatedBy,
          type: "Inventory",
          time: moment(new Date()).format("HH:mm:ss"),
          quantityLog: JSON.stringify(tempLog),
          itemTags: itemTags,
          updatedTime: moment(new Date()).format("HH:mm:ss"),
          updatedDate: moment(new Date()).format("YYYY-MM-DD"),
          rawMaterial: 0,
        },
      });
      addInventoryLog();
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addInventoryLog = async () => {
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          itemId: test.test,
          shopId: shopId,
          outletShopId: outletShopId,
          quantity: quantity,
          from: "CRM",
          trackingID: "NA",
          action: "Added",
          updatedBy: updatedBy,
          type: "InventoryLog",
          time: moment(new Date()).format("HH:mm:ss"),
          color: color,
          rawMaterial: rawMaterial,
        },
      });
      message.error("Inventory updated");
      navigate("/ListInventory/homepage");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    // if (rawMaterial != 0 && quantity === "")
    //   return message.error("Please enter quantity");
    if (quantity != "" && isNaN(quantity))
      return message.error("Please enter valid price");
    // if (rawMaterial == 0 && quantity === "")
    //   return message.error("Please enter raw material");
    if (isNaN(rawMaterial))
      return message.error("Please enter valid raw material");
    // if (lowStockValue === "")
    //   return message.error("Please enter low stock value");
    if (lowStockValue != "" && isNaN(lowStockValue))
      return message.error("Please enter valid stock value");

    setSpinnerState("true");
    let allItems = await getItemIdByTypeFunc("Inventory", test.test);
    allItems = allItems.filter((item) => item.outletShopId == outletShopId);
    if (allItems.length == 0) addItem();
    else editItem(allItems[0].id, JSON.parse(allItems[0].quantityLog));
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Update Quantity</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px" }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            {" "}
            Update Inventory
          </Button>
        </Col>
        <div className="divider"></div>
      </Row>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Quantity:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                maxLength={10}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Low Stock Value:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Low Stock Value"
                value={lowStockValue}
                onChange={(e) => setLowStockValue(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Select Color:
              </label>
              <Select
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                onChange={(value) => {
                  setColor(value);
                }}
                value={color}
              >
                {props.common.colorArray
                  .concat(props.common.meshColorArray)
                  .map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Tags:
              </label>
              <Select
                style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                mode="multiple"
                placeholder="Tags"
                value={itemTags}
                onChange={setItemTag}
                options={filteredOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Raw Material
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter raw material"
                value={rawMaterial}
                onChange={(e) => setRawMaterial(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddComponents);
