import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button, Switch } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import {
  getItemsByShopTypeFunc,
  getItemsByShopTypeFunc100,
} from "./functionCall";

function ListST(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [shopId, setShopId] = useState("");
  const [showCount, setShowCount] = useState({});

  const Columns = [
    {
      title: "PR Name",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Added Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Item",
      dataIndex: "componentList",
      key: "componentList",
      ellipsis: true,
      render: (componentList) =>
        Object.entries(JSON.parse(componentList)).map(
          ([key, value]) => value.itemName
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => <a onClick={() => navigate(`../AddPR/${id}`)}>View</a>,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("PurchaseRequest")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = [];
    if (showAll)
      allItems = await getItemsByShopTypeFunc("PurchaseRequest", shopId);
    else allItems = await getItemsByShopTypeFunc100("PurchaseRequest", shopId);

    let temp = {
      Live: 0,
      New: 0,
      Review: 0,
      Placed: 0,
      Delivered: 0,
      Rejected: 0,
    };
    temp.Live = allItems.filter((item) => item.status == "live").length;
    temp.New = allItems.filter((item) => item.status == "New").length;
    temp.Review = allItems.filter((item) => item.status == "In-Review").length;
    temp.Placed = allItems.filter(
      (item) => item.status == "Order Placed"
    ).length;
    temp.Delivered = allItems.filter(
      (item) => item.status == "Delivered"
    ).length;
    temp.Rejected = allItems.filter((item) => item.status == "Rejected").length;
    setShowCount(temp);

    setAllItems(
      allItems.sort(
        (a, b) =>
          moment(b.addedDate + " " + b.time, "YYYY-MM-DD  HH:mm:ss") -
          moment(a.addedDate + " " + a.time, "YYYY-MM-DD  HH:mm:ss")
      )
    );
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      await getItemsByType(shopId);
    };
    if (shopId != "") getData();
  }, [showAll]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search by name"
            name="search by name"
            variant="outlined"
            size="small"
            style={{ width: "70%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all PR"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />
          <Button type="primary">
            <Link to="../AddPR/addNewPR">New Purchase Request</Link>
          </Button>
        </div>
        <div style={{ paddingBottom: 10 }}>
          {allItems.length} Purchase Request
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          {Object.entries(showCount).map(([key, value]) => {
            return (
              <p style={{ fontWeight: "bold" }}>
                {key} : {value}
              </p>
            );
          })}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ x: "50vw", y: 420 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListST);
