import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../images/Logo.jpg";
import moment from "moment";
import customFont from "../fonts/arial.ttf";
Font.register({ family: "Arial", src: customFont });

// Create Document Component
export default function GenerateOrderDetailsPDF(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image src={Logo} style={{ width: 70, height: 70 }} />
              <Text>Royal Fabrics</Text>
              <Text>Email: royalmosquitonets.gmail.com</Text>
            </View>
            <View style={styles.headerRight}>
              <Text style={styles.from}>WORK ORDER</Text>
              <View style={styles.headerInnerRight}>
                <View>
                  <Text style={[styles.borderLeft, { height: 16 }]}>
                    Reference ID:
                  </Text>
                  <Text style={styles.borderLeft}>Enquiry Date:</Text>
                  <Text style={styles.borderLeft}>Customer Visit Date:</Text>
                  <Text style={styles.borderLeft}>
                    Estimated Delivery Date:
                  </Text>
                </View>
                <View>
                  <Text
                    style={[styles.borderRight, { fontSize: 13, height: 16 }]}
                  >
                    {props.item.randomId}
                  </Text>
                  <Text style={styles.borderRight}>
                    {moment(props.item.addedDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    )}
                  </Text>
                  <Text style={styles.borderRight}>
                    {props.item.visitDate
                      ? moment(props.item.visitDate, "YYYY-MM-DD").format(
                          "DD MMM"
                        )
                      : "NA"}
                  </Text>
                  <Text style={styles.borderRight}>
                    {props.item.estimatedDelivery
                      ? moment(
                          props.item.estimatedDelivery,
                          "YYYY-MM-DD"
                        ).format("DD MMM")
                      : "NA"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.topSection}>
            <Text>Customer Name: {props.item.name}</Text>
            <Text>Bill To: {props.item.address}</Text>
            <Text>Ship To: {props.item.shippingAddress}</Text>
            <Text>Notes: {props.item.notes}</Text>
            <Text>Delivery Notes: {props.item.deliveryNotes}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={{ width: "10%" }}>S No.</Text>
              <Text style={{ width: "25%" }}>Product Details</Text>
              <Text style={{ width: "10%" }}>Area</Text>
              <Text style={{ width: "10%" }}>PC</Text>
              <Text style={{ width: "15%" }}>W (cm)</Text>
              <Text style={{ width: "15%" }}>H (cm)</Text>
              <Text style={{ width: "10%" }}>Qty</Text>
              <Text style={{ width: "10%" }}>Sqft</Text>
            </View>

            {Object.entries(JSON.parse(props.item.orderDetails)).map(
              ([key, value], index) => {
                return (
                  <>
                    <View style={styles.tableData}>
                      <Text style={{ width: "10%" }}>{index + 1}</Text>
                      <View style={{ width: "25%" }}>
                        <Text>{value.itemName}</Text>
                        <Text>{value.instructions}</Text>
                        <Text>Profile: {value.profileColor}</Text>
                        <Text>Mesh: {value.meshColor}</Text>
                      </View>
                      <View style={{ width: "80%" }}>
                        {Object.values(value.dimensionMap)
                          .sort((a, b) => a.timestamp - b.timestamp)
                          .map((value1) => {
                            return (
                              <>
                                <View style={styles.tableData}>
                                  <Text style={{ width: "10%" }}>
                                    {value1.location}
                                  </Text>
                                  <Text style={{ width: "10%" }}>
                                    {value1.panelCount}
                                  </Text>
                                  <Text style={{ width: "15%" }}>
                                    {value1.width}
                                  </Text>
                                  <Text style={{ width: "15%" }}>
                                    {value1.height}
                                  </Text>
                                  <Text style={{ width: "10%" }}>
                                    {value1.quantity}
                                  </Text>
                                  <Text style={{ width: "10%" }}>
                                    {value1.sqft}
                                  </Text>
                                </View>
                              </>
                            );
                          })}
                      </View>
                    </View>
                  </>
                );
              }
            )}
          </View>
          {props.profileMap && (
            <View style={styles.table}>
              <Text style={{ fontSize: 10 }}>
                Profile Used: {props.profileLength} Feet
              </Text>
              <Text style={{ fontSize: 10 }}>
                Wastage per cut: {parseFloat(props.wastage).toFixed(2)} cm
              </Text>
              <View style={styles.tableHeading}>
                <Text style={{ width: "10%" }}>Profile</Text>
                <Text style={{ width: "5%" }}>Count</Text>
                <Text style={{ width: "30%" }}>Cutting List</Text>
                <Text style={{ width: "55%" }}>Combination</Text>
              </View>
              {Object.values(props.profileMap).map((item) => {
                return (
                  <>
                    <View style={styles.tableData}>
                      <Text style={{ width: "10%" }}>{item.profile}</Text>
                      <Text style={{ width: "5%" }}>{item.count}</Text>
                      <Text style={{ width: "30%" }}>
                        {item.cuttingList.map((item1, index) => {
                          return (
                            <>
                              {index + 1} - {item1}
                              {index !== item.cuttingList.length - 1 && (
                                <Text>{"\n"}</Text>
                              )}
                            </>
                          );
                        })}
                      </Text>
                      <Text style={{ width: "55%" }}>
                        {item.combination.map((item1, index) => {
                          return (
                            <>
                              {index + 1} -{" "}
                              {item1.map(
                                (value) =>
                                  parseFloat(value - props.wastage).toFixed(1) +
                                  "cm "
                              )}
                              -{" "}
                              {(
                                parseFloat(props.profileLength * 30.48) -
                                item1.reduce(function (a, b) {
                                  return a + b;
                                })
                              ).toFixed(1) + "cm wastage"}
                              {index !== item.combination.length - 1 && (
                                <Text>{"\n"}</Text>
                              )}
                            </>
                          );
                        })}
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
          )}
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 30,
    marginRight: 30,
    fontSize: 8,
  },
  headerLeft: {
    border: "1px solid black",
    borderRightWidth: 1.3,
    padding: 5,
    width: 300,
  },
  headerRight: {
    border: "1px solid black",
    borderLeftWidth: 1.3,
    width: 300,
  },
  headerInnerRight: {
    flexDirection: "row",
    alignContent: "space-between",
    fontSize: 9,
  },
  borderLeft: {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    paddingLeft: 2,
    width: 120,
    textAlign: "left",
  },
  borderRight: {
    borderBottom: "1px solid black",
    paddingRight: 5,
    width: 126,
    textAlign: "right",
  },
  from: {
    borderBottom: "1px solid black",
    textTransform: "uppercase",
    textAlign: "center",
    padding: 5,
    backgroundColor: "#D3D3D3",
    fontWeight: "bold",
  },
  topSection: {
    textAlign: "left",
    marginLeft: 20,
    marginTop: 10,
    padding: 5,
    fontSize: 9,
    border: "1px solid black",
    width: "515",
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataGrey: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#D3D3D3",
  },
  name: {
    padding: 2,
    width: 150,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 55,
    textAlign: "center",
  },
  image: {
    padding: 2,
    width: 70,
    textAlign: "center",
  },
  color: {
    padding: 2,
    width: 65,
    textAlign: "center",
  },
  number: {
    padding: 2,
    width: 30,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 80,
    textAlign: "right",
    fontFamily: "Arial",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  notes: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
  },
  belowDetails: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
    fontFamily: "Times-Roman",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
    fontFamily: "Times-Bold",
  },
  leftPadding: {
    paddingLeft: 10,
  },
  signature: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "right",
    fontSize: 8,
    paddingRight: 30,
  },
});
