import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import { Row, Col, Card, Button, message, Checkbox } from "antd";
import { checkAccess } from "./AuthorizationService";

function ListGroups(props) {
  const navigate = useNavigate();
  const [formulaList, setFormulaList] = useState([]);
  const [shopId, setShopId] = useState("");

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemById(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ManageFormula")) navigate("/homePage");
  }, []);

  const getItemById = async (shopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      setFormulaList(JSON.parse(allTodos.data.getItemById.formulaList));
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: shopId,
          formulaList: JSON.stringify(formulaList),
        },
      });
      props.setShopDetails({ formulaList: JSON.stringify(formulaList) });
      message.error("Formula List Updated");
      navigate("/HomePage");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const onChange = (key) => {
    let temp = { ...formulaList };
    if (Object.keys(temp).includes(key)) delete temp[key];
    else {
      temp[key] = {};
      temp[key].key = key;
      temp[key].formula = props.common.calBasedOn[key].formula;
      temp[key].explaination = props.common.calBasedOn[key].explaination;
      temp[key].example = props.common.calBasedOn[key].example;
      temp[key].UOM = props.common.calBasedOn[key].UOM;
    }
    setFormulaList(temp);
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{ marginTop: 10, marginBottom: 20 }}
          offset={17}
        >
          <Button onClick={() => editItem()} type="primary">
            Update Formula List
          </Button>
        </Col>
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={{ marginTop: 10 }}
            offset={2}
          >
            <Card
              style={{
                width: "80%",
                padding: 10,
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div>
                {Object.entries(props.common.calBasedOn).map(([key, value]) => {
                  return (
                    <>
                      <div>
                        <p>
                          <Checkbox
                            onChange={() => onChange(key)}
                            checked={Object.keys(formulaList).includes(key)}
                          ></Checkbox>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          {key} - {value.formula} - {value.example}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    setShopDetails: (data) =>
      dispatch({ type: "SET_SHOPDETAILS", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
