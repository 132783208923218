import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button, Select, Switch } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getAllItemsPagination,
  getAllItemsPagination100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ListLoans() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [showAll, setShowAll] = useState(false);

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "7%",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      width: "7%",
      ellipsis: true,
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "7%",
      ellipsis: true,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "7%",
      ellipsis: true,
    },
    {
      title: "Installment Count",
      dataIndex: "installmentCount",
      key: "installmentCount",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Installment Done",
      dataIndex: "installmentDone",
      key: "installmentDone",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Payment Done",
      dataIndex: "paymentDone",
      key: "paymentDone",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Payment Pending",
      dataIndex: "paymentPending",
      key: "paymentPending",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      width: "7%",
      ellipsis: true,
      render: (id) => <a onClick={() => navigate(`../AddLoan/${id}`)}>View</a>,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Leads")) navigate("/homePage");
  }, []);

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      await getAllItemsByType(outletShopId);
    };
    if (outletShopId != "") getData();
  }, [showAll]);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = [];
    if (showAll) allItems = await getAllItemsPagination("Loan", outletShopId);
    else allItems = await getAllItemsPagination100("Loan", outletShopId);
    setAllItems(allItems);
  };

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search lead"
            name="search lead"
            variant="outlined"
            size="small"
            style={{ width: 600, backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          {/* <Select
            style={{
              backgroundColor: "white",
              flex: 1,
              marginLeft: 10,
              marginRight: 10,
              height: 40,
            }}
            value={status}
            onChange={(value) => {
              setStatus(value);
            }}
          >
            <Option value="">Select Status</Option>
            {statusList.map((key) => (
              <Option value={key}>{key}</Option>
            ))}
          </Select> */}

          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all Loans"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />

          <Button type="primary" style={{ width: 100 }}>
            <Link to="../AddLoan/addNewRecord">
              <PlusOutlined /> New Loan
            </Link>
          </Button>
        </div>
        <p style={{ fontWeight: "bold" }}>
          {term == ""
            ? allItems.length
            : allItems.filter(
                (item) =>
                  item.name
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1
              ).length}{" "}
          lead(s)
        </p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListLoans);
