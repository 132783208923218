import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, DatePicker, Button } from "antd";
import { getProdGroupReportPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";

function ReportPG() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;

  const headers = [
    { label: "Name", key: "name" },
    { label: "Description", key: "desc" },
    { label: "Count", key: "count" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let temp = {};
    let allItems = await getProdGroupReportPagination(
      "Production",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD"),
      "Packed",
      "Dispatched"
    );
    if (allItems.length > 0)
      allItems.map((item) => {
        Object.entries(JSON.parse(item.orderDetails)).map(([key, value]) => {
          let name = value.itemName;
          if (temp[name] == undefined) {
            temp[name] = {};
            temp[name].name = name;
            temp[name].desc = value.itemDescription;
            temp[name].count = 0;
            temp[name].sqft = 0;
          }
          Object.entries(value.dimensionMap).map(([key1, value1]) => {
            temp[name].count = temp[name].count + parseFloat(value1.quantity);
            temp[name].sqft = temp[name].sqft + parseFloat(value.areaInSqFt);
          });
        });
      });
    setReportList(
      Object.values(temp).sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    );
    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(outletShopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        {spinnerState && <SpinnerCircular />}
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          {reportList.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={reportList}
                headers={headers}
                filename={
                  "Products-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <h2>
                Product count for work order between{" "}
                {dateRange[0].format("DD MMM YY")}
                {" - "}
                {dateRange[1].format("DD MMM YY")}
              </h2>
              <div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ fontWeight: "bold", width: 200 }}>
                          {item.name}
                        </p>
                        <p style={{ width: 300 }}>{item.desc}</p>
                        <p style={{ marginRight: 2, width: 100 }}>
                          {item.count}
                        </p>
                        <p style={{ marginRight: 2, width: 100 }}>
                          {item.sqft.toFixed(2)}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportPG);
