import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create Document Component
export default function GenerateSQFTPDF(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.topSection}>
            <Text style={{ fontSize: 18, fontWeight: "bold", padding: 10 }}>
              Royal Fabrics
            </Text>
            <Text style={{ padding: 10 }}>
              Period: {props.dateRange[0].format("DD-MM-YYYY")} to{" "}
              {props.dateRange[1].format("DD-MM-YYYY")}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={styles.sNo}>S No.</Text>
              <Text style={styles.ID}>Id</Text>
              <Text style={styles.name}>Location</Text>
              <Text style={styles.count}>Order Count</Text>
              <Text style={styles.amount}>Total Order</Text>
            </View>

            {Object.entries(props.allOutlets).map(([key, value], index) => {
              return (
                <>
                  <View style={styles.tableData}>
                    <Text style={styles.sNo}>{index + 1}</Text>
                    <Text style={styles.ID}>{value.randomId}</Text>
                    <Text style={styles.name}>{value.location}</Text>
                    <Text style={styles.count}>{value.orderCount}</Text>
                    <Text style={styles.amountRight}>
                      {parseFloat(value.totalOrder).toFixed(2)}
                    </Text>
                  </View>
                </>
              );
            })}

            <View style={styles.tableHeading}>
              <Text style={styles.sNo}></Text>
              <Text style={styles.ID}></Text>
              <Text style={styles.name}></Text>
              <Text style={styles.count}>
                {Object.values(props.allOutlets).reduce(
                  (sum, value) => sum + value.orderCount,
                  0
                )}
              </Text>
              <Text style={styles.amountRight}>
                {parseFloat(
                  Object.values(props.allOutlets).reduce(
                    (sum, value) => sum + value.totalOrder,
                    0
                  )
                ).toFixed(2)}
              </Text>
              {/* <Text style={styles.count}>
                {Object.values(props.allOutlets).reduce(
                  (sum, value) => sum + value.quotationCount,
                  0
                )}
              </Text>
              <Text style={styles.amountRight}>
                {parseFloat(
                  Object.values(props.allOutlets).reduce(
                    (sum, value) => sum + value.totalQuotation,
                    0
                  )
                ).toFixed(2)}
              </Text> */}
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  topSection: {
    textAlign: "left",
    marginLeft: 20,
    marginTop: 10,
    padding: 5,
    fontSize: 10,
    width: "515",
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  ID: {
    width: "15%",
    textAlign: "left",
  },
  name: {
    width: "30%",
    textAlign: "left",
  },
  count: {
    width: "10%",
    textAlign: "center",
  },
  amount: {
    width: "15%",
    textAlign: "center",
  },
  amountRight: {
    width: "15%",
    textAlign: "right",
  },
  sNo: {
    width: "5%",
    textAlign: "center",
  },
});
