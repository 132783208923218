import React, { useState, useEffect } from "react";
import "../styles/addBanner.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import "../styles/addBill.css";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Table, Button, Select } from "antd";
import { checkAccess } from "./AuthorizationService";
import {
  getItemsByTypeFunc,
  getItemByOutletIdPagination,
} from "./functionCall";
const { Option } = Select;

function SalesPayments(props) {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [allOutlets, setAllOutlet] = useState([]);
  const [outletId, setOutletId] = useState("");
  const [location, setLocation] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [paymentPending, setPaymentPending] = useState(0);
  const [status, setStatus] = useState("");
  const [from, setFrom] = useState("");

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "10%",
      ellipsis: true,
      render: (paymentStatus) => (paymentStatus == "false" ? "Due" : "Done"),
    },
    {
      title: "Payment Pending",
      dataIndex: "paymentPending",
      key: "paymentPending",
      width: "10%",
      ellipsis: true,
      render: (paymentPending) => paymentPending.toFixed(2),
    },
    {
      title: "Payment Done",
      dataIndex: "paymentDone",
      key: "paymentDone",
      width: "10%",
      ellipsis: true,
      render: (paymentDone) => paymentDone.toFixed(2),
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "10%",
      ellipsis: true,
      render: (totalAmount) => totalAmount.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("SalesPayments")) navigate("/homePage");
  }, []);

  const getAllOutlets = async (from, shopId) => {
    let allItems = await getItemsByTypeFunc(from, shopId);
    allItems.map((item) => {
      item.location = from == "Outlet" ? item.location : item.shopName;
    });
    setAllOutlet(allItems);
  };

  useEffect(() => {
    const getInvoice = async () => {
      let tempPending = 0;
      let allPI = await getItemByOutletIdPagination(
        "SalesInvoice",
        "live",
        outletShopId,
        outletId
      );
      allPI.map((item) => {
        if (item.paymentStatus == "false") {
          tempPending = tempPending + item.paymentPending;
        }
      });
      if (status != "")
        allPI = allPI.filter((item) => item.paymentStatus == status);
      setAllInvoice(allPI);
      setPaymentPending(tempPending);
    };
    if (outletId != "") getInvoice();
    else {
      setAllInvoice([]);
      setPaymentPending(0);
    }
  }, [outletId, status]);

  useEffect(() => {
    const getOutlet = async () => {
      setOutletId("");
      setLocation("");
      await getAllOutlets(from, shopId);
    };
    if (from != "" && shopId != "") getOutlet();
  }, [from]);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Select
            style={{ backgroundColor: "white", width: "15%" }}
            value={from}
            onChange={(value) => {
              setFrom(value);
            }}
          >
            <Option value="">Select outlet/dealer</Option>
            <Option value="Outlet">Outlet</Option>
            <Option value="Dealer">Dealer</Option>
          </Select>
          <Select
            style={{ backgroundColor: "white", width: "25%" }}
            value={location}
            onChange={(value) => {
              if (value == "") {
                setAllInvoice([]);
                setPaymentPending(0);
                setOutletId("");
                setLocation("");
              } else {
                let temp = JSON.parse(value);
                setOutletId(temp.id);
                setLocation(temp.location);
              }
            }}
          >
            <Option value="">Select {from}</Option>
            {allOutlets.map((value) => {
              return (
                <Option value={JSON.stringify(value)}>{value.location}</Option>
              );
            })}
          </Select>
          {(outletId != "" && (
            <>
              <Select
                style={{ backgroundColor: "white", width: "10%" }}
                value={status}
                onChange={(value) => {
                  setStatus(value);
                }}
              >
                <Option value="">Select a status</Option>
                <Option value="false">Not Paid</Option>
              </Select>
              <label style={{ width: "20%" }}>
                Payment Pending: {paymentPending}
              </label>
              <Button
                type="primary"
                style={{ width: "10%" }}
                onClick={() =>
                  navigate(`../CreditReceipt/${outletId}/${paymentPending}`)
                }
              >
                Add Pay Received
              </Button>
              <Button
                type="primary"
                style={{ width: "10%" }}
                onClick={() => navigate(`../PaymentHistory/${outletId}/sales`)}
              >
                Payment History
              </Button>
            </>
          )) || <div style={{ width: "40%" }}></div>}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allInvoice}
                  scroll={{ y: 520 }}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesPayments);
