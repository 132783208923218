import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Table,
  message,
  Divider,
  Select,
} from "antd";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import { CloseOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import {
  getItemsByTypeFunc,
  getItemsByOutletPagination,
  getAllItemsPagination10,
} from "./functionCall";
import { v4 as uuidv4 } from "uuid";
import SendCoatPDF from "./genSendCoatPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
const { Option } = Select;

function AddProductGroups(props) {
  const navigate = useNavigate();
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [term, setTerm] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [address, setAddress] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [from, setFrom] = useState("");
  const [allVendors, setAllVendors] = useState({});
  const [vendorId, setVendorId] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  let test = useParams();

  const columns = [
    {
      title: "Material Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      ellipsis: true,
      render: (size, record) => (
        <Input
          value={size}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "size", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "size", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record) => (
        <Input
          value={quantity}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "quantity", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "quantity", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Dark Brown",
      dataIndex: "DB",
      key: "DB",
      ellipsis: true,
      render: (DB, record) => (
        <Input
          value={DB}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "DB", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "DB", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Golden Brown",
      dataIndex: "GB",
      key: "GB",
      ellipsis: true,
      render: (GB, record) => (
        <Input
          value={GB}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "GB", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "GB", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "White",
      dataIndex: "white",
      key: "white",
      ellipsis: true,
      render: (white, record) => (
        <Input
          value={white}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "white", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "white", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Ivory",
      dataIndex: "ivory",
      key: "ivory",
      ellipsis: true,
      render: (ivory, record) => (
        <Input
          value={ivory}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "ivory", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "ivory", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Gray",
      dataIndex: "gray",
      key: "gray",
      ellipsis: true,
      render: (gray, record) => (
        <Input
          value={gray}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "gray", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "gray", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Honey Gold",
      dataIndex: "HG",
      key: "HG",
      ellipsis: true,
      render: (HG, record) => (
        <Input
          value={HG}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "HG", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "HG", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Black",
      dataIndex: "black",
      key: "black",
      ellipsis: true,
      render: (black, record) => (
        <Input
          value={black}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "black", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "black", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "702",
      dataIndex: "w702",
      key: "w702",
      ellipsis: true,
      render: (w702, record) => (
        <Input
          value={w702}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "w702", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "w702", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "501",
      dataIndex: "w501",
      key: "w501",
      ellipsis: true,
      render: (w501, record) => (
        <Input
          value={w501}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "w501", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "w501", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Raw",
      dataIndex: "raw",
      key: "raw",
      ellipsis: true,
      render: (raw, record) => (
        <Input
          value={raw}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "raw", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "raw", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    ...(test.test === "addNewCoating"
      ? [
          {
            title: "Add",
            dataIndex: "key",
            key: "key",
            ellipsis: true,
            render: (key) => (
              <Button
                type="primary"
                danger
                onClick={() => removeComponent(key)}
              >
                Remove
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    ...(props.common.role != "Store" && props.common.role != "ProdManager"
      ? [
          {
            title: "Price",
            dataIndex: "masterPP",
            key: "masterPP",
            width: "10%",
            ellipsis: true,
          },
        ]
      : []),
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addComponent(record)}
          style={{ width: "55%" }}
        >
          <PlusOutlined /> Add
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
          if (test.test != "addNewCoating") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("Inventory")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      // console.log(allTodos.data.getItemById)
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setComponentList(JSON.parse(res.componentList));
        setItemDetails(res);
        setVendorName(res.vendorName);
        setAddress(res.address);
        setGSTNumber(res.GSTNumber);
        setFrom(res.from);
        setNotes(res.notes);
        setStatus(res.status);
        setTotalAmount(res.totalAmount ?? 0);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      "Vendor",
      "live",
      outletShopId
    );

    allItems = allItems.sort(function (a, b) {
      if (a.shopName.toLowerCase() < b.shopName.toLowerCase()) return -1;
      if (a.shopName.toLowerCase() > b.shopName.toLowerCase()) return 1;
      return 0;
    });

    let temp = {};
    allItems.map((item) => {
      if (temp[item.id] == undefined) {
        temp[item.id] = {};
        temp[item.id].id = item.id;
        temp[item.id].shopName = item.shopName;
        temp[item.id].phoneNumber = item.phoneNumber;
        temp[item.id].address = item.address;
        temp[item.id].GSTNumber = item.GSTNumber;
      }
    });
    setAllVendors(temp);
  };

  const addItem = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10("SendCoating", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "DC-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "DC-" + year + "-" + tempCounter;

    try {
      let AddItem = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "SendCoating",
          time: moment(new Date()).format("HH:mm:ss"),
          vendorName: vendorName,
          address: address,
          GSTNumber: GSTNumber,
          from: from,
          vendorId: vendorId,
          notes: notes,
          totalAmount: totalAmount,
        },
      });
      updateRawInventory(AddItem.data.addItem.id);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const updateRawInventory = async (id) => {
    try {
      await API.graphql({
        query: mutations.updateRawInventory,
        variables: {
          updatedBy: updatedBy,
          id: id,
        },
      });
      message.error("Send for coating");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          status: status,
          notes: notes,
          totalAmount: totalAmount,
          updatedBy: updatedBy,
        },
      });
      message.error("Status / Notes updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (vendorName == "") return message.error("Please select a vendor");
    if (address == "") return message.error("Please enter address");
    if (GSTNumber == "") return message.error("Please enter GST Number");
    if (from == "")
      return message.error("Please enter from where material is brought");
    if (Object.keys(componentList).length == 0)
      return message.error("Please add items to the list");

    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.quantity == 0) {
        flag = true;
        return message.error("Enter quantity for " + value.itemName);
      }
    });

    if (flag) return;
    setSpinnerState("true");
    if (test.test === "addNewCoating") addItem();
    else editItem();
  };

  const addComponent = (item) => {
    let tempList = { ...componentList };
    let key = uuidv4();
    tempList[key] = {};
    tempList[key].id = item.id;
    tempList[key].key = key;
    tempList[key].itemName = item.itemName;
    tempList[key].quantity = 0;
    tempList[key].DB = "";
    tempList[key].GB = "";
    tempList[key].white = "";
    tempList[key].ivory = "";
    tempList[key].gray = "";
    tempList[key].HG = "";
    tempList[key].black = "";
    tempList[key].w702 = "";
    tempList[key].w501 = "";
    tempList[key].raw = "";
    setComponentList(tempList);
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    temp[key][value] = data;
    setComponentList(temp);
  };

  const downloadPDF = async () => {
    await pdf(<SendCoatPDF item={itemDetails} />)
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `Powder Coating ${itemDetails.randomId}.pdf`);
      });
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1100px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginLeft: -20,
            display: "flex",
            justifyContent: "space-between",
            margin: 10,
          }}
        >
          <h1 className="bill-heading">Coating List Details</h1>
          {test.test != "addNewCoating" && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <Select
              style={{
                width: "100%",
                marginTop: 15,
                flex: 1,
              }}
              showSearch
              value={vendorName}
              onChange={(value) => {
                let temp = JSON.parse(value);
                setVendorId(temp.id);
                setVendorName(temp.shopName);
                setAddress(temp.address);
                setGSTNumber(temp.GSTNumber);
              }}
            >
              <Option key="" value="">
                Select a Vendor
              </Option>
              {Object.entries(allVendors).map(([key, option]) => (
                <Option key={option.value} value={JSON.stringify(option)}>
                  {option.shopName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Material from"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              margin="normal"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              inputProps={{
                maxLength: 100,
              }}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="GST Number"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              margin="normal"
              value={GSTNumber}
              onChange={(e) => setGSTNumber(e.target.value)}
              inputProps={{
                maxLength: 100,
              }}
              autoComplete="off"
            />
          </Col>
          {test.test != "addNewCoating" && (
            <Col xs={24} sm={10} offset={1}>
              <TextField
                select
                margin="normal"
                variant="outlined"
                style={{ width: "100%" }}
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                autoComplete="off"
              >
                <MenuItem key="" value="">
                  Select Status
                </MenuItem>
                <MenuItem key="live" value="live">
                  New
                </MenuItem>
                <MenuItem key="Pending" value="Pending">
                  Pending
                </MenuItem>
                <MenuItem key="Complete" value="Complete">
                  Complete
                </MenuItem>
              </TextField>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Address"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              margin="normal"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              inputProps={{
                maxLength: 100,
              }}
              autoComplete="off"
              multiline
              rows={3}
            />
          </Col>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Notes"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              margin="normal"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              inputProps={{
                maxLength: 100,
              }}
              autoComplete="off"
              multiline
              rows={3}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={10} offset={1}>
            <TextField
              label="Amount"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              margin="normal"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              inputProps={{
                maxLength: 100,
              }}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} width="100%">
          {/* <Col xs={24} md={23} lg={19} offset={1}> */}
          <Table
            dataSource={Object.values(componentList)}
            columns={columns}
            className="my-table"
          />
          {/* </Col> */}
        </Row>

        <Row gutter={[16, 16]}>
          {test.test === "addNewCoating" && (
            <Col xs={12} lg={5} style={{ padding: 10 }} offset={4}>
              <div>
                <Button
                  className="button"
                  type="primary"
                  disabled={spinnerState}
                  onClick={() => setModalAddComponents(true)}
                  style={{ margin: 10 }}
                >
                  <PlusOutlined /> Add Items
                </Button>
              </div>
            </Col>
          )}
          <Col xs={12} lg={5} style={{ padding: 10 }} offset={2}>
            <div>
              <Button
                className="button"
                type="primary"
                disabled={spinnerState}
                onClick={() => checkInput()}
                style={{ margin: 10 }}
              >
                {test.test === "addNewCoating"
                  ? "Send For Coating"
                  : "Edit Status / Notes"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        visible={modalAddComponents}
        width={"70%"}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        style={customStylesAddProducts}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        {/* <h1 className="bill-heading">Select Components</h1> */}
        <div className="bill">
          <div className="bill-container">
            <TextField
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: "95%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />

            <Row gutter={[16, 16]}>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term === ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={8} offset={20}>
                <div className="discount">
                  <Button
                    type="primary"
                    onClick={() => setModalAddComponents(false)}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProductGroups);
