import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import customFont from "../fonts/arial.ttf";
import numberToWords from "number-to-words";
Font.register({ family: "Arial", src: customFont });

// Create Document Component
export default function GenSalesInvoice(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Text>Sales Invoice</Text>
          </View>
          <View style={{ borderWidth: 1 }}>
            <View style={styles.topSection}>
              <View style={styles.topTextLeft}>
                <View style={styles.box}>
                  <Text style={{ fontWeight: "bold" }}>
                    ROYAL FABRICS 312/44, Thambu chetty street, Mannady - 600001
                    Mobile: 9047122224 Ph No : 044 42023734 Email:
                    royalmosquitonets@gmail.com GSTIN: 33AMNPJ9237B1Z4 State:
                    Tamil Nadu, Code: 33
                  </Text>
                </View>
                <View style={[styles.box, { borderBottomWidth: 0 }]}>
                  <Text style={{ fontSize: 10 }}>Buyer</Text>
                  <Text style={{ fontWeight: "bold", fontSize: 10 }}>
                    Outlet: {props.item.location}
                  </Text>
                  <Text style={{ fontWeight: "bold", fontSize: 10 }}>
                    Name: {props.item.name}
                  </Text>
                  <Text>Phone: {props.item.phoneNumber}</Text>
                  <Text style={{ width: "100%" }}>
                    Bill To: {props.item.address}
                  </Text>
                  <Text style={{ width: "100%" }}>
                    Ship To: {props.item.shippingAddress}
                  </Text>
                  <Text style={{ width: "100%" }}>
                    State: {props.item.state}
                  </Text>
                </View>
              </View>
              <View style={styles.topTextRight}>
                <View
                  style={[styles.box, { padding: 0, borderBottomWidth: 1 }]}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{ width: "50%", padding: 2, borderRightWidth: 1 }}
                    >
                      <Text>Invoice No.</Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {props.item.randomId}
                      </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Dated:</Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {" "}
                        {moment(props.item.date, "YYYY-MM-DD").format(
                          "DD-MM-YYYY"
                        )}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{ width: "50%", borderRightWidth: 1, padding: 2 }}
                    >
                      <Text>Delivery Note</Text>
                      <Text> </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Mode/Terms of Payment </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{ width: "50%", borderRightWidth: 1, padding: 2 }}
                    >
                      <Text>Supplier Invoice No. & Date:</Text>
                      <Text style={{ fontWeight: "bold" }}> </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Other References: </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{ width: "50%", borderRightWidth: 1, padding: 2 }}
                    >
                      <Text>Buyer's Order No.</Text>
                      <Text> </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Dated </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{ width: "50%", borderRightWidth: 1, padding: 2 }}
                    >
                      <Text>Dispatch Doc No.</Text>
                      <Text> </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Delivery Note Date </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{ width: "50%", borderRightWidth: 1, padding: 2 }}
                    >
                      <Text>Dispatched through</Text>
                      <Text> </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Destination </Text>
                    </View>
                  </View>
                  <View>
                    <Text>Terms of Delivery</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeading}>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  S No.
                </Text>
                <Text
                  style={{
                    width: "40%",
                    borderRightWidth: 1,
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  Description of Goods
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  HSN/SAC
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    styles.total,
                    {
                      width: "10%",
                      borderRightWidth: 1,
                      textAlign: "center",
                      height: "100%",
                    },
                  ]}
                >
                  Rate
                </Text>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  per
                </Text>
                <Text
                  style={[
                    styles.total,
                    { width: "10%", textAlign: "center", height: "100%" },
                  ]}
                >
                  Amount
                </Text>
              </View>

              {Object.entries(JSON.parse(props.item.componentList)).map(
                ([key, value], index) => {
                  return (
                    <>
                      <View style={styles.tableData}>
                        <Text
                          style={{
                            width: "5%",
                            borderRightWidth: 1,
                            textAlign: "center",
                            padding: 2,
                            height: "100%",
                          }}
                        >
                          {" "}
                          {index + 1}
                        </Text>
                        <Text
                          style={{
                            width: "40%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                            height: "100%",
                          }}
                        >
                          {" "}
                          {value.itemName}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                            height: "100%",
                          }}
                        >
                          {" "}
                          {value.HSN ?? ""}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                            height: "100%",
                          }}
                        >
                          {" "}
                          {value.areaInSqFt} sqft
                        </Text>
                        <Text
                          style={[
                            styles.total,
                            {
                              width: "10%",
                              borderRightWidth: 1,
                              padding: 2,
                              height: "100%",
                            },
                          ]}
                        >
                          {" "}
                          {value.pricePerSQFT}
                        </Text>
                        <Text
                          style={{
                            width: "5%",
                            borderRightWidth: 1,
                            padding: 2,
                            height: "100%",
                          }}
                        >
                          {" "}
                          sqft
                        </Text>
                        <Text
                          style={[
                            styles.total,
                            {
                              width: "10%",
                              fontWeight: "bold",
                              padding: 2,
                              height: "100%",
                            },
                          ]}
                        >
                          {" "}
                          {parseFloat(value.itemTotal).toFixed(2)}
                        </Text>
                      </View>
                    </>
                  );
                }
              )}

              <View style={[styles.tableHeading]}>
                <View
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "40%",
                    borderRightWidth: 1,
                    textAlign: "right",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text>Discount</Text>
                  <Text>Packing and Forwarding</Text>
                  <Text>OUTPUT TAX - IGST</Text>
                  {/* <Text>TCS COLLECTED @0.1%</Text> */}
                  {/* <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>Less:</Text>
                    <Text>Round Off</Text>
                  </View> */}
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    fontWeight: "bold",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    fontWeight: "bold",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    textAlign: "right",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                    height: "100%",
                  }}
                >
                  <Text>- {props.item.discountAmount}</Text>
                  <Text>{props.item.extraCharges}</Text>
                  <Text>{props.item.GSTAmount}</Text>
                  {/* <Text>304.00</Text> */}
                  {/* <Text>(-)0.28</Text> */}
                </View>
              </View>

              <View style={[styles.tableHeading]}>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    padding: 2,
                    height: "100%",
                  }}
                >
                  {" "}
                </Text>
                <Text
                  style={{
                    width: "40%",
                    borderRightWidth: 1,
                    textAlign: "right",
                    padding: 2,
                    height: "100%",
                  }}
                >
                  {" "}
                  Total
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    fontWeight: "bold",
                    padding: 2,
                    height: "100%",
                  }}
                >
                  {" "}
                  {Object.values(JSON.parse(props.item.componentList)).reduce(
                    (sum, value) => sum + parseFloat(value.areaInSqFt),
                    0
                  )}{" "}
                  sqft
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    padding: 2,
                    height: "100%",
                  }}
                >
                  {" "}
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    padding: 2,
                    height: "100%",
                  }}
                >
                  {" "}
                </Text>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    padding: 2,
                    height: "100%",
                  }}
                >
                  {" "}
                </Text>
                <Text
                  style={[
                    styles.total,
                    {
                      width: "10%",
                      fontWeight: "bold",
                      padding: 2,
                      height: "100%",
                    },
                  ]}
                >
                  {parseFloat(props.item.totalAmount).toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={{}}>
              <View>
                <Text style={{ fontSize: 8, padding: 2 }}>
                  Amount Chargeable (in words)
                </Text>
                <Text style={[styles.inWords, { fontWeight: "bold" }]}>
                  INR{" "}
                  {numberToWords
                    .toWords(props.item.totalAmount)
                    .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                  Only
                </Text>
                {/* <Text>{"          "}</Text>
                <Text>{"          "}</Text>
                <Text style={{ fontSize: 10, padding: 2 }}>
                  Company's GSTIN/UIN : <Text style={{ fontWeight: "bold" }}></Text>
                </Text> */}
              </View>

              {/* <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderTopWidth: 1,
                  }}
                >
                  <Text
                    style={{
                      width: "60%",
                      borderRightWidth: 1,
                      height: "100%",
                      fontSize: 10,
                    }}
                  >
                    HSN/SAC
                  </Text>
                  <Text
                    style={{
                      width: "10%",
                      borderRightWidth: 1,
                      height: "100%",
                      fontSize: 10,
                    }}
                  >
                    Taxable Value
                  </Text>
                  <View
                    style={{
                      width: "20%",
                      height: "100%",
                      borderRightWidth: 1,
                    }}
                  >
                    <Text style={{ borderBottomWidth: 1, fontSize: 10 }}>
                      Integrated Tax
                    </Text>
                    <View
                      style={{ flexDirection: "row", alignItems: "flex-start" }}
                    >
                      <Text
                        style={{
                          width: "50%",
                          borderRightWidth: 1,
                          fontSize: 10,
                        }}
                      >
                        Rate
                      </Text>
                      <Text style={{ width: "50%", fontSize: 10 }}>Amount</Text>
                    </View>
                  </View>
                  <Text style={{ width: "10%", height: "100%", fontSize: 10 }}>
                    Total Tax Amount
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <Text
                    style={{
                      width: "60%",
                      borderRightWidth: 1,
                      height: "100%",
                      fontSize: 10,
                      textAlign: "right",
                    }}
                  >
                    Total
                  </Text>
                  <Text
                    style={{
                      width: "10%",
                      borderRightWidth: 1,
                      height: "100%",
                      fontSize: 10,
                    }}
                  >
                    {" "}
                  </Text>
                  <Text
                    style={{
                      width: "10%",
                      borderRightWidth: 1,
                      height: "100%",
                      fontSize: 10,
                    }}
                  >
                    {" "}
                  </Text>
                  <Text
                    style={{
                      width: "10%",
                      height: "100%",
                      borderRightWidth: 1,
                      fontSize: 10,
                    }}
                  >
                    {" "}
                  </Text>
                  <Text style={{ width: "10%", height: "100%", fontSize: 10 }}>
                    {" "}
                  </Text>
                </View>
              </View> */}

              {/* <View>
                <Text style={{ fontSize: 8, padding: 2 }}>
                  Amount Chargeable (in words)
                  <Text
                    style={[
                      styles.inWords,
                      { fontWeight: "bold", fontSize: 10 },
                    ]}
                  >
                    INR Only
                  </Text>
                </Text>
              </View> */}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 30,
                }}
              >
                <View style={{ width: "50%", padding: 5 }}>
                  <Text style={{ fontSize: 10 }}>Declaration</Text>
                  <Text style={{ fontSize: 10 }}>
                    We declare that this invoice shows the actual price of the
                    goods described and that all particulars are true and
                    correct
                  </Text>
                </View>
                <View
                  style={[
                    styles.signature,
                    { width: "50%", borderLeftWidth: 1, borderTopWidth: 1 },
                  ]}
                >
                  <Text style={{ fontWeight: "bold" }}>for, Royal Fabrics</Text>
                  <Text>{"          "}</Text>
                  <Text>{"          "}</Text>
                  <Text>Authorised Signatory</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 40,
    marginRight: 40,
    borderBottom: "1px solid black",
    height: 70,
  },
  topSection: {
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 10,
    borderBottomWidth: 1,
  },
  topTextLeft: {
    textAlign: "left",
    width: "50%",
    borderRight: "1px solid black",
  },
  topTextRight: {
    textAlign: "left",
    width: "50%",
  },
  box: { borderBottom: "1px solid black", padding: 5 },
  table: {},
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "flex-start",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataBlue: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#87CEEB",
  },
  name: {
    padding: 2,
    width: 250,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 55,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 80,
    textAlign: "right",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    fontSize: 10,
  },
  leftPadding: {
    paddingLeft: 20,
  },
  signature: {
    textAlign: "right",
    fontSize: 12,
    paddingRight: 10,
  },
  total: {
    textAlign: "right",
    paddingRight: 5,
  },
  inWords: {
    paddingLeft: 5,
    fontSize: 10,
    marginTop: 10,
  },
});
