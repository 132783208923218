import { useNavigate, Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button, Switch } from "antd";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getAllItemsPagination,
  getItemsByShopTypeFunc,
  getAllItemsPagination100,
  getItemsByShopTypeFunc100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ListQuotation(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [status, setStatus] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [showCount, setShowCount] = useState({});
  const test = useParams();
  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "12%",
      render: (randomId, record) => (
        <a onClick={() => navigate(`../ReadQuotation/${record.id}/quotation`)}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "10%",
      render: (name, record) => (
        <a
          style={{
            marginTop: -5,
            marginBottom: -5,
            color: "black",
            fontWeight: record.status === "live" ? "bold" : "normal",
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "7%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
      width: "7%",
      render: (time) => moment(time, "HH:mm:ss").format("hh:mm A"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: "10%",
      render: (status) => (status == "live" ? "New" : status),
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Outlet",
            dataIndex: "orderLocation",
            key: "orderLocation",
            ellipsis: true,
            width: "10%",
          },
        ]
      : []),
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      width: "10%",
      render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
    },
    ...(test.test == "homepage"
      ? [
          {
            title: "View",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            width: "7%",
            render: (id) => (
              <a onClick={() => navigate(`../SqftCompQuot/${id}/quotation`)}>
                View
              </a>
            ),
          },
        ]
      : []),
    {
      title: "Tiny URL",
      dataIndex: "tinyURL",
      key: "tinyURL",
      width: "7%",
      ellipsis: true,
      render: (tinyURL, record) =>
        (record.status == "Convert to Order" && (
          <Link target="_blank" to={`${record.tinyURL}`}>
            URL
          </Link>
        )) ||
        "NA",
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          setAddedBy(user.attributes.email);
          await getAllItemsByType(
            user.attributes["custom:shopId"],
            test.test == "homepage"
              ? user.attributes["custom:outletShopId"]
              : test.test,
            user.attributes.email
          );
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Quotation")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId, outletShopId, addedBy) => {
    let allItems = [];
    if (props.common.shopType == "master") {
      if (showAll) allItems = await getItemsByShopTypeFunc("Quotation", shopId);
      else allItems = await getItemsByShopTypeFunc100("Quotation", shopId);
    } else {
      if (showAll)
        allItems = await getAllItemsPagination("Quotation", outletShopId);
      else allItems = await getAllItemsPagination100("Quotation", outletShopId);
    }

    if (props.common.shopType == "master" && props.common.role == "Telecallers")
      allItems = allItems.filter((item) => item.addedBy == addedBy);

    let temp = { New: 0, FollowUp: 0, Order: 0 };
    temp.New = allItems.filter((item) => item.status == "live").length;
    temp.FollowUp = allItems.filter(
      (item) => item.status == "Follow Up"
    ).length;
    temp.Order = allItems.filter(
      (item) => item.status == "Convert to Order"
    ).length;
    setShowCount(temp);

    setAllItems(allItems);
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      setShowCount({});
      await getAllItemsByType(
        shopId,
        test.test == "homepage" ? outletShopId : test.test,
        addedBy
      );
    };
    if (shopId != "" && outletShopId != "" && addedBy != "") getData();
  }, [showAll]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            name="Search quotation"
            label="Search quotation"
            variant="outlined"
            size="small"
            style={{ width: "40%", backgroundColor: "white", marginRight: 10 }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <TextField
            select
            margin="normal"
            label="Select Status"
            variant="outlined"
            style={{
              width: "40%",
              backgroundColor: "white",
              marginTop: 8,
              marginRight: 10,
            }}
            value={status}
            size="small"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <MenuItem key="live" value="live">
              New
            </MenuItem>
            <MenuItem key="Follow Up" value="Follow Up">
              Follow Up
            </MenuItem>
            <MenuItem key="Convert to Order" value="Convert to Order">
              Convert to Order
            </MenuItem>
            <MenuItem key="Rejected" value="Rejected">
              Rejected
            </MenuItem>
          </TextField>
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all Quotes"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />

          {test.test == "homepage" && props.common.shopType != "master" && (
            <Button type="primary" style={{ marginRight: 10 }}>
              <Link to="../SqftCompQuot/addNewQuotation/quotation">
                <PlusOutlined /> New Quotation
              </Link>
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          {Object.entries(showCount).map(([key, value]) => {
            return (
              <p style={{ fontWeight: "bold" }}>
                {key} : {value}
              </p>
            );
          })}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == "" && status == ""
                      ? allItems
                      : term != "" && status == ""
                      ? allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                      : term == "" && status != ""
                      ? allItems.filter((item) => item.status == status)
                      : allItems
                          .filter((item) => item.status == status)
                          .filter(
                            (item) =>
                              item.randomId
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              item.name
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              item.phoneNumber
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1
                          )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListQuotation);
