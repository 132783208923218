import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, DatePicker, Button } from "antd";
import { getTypeAndDateShopIdPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [shopId, setShopId] = useState("");
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;

  const headers = [
    { label: "Month", key: "month" },
    { label: "Year", key: "year" },
    { label: "Total", key: "total" },
    { label: "New", key: "new" },
    { label: "Cutting Complete", key: "cutting" },
    { label: "Mesh Process", key: "mesh" },
    { label: "Packed", key: "packed" },
    { label: "Dispatch", key: "dispatch" },
    { label: "Delivered", key: "delivered" },
    { label: "Install", key: "install" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          getAllItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId) => {
    let temp = {};

    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].total = 0;
      temp[key].new = 0;
      temp[key].cutting = 0;
      temp[key].mesh = 0;
      temp[key].packed = 0;
      temp[key].dispatch = 0;
      temp[key].delivered = 0;
      temp[key].install = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getTypeAndDateShopIdPagination(
        "Production",
        shopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + 1;
          if (item.status == "live") value.new = value.new + 1;
          else if (item.status == "Cutting Complete")
            value.cutting = value.cutting + 1;
          else if (item.status == "Mesh Process") value.mesh = value.mesh + 1;
          else if (item.status == "Packed") value.packed = value.packed + 1;
          else if (item.status == "Dispatched")
            value.dispatch = value.dispatch + 1;
          else if (item.status == "Delivered")
            value.delivered = value.delivered + 1;
          else if (item.status == "Installed")
            value.install = value.install + 1;
        });
    }
    setSpinnerState(false);
    let sortedArray = Object.values(temp).sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      return b.mnth - a.mnth;
    });
    setReportList(sortedArray);
  };

  useEffect(() => {
    if (shopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(shopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
          {reportList.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={reportList}
                headers={headers}
                filename={
                  "Products-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <h2>Production count by status for all outlets</h2>
              <div style={{ display: "flex" }}>
                <div style={{ width: 200 }}>
                  <p style={{ fontWeight: "bold" }}>Status</p>
                  <p style={{ fontWeight: "bold" }}>Total</p>
                  <p>New</p>
                  <p>Cutting Complete</p>
                  <p>Mesh Process</p>
                  <p>Packed</p>
                  <p>Dispatched</p>
                  <p>Delivered</p>
                  <p>Installed</p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div style={{ width: 200 }}>
                        <p style={{ fontWeight: "bold" }}>
                          {item.month}-{item.year}
                        </p>
                        <p style={{ fontWeight: "bold" }}>{item.total}</p>
                        <p>{item.new}</p>
                        <p>{item.cutting}</p>
                        <p>{item.mesh}</p>
                        <p>{item.packed}</p>
                        <p>{item.dispatch}</p>
                        <p>{item.delivered}</p>
                        <p>{item.install}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
