import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import numberToWords from "number-to-words";

// Create Document Component
export default function GeneratePayRoll(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5, fontSize: 8 }}>
            <Text>ROYAL FABRICS</Text>
            <Text>312/44 , Thambu chetty street, Mannady, Chennai -600001</Text>
            <Text>
              Phone : 044 42043734 , Email : royalmosquitonets@gmail.com
            </Text>
            <Text>Website : www.royalmosquitonets.com</Text>
          </View>
          <View style={{ alignItems: "center", margin: 5, fontSize: 12 }}>
            <Text>Pay Slip</Text>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Employee Name: {props.name}</Text>
              <Text>Father Name: {props.emgDetails.name}</Text>
              <Text>Department: {props.role}</Text>
              <Text>Gender: {props.gender}</Text>
              <Text>PAN: {props.documents.PAN}</Text>
              <Text>Aadhar: {props.documents.aadhar}</Text>
            </View>
            <View style={styles.topTextRight}>
              <Text>Employee Code: {props.randomId}</Text>
              <Text>DOB: {props.DOB}</Text>
              <Text>DOJ: {props.DOJ}</Text>
              <Text>Bank Name: {props.bankDetailsMap.name}</Text>
              <Text>Bank A/C no.: {props.bankDetailsMap.acNo}</Text>
              <Text>IFSC Code: {props.bankDetailsMap.IFSC}</Text>
            </View>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Month: {props.month}</Text>
              <Text>Month of Days: {props.attndDetails.daysInMonth}</Text>
              <Text>Holidays: {props.attndDetails.holidays}</Text>
            </View>
            <View style={styles.topTextRight}>
              <Text>Working Days: {props.attndDetails.workingDays}</Text>
              <Text>Leaves: {props.attndDetails.leaves}</Text>
              <Text>Net paid days: {props.attndDetails.netPaidDays}</Text>
            </View>
          </View>
          <View style={{ borderTopWidth: 1, height: 10 }}></View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Earnings</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>Structure</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>Per Month</Text>
            </View>
            <View style={styles.topTextLeft}>
              <Text>Deduction</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>Per Month</Text>
            </View>
            <View
              style={[
                styles.topTextAlignRight,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text>Per Month</Text>
            </View>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Basic Salary</Text>
              <Text>HRA</Text>
              <Text>Transport Allowance</Text>
              <Text>Medical Allowance</Text>
              <Text>Incentive</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>{props.salaryDetails.basicSalary}</Text>
              <Text>{props.salaryDetails.HRA}</Text>
              <Text>{props.salaryDetails.transport}</Text>
              <Text>{props.salaryDetails.medical}</Text>
              <Text>{props.salaryDetails.incentive}</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>{props.salaryCredited.basicSalary.toFixed(2)}</Text>
              <Text>{props.salaryCredited.HRA.toFixed(2)}</Text>
              <Text>{props.salaryCredited.transport.toFixed(2)}</Text>
              <Text>{props.salaryCredited.medical.toFixed(2)}</Text>
              <Text>{props.salaryCredited.incentive}</Text>
            </View>
            <View style={styles.topTextLeft}>
              <Text>PF</Text>
              <Text>ESI</Text>
              <Text>Loans and advances</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>{props.salaryDetails.PF}%</Text>
              <Text>{props.salaryDetails.ESI}%</Text>
              <Text>{props.salaryDetails.loanAdvance}</Text>
            </View>
            <View
              style={[
                styles.topTextAlignRight,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text>{props.salaryCredited.PF.toFixed(2)}</Text>
              <Text>{props.salaryCredited.ESI.toFixed(2)}</Text>
              <Text>{props.salaryCredited.loanAdvance}</Text>
            </View>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Total Earnings</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text> </Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text>{props.earning}</Text>
            </View>
            <View style={styles.topTextLeft}>
              <Text>Total Deductions</Text>
            </View>
            <View style={styles.topTextAlignRight}>
              <Text> </Text>
            </View>
            <View
              style={[
                styles.topTextAlignRight,
                { borderRight: "1px solid black" },
              ]}
            >
              <Text>{props.deductions}</Text>
            </View>
          </View>
          <View style={{ borderBottomWidth: 1 }}></View>
          <Text style={{ height: 10 }}> </Text>

          <View style={{ borderWidth: 1, fontSize: 10, padding: 2 }}>
            <Text>NET PAYABLE {props.salary}</Text>
            <Text>
              Net payable in words INR{" "}
              {numberToWords
                .toWords(props.salary)
                .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
              Only
            </Text>
          </View>
          <View>
            <Text> </Text>
          </View>
          <View style={{ borderWidth: 1, fontSize: 10, padding: 2 }}>
            <Text>EMPLOYER'S CONTRIBUTION ON CTC</Text>
            <Text>
              PF Employer contribution {props.salaryDetails.empPF} -{" "}
              {props.salaryCredited.empPF}
            </Text>
            <Text>
              ESI Employer contribution {props.salaryDetails.empESI} -{" "}
              {props.salaryCredited.empESI}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  topSection: {
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 10,
    // borderBottomWidth: 1,
    borderTopWidth: 1,
  },
  topTextLeft: {
    textAlign: "left",
    width: "50%",
    borderLeft: "1px solid black",
    paddingLeft: 5,
  },
  topTextRight: {
    width: "50%",
    borderRight: "1px solid black",
    paddingLeft: 5,
  },
  topTextAlignRight: {
    textAlign: "right",
    width: "50%",
    borderLeft: "1px solid black",
    paddingRight: 5,
  },
});
