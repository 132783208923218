import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBill.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Button, Card } from "antd";
import { checkAccess } from "./AuthorizationService";
import { getAttndByYearFunc } from "./functionCall";
import moment from "moment";
import { CSVLink } from "react-csv";

function AddComponents(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [attendance, setAttendance] = useState({});

  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getAttndByYear();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Attendance")) navigate("/homePage");
    getUser();
  }, []);

  const getAttndByYear = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let allItems = [];
    allItems = await getAttndByYearFunc("Attendance", test.test, year);
    if (allItems.length > 0) {
      setAttendance(JSON.parse(allItems[0].attendance));
      setName(allItems[0].name);
      setYear(allItems[0].year);
    }
  };

  const headers = [
    { label: "Date", key: "date" },
    { label: "Time In", key: "startTime" },
    { label: "Time Out", key: "endTime" },
    { label: "Hours", key: "hours" },
  ];

  function getDaysOfMonthArray(year, month) {
    const firstDayOfMonth = moment(`${year}-${month}-01`, "YYYY-MM-DD");
    const daysInMonth = firstDayOfMonth.daysInMonth();
    return Array.from({ length: daysInMonth }, (_, index) =>
      firstDayOfMonth.clone().add(index, "days").format("YYYY-MM-DD")
    );
  }

  const DaysOfMonth = ({ year, month, attendance }) => {
    const daysArray = getDaysOfMonthArray(year, month);
    const sundayArray = daysArray.filter((day) => moment(day).day() === 0);
    const holidays = props.common.holidayList.filter((value) =>
      daysArray.includes(value)
    );
    console.log(month + JSON.stringify(holidays));
    const holidayArray = sundayArray.concat(holidays);

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={3}>
            <p style={{ fontSize: 18, fontWeight: "bold" }}>
              {moment(month, "MM").format("MMMM")}
            </p>
          </Col>
          <Col span={21}>
            {daysArray.map((day) => (
              <span
                style={{
                  margin: 5,
                  fontWeight: "bold",
                  backgroundColor: Object.keys(attendance).includes(day)
                    ? "#5cb85c"
                    : holidayArray.includes(day)
                    ? "#d1d6dc"
                    : "#fab972",
                  // : "#d78c3d",
                  padding: 10,
                  display: "inline-block",
                  width: 60,
                  height: 60,
                  textAlign: "center",
                }}
              >
                <div>{moment(day, "YYYY-MM-DD").format("DD")}</div>
                <div>{moment(day, "YYYY-MM-DD").format("ddd")}</div>
              </span>
            ))}
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <p style={{ fontWeight: "bold" }}>
              Total : {daysArray.length} Day(s)
            </p>
          </Col>
          <Col span={3}>
            <p style={{ fontWeight: "bold" }}>
              Present : {Object.keys(attendance).length} Day(s)
            </p>
          </Col>
          <Col span={3}>
            <p style={{ fontWeight: "bold" }}>
              Leave :{" "}
              {daysArray.length -
                Object.keys(attendance).length -
                holidayArray.length +
                holidayArray.filter((value) =>
                  Object.keys(attendance).includes(value)
                ).length}{" "}
              Day(s)
            </p>
          </Col>
          <Col span={3}>
            <p style={{ fontWeight: "bold" }}>
              Holiday :{" "}
              {holidayArray.length -
                holidayArray.filter((value) =>
                  Object.keys(attendance).includes(value)
                ).length}{" "}
              Day(s)
            </p>
          </Col>
          <Col span={5}>
            <p style={{ fontWeight: "bold" }}>
              Worked on Holiday :{" "}
              {
                holidayArray.filter((value) =>
                  Object.keys(attendance).includes(value)
                ).length
              }{" "}
              Day(s)
            </p>
          </Col>
          <Col span={3}>
            <p style={{ fontWeight: "bold" }}>
              Working Hours :{" "}
              {Object.values(attendance).reduce(
                (sum, value) => sum + parseFloat(value.hours),
                0
              )}
            </p>
          </Col>
          <Col span={3}>
            <Button type="primary" style={{ marginTop: 10 }}>
              <CSVLink
                data={Object.values(attendance)}
                headers={headers}
                filename={
                  name +
                  "-" +
                  moment(month, "MM").format("MMMM") +
                  "-" +
                  year +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <h3 className="bill-heading">
          {Object.keys(attendance).length > 0
            ? `Attendance Details Year : ${year}`
            : "No records found"}
        </h3>
        <h2>Employee Name : {name}</h2>
        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          {Object.entries(attendance).map(([key, value]) => {
            return (
              <div>
                <DaysOfMonth year={year} month={key} attendance={value} />
                <div className="divider2"></div>
              </div>
            );
          })}
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddComponents);
