import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Divider,
  Card,
  Select,
} from "antd";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { TextField, MenuItem, InputAdornment } from "@mui/material";
import { getItemsByOutletPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc, getAllItemsPagination10 } from "./functionCall";
import GeneratePO from "./generatePO";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
const { Option } = Select;

function AddPO(props) {
  const navigate = useNavigate();
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddPR, setModalAddPR] = useState(false);
  const [term, setTerm] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [allVendors, setAllVendors] = useState({});
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  let test = useParams();

  const columns = [
    {
      title: "Item Code",
      dataIndex: "itemCode",
      key: "itemCode",
      ellipsis: true,
      render: (itemCode, record) => (
        <Input
          value={itemCode}
          onChange={(e) => {
            addValue(record.key, "itemCode", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Item Size",
      dataIndex: "itemSize",
      key: "itemSize",
      ellipsis: true,
      render: (itemSize, record) => (
        <Input
          value={itemSize}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "itemSize", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "itemSize", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record) => (
        <Input
          value={quantity}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "quantity", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "quantity", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true,
      render: (unit, record) => (
        <Input
          value={unit}
          onChange={(e) => {
            addValue(record.key, "unit", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "key",
      key: "key",
      ellipsis: true,
      render: (key) => (
        <Button
          type="primary"
          onClick={() => removeComponent(key)}
          danger={Object.keys(componentList).includes(key) ? true : ""}
          style={{ width: "90%" }}
        >
          {Object.keys(componentList).includes(key) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "key",
      key: "key",
      ellipsis: true,
      render: (key, record) => (
        <Button
          type="primary"
          onClick={() => addPR(record)}
          danger={Object.keys(componentList).includes(key) ? true : ""}
          style={{ width: "60%" }}
        >
          {Object.keys(componentList).includes(key) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewPO") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("PurchaseOrder")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setVendorId(res.vendorId);
        setVendorName(res.vendorName);
        setComponentList(JSON.parse(res.componentList));
        setItemDetails(res);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let allList = [];
    let allItems = await getItemsByTypeFunc("PurchaseRequest", shopId);
    allItems.map((item) => {
      let list = JSON.parse(item.componentList);
      let temp = Object.values(list)[0];
      temp.PRId = item.id;
      allList.push(temp);
    });
    setAllItems(
      allList.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      "Vendor",
      "live",
      outletShopId
    );

    let temp = {};
    allItems.map((item) => {
      if (temp[item.id] == undefined) {
        temp[item.id] = {};
        temp[item.id].id = item.id;
        temp[item.id].shopName = item.shopName;
        temp[item.id].address = item.address;
        temp[item.id].GSTNumber = item.GSTNumber;
      }
    });
    setAllVendors(temp);
  };

  const addItem = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10("PurchaseOrder", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "PO-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "PO-" + year + "-" + tempCounter;

    try {
      let PO = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          vendorId: vendorId,
          vendorName: vendorName,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "PurchaseOrder",
          stockReceived: false,
        },
      });
      try {
        let updatePO = await API.graphql({
          query: mutations.updatePOInventory,
          variables: {
            id: PO.data.addItem.id,
            updatedBy: updatedBy,
            action: "stockOrder",
          },
        });
        message.error("Purchase Order Added");
        navigate(-1);
      } catch (error) {
        console.log(JSON.stringify(error));
        message.error(JSON.stringify(error));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          vendorId: vendorId,
          vendorName: vendorName,
          updatedBy: updatedBy,
          componentList: JSON.stringify(componentList),
        },
      });
      message.error("Purchase Order updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (allVendors.length == 0) return message.error("Please add vendors");
    if (vendorName == "") return message.error("Select Vendor");
    if (Object.keys(componentList).length == 0)
      return message.error("Please add items to the purchase order");

    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.quantity == 0) {
        flag = true;
        return message.error("Enter quantity for " + value.itemName);
      }
      if (value.color == "") {
        flag = true;
        return message.error("Select color for " + value.itemName);
      }
    });

    if (flag) return;

    setSpinnerState("true");

    if (test.test === "addNewPO") addItem();
    else editItem();
  };

  const addPR = (item) => {
    let key = item.key;
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    } else {
      tempList[key] = {};
      tempList[key].key = key;
      tempList[key].id = item.id;
      tempList[key].itemName = item.itemName;
      tempList[key].quantity = item.quantity;
      tempList[key].color = item.color;
      tempList[key].PRId = item.PRId;
      tempList[key].itemId = item.id;
      tempList[key].itemCode = "";
      tempList[key].itemSize = "";
      tempList[key].unit = "";
    }
    setComponentList(tempList);
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    temp[key][value] = data;
    setComponentList(temp);
  };

  const downloadPDF = async () => {
    await pdf(
      <GeneratePO item={itemDetails} vendorDetails={allVendors[vendorId]} />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `PO ${itemDetails.randomId}.pdf`);
      });
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1100px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 40,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginLeft: -20,
            display: "flex",
            justifyContent: "space-between",
            margin: 10,
          }}
        >
          <h1 className="bill-heading">Purchase Order Details</h1>
          {test.test != "addNewPO" && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )}
        </div>
        <Row gutter={[16, 16]}>
          <Card style={{ width: "100%" }}>
            <Row gutter={[16, 16]}>
              {test.test === "addNewPO" ? (
                <Col xs={24} sm={10} offset={1}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0",
                    }}
                  >
                    <label
                      style={{
                        width: "120px",
                        marginRight: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Select Vendor
                    </label>
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        flex: 1,
                      }}
                      value={vendorName}
                      onChange={(value) => {
                        let temp = JSON.parse(value);
                        setVendorId(temp.id);
                        setVendorName(temp.shopName);
                      }}
                    >
                      {Object.entries(allVendors).map(([key, option]) => (
                        <Option
                          key={option.value}
                          value={JSON.stringify(option)}
                        >
                          {option.shopName}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              ) : (
                <Col xs={24} sm={10}>
                  <span>{vendorName}</span>
                </Col>
              )}
            </Row>
          </Card>
        </Row>
        <Row gutter={[16, 16]}>
          {Object.entries(componentList).length > 0 && (
            <Col xs={24} sm={10} style={{ paddingTop: 20 }}>
              <span>{Object.entries(componentList).length} item(s) added</span>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24}>
            <Table
              dataSource={Object.values(componentList)}
              columns={columns}
              className="my-table"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {test.test === "addNewPO" && (
            <Col xs={24} md={12} lg={8}>
              <Button
                className="button"
                type="primary"
                size="large"
                disabled={spinnerState}
                onClick={() => setModalAddPR(true)}
                style={{ marginTop: 20 }}
              >
                <PlusOutlined /> Add Items
              </Button>
            </Col>
          )}
          <Col xs={24} md={12} lg={8}>
            <Button
              className="button"
              type="primary"
              size="large"
              disabled={spinnerState}
              onClick={() => checkInput()}
              style={{ marginTop: 20 }}
            >
              {test.test === "addNewPO"
                ? "Add Purchase Order"
                : "Update Purchase Order"}
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        visible={modalAddPR}
        width={"70%"}
        onCancel={() => setModalAddPR(false)}
        footer={null}
        style={customStylesAddProducts}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        <div className="bill">
          <div className="bill-container">
            <TextField
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: "95%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />

            <Row>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={8} lg={6} offset={21}>
                <div className="discount">
                  <Button type="primary" onClick={() => setModalAddPR(false)}>
                    Add Item
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPO);
