import React, { useEffect } from "react";
import "../styles/homePage.css";
import Dashboard from "./dashboard";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { useDispatch } from "react-redux";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import axios from "axios";
import { connect } from "react-redux";
import { theme } from "antd";
import WelcomePage from "./welcomePage";
import DashboardOutlet from "./dashboardOutlet";
import { checkAccess } from "./AuthorizationService";
import { messaging, getToken, onMessage } from "../firebaseConfig";

function HomePage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          if (user.attributes["custom:shopId"] !== "NA")
            try {
              const allTodos = await API.graphql({
                query: queries.getItemById,
                variables: { id: user.attributes["custom:shopId"] },
              });
              dispatch({
                type: "SET_SHOPDETAILS",
                payload: allTodos.data.getItemById,
              });
            } catch (error) {
              console.log(JSON.stringify(error));
            }
          try {
            const allTodos = await API.graphql({
              query: queries.getItemById,
              variables: { id: user.attributes["custom:outletShopId"] },
            });
            dispatch({
              type: "SET_OUTLETDETAILS",
              payload: allTodos.data.getItemById,
            });
          } catch (error) {
            console.log(JSON.stringify(error));
          }
          // console.log("user " + JSON.stringify(user.username));
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
      try {
        const res = await axios.get(
          "https://royalfabcrm.s3.ap-south-1.amazonaws.com/royalFabCRM.json",
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        dispatch({ type: "SET_JSON", payload: res.data });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
      try {
        const allTodos = await API.graphql({
          query: queries.getUserDetails,
        });
        dispatch({
          type: "SET_USERDATA",
          payload: allTodos.data.getUserDetails,
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
    }
    const registerServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        const token = await getToken(messaging, {
          vapidKey:
            "BIiHrcHZpGXZwaod8LLNDGuU5fmnr7JvoDEtWfa8RHk-VJIENm1r46uosqQ46Y9qEy24WHGstmnBdrvlem68Imw",
          serviceWorkerRegistration: registration,
        });
        console.log("Token:", token);

        let id = "";
        try {
          const allTodos = await API.graphql({
            query: queries.getUserDetails,
          });
          id = allTodos.data.getUserDetails.id;

          try {
            await API.graphql({
              query: mutations.editItem,
              variables: {
                id: id,
                tokenWeb: token,
              },
            });
          } catch (error) {
            console.log(JSON.stringify(error));
          }
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      } catch (error) {
        console.error(
          "Error registering service worker or getting token",
          error
        );
      }
    };

    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        registerServiceWorker();
      } catch (error) {
        console.error("Permission denied", error);
      }
    };

    requestPermission();

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
    });
    fetchData();
  }, []);

  return props.common.shopType === "master" && checkAccess("Dashboard") ? (
    <Dashboard />
  ) : props.common.shopType === "outlet" && checkAccess("Dashboard") ? (
    <DashboardOutlet />
  ) : (
    <WelcomePage />
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
