import { SET_PI_ITEMS, CHANGE_PI_COM, SET_PI_EXTRA_CRG } from "../action";

const initialState = {
  subTotal: 0,
  total: 0,
  extraCharges: 0,
  GST: 0,
  GSTAmount: 0,
  IGST: 0,
  IGSTAmount: 0,
  TDS: 0,
  TDSAmount: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PI_ITEMS:
      return {
        ...state,
        subTotal: action.payload.subTotal,
        total: action.payload.totalAmount,
        extraCharges: action.payload.extraCharges,
        GST: action.payload.GST,
        GSTAmount: action.payload.GSTAmount,
        IGST: action.payload.IGST,
        IGSTAmount: action.payload.IGSTAmount,
        TDS: action.payload.TDS,
        TDSAmount: action.payload.TDSAmount,
      };

    case CHANGE_PI_COM:
      var subTotal = 0;
      var amount = 0;
      var tempObj = action.payload.componentList;
      var GSTAmount = 0;
      var IGSTAmount = 0;
      var TDSAmount = 0;
      var extraCharges = 0;

      Object.entries(tempObj).map(([key, value]) => {
        subTotal = subTotal + parseFloat(value.itemTotal);
      });

      if (state.extraCharges != "")
        extraCharges = parseFloat(state.extraCharges);

      if (state.GST != "")
        GSTAmount =
          (parseFloat(state.GST) / 100) *
          (parseFloat(subTotal) + parseFloat(extraCharges));

      if (state.IGST != "")
        IGSTAmount =
          (parseFloat(state.IGST) / 100) *
          (parseFloat(subTotal) + parseFloat(extraCharges));

      if (state.TDS != "")
        TDSAmount = (parseFloat(state.TDS) / 100) * parseFloat(subTotal);

      amount =
        parseFloat(subTotal) +
        parseFloat(extraCharges) +
        parseFloat(GSTAmount) +
        parseFloat(IGSTAmount) +
        parseFloat(TDSAmount);

      return {
        ...state,
        total: amount,
        subTotal: subTotal,
        GSTAmount: GSTAmount,
        IGSTAmount: IGSTAmount,
        TDSAmount: TDSAmount,
      };

    case SET_PI_EXTRA_CRG:
      var amount = state.subTotal;
      var subTotal = state.subTotal;
      var GSTAmount = 0;
      var IGSTAmount = 0;
      var TDSAmount = 0;
      var extraCharges = 0;

      if (action.payload.extraCharges != "")
        extraCharges = parseFloat(action.payload.extraCharges);

      if (action.payload.GST != "")
        GSTAmount =
          (parseFloat(action.payload.GST) / 100) *
          (parseFloat(subTotal) + parseFloat(extraCharges));

      if (action.payload.IGST != "")
        IGSTAmount =
          (parseFloat(action.payload.IGST) / 100) *
          (parseFloat(subTotal) + parseFloat(extraCharges));

      if (action.payload.TDS != "")
        TDSAmount =
          (parseFloat(action.payload.TDS) / 100) * parseFloat(subTotal);

      amount =
        parseFloat(subTotal) +
        parseFloat(extraCharges) +
        parseFloat(GSTAmount) +
        parseFloat(IGSTAmount) +
        parseFloat(TDSAmount);

      return {
        ...state,
        total: amount,
        subTotal: subTotal,
        extraCharges: action.payload.extraCharges,
        GST: action.payload.GST,
        GSTAmount: GSTAmount,
        IGST: action.payload.IGST,
        IGSTAmount: IGSTAmount,
        TDS: action.payload.TDS,
        TDSAmount: TDSAmount,
      };

    default:
      return state;
  }
}
