import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "../styles/signIn.css";
import { Auth, API } from "aws-amplify";
import { createUserDetails } from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { Row, Col, Button, message, Modal } from "antd";
import { TextField } from "@mui/material";
import Logo from "../images/Logo.jpg";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);

  const signInUser = async () => {
    let randomId = Math.floor(Math.random() * 10000000000) + 1;
    let location = "";
    try {
      const user = await Auth.signIn(email, password);
      {
        try {
          const allTodos = await API.graphql({
            query: queries.getItemById,
            variables: { id: user.attributes["custom:outletShopId"] },
          });
          location = allTodos.data.getItemById.location;
        } catch (error) {
          console.log(JSON.stringify(error));
        }

        try {
          await API.graphql({
            query: createUserDetails,
            variables: {
              email: email,
              shopName: user.attributes["custom:shopName"],
              shopType: user.attributes["custom:shopType"],
              role: user.attributes["custom:role"],
              shopId: user.attributes["custom:shopId"],
              outletShopId: user.attributes["custom:outletShopId"],
              randomId: randomId,
              type: user.attributes["custom:shopName"] + " User",
              location: location,
            },
          });
          navigate("../homePage");
        } catch (error) {
          // console.log(JSON.stringify(error))
          if (
            error.errors[0].errorType ===
            "DynamoDB:ConditionalCheckFailedException"
          )
            navigate("../homePage");
        }
      }
    } catch (error) {
      if (error.code === "UserNotConfirmedException") {
        message.error(
          "User is not confirmed. Kindly enter the password sent to your mail",
        );
        setModal(true);
      }
      if (error.code === "NotAuthorizedException")
        message.error("Incorrect username or password.");
      if (error.code === "UserNotFoundException")
        message.warning("You do not have an account, please sign-up.");

      console.log("error signing in", error);
    }
  };

  const verifyUser = async () => {
    setSpinnerState(true);
    const username = email;
    const code = passcode;
    try {
      const user = await Auth.confirmSignUp(username, code, {
        forceAliasCreation: false,
      });
      message.success("User Confirmed");
      setModal(false);
      setSpinnerState(false);
    } catch (e) {
      if (e.code === "ExpiredCodeException") alert("Your token is expired");
      else if (e.code === "CodeMismatchException")
        alert("You have entered a wrong code.");
      else alert("Something went wrong");
      console.log(e);
      setSpinnerState(false);
    }
  };

  const checkInput = () => {
    if (email === "") return message.error("Please enter user name");
    var mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== "" && mailReg.test(email) === false)
      return message.error("Please enter valid email");
    var passReg =
      /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g;
    if (passReg.test(password) === false)
      return message.error("Please enter a valid password");
    if (password === "") return message.error("Please enter password");
    signInUser();
  };

  const customStyles = {
    content: {
      top: "50%",
      width: "700px",
      height: "400px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="mainContainer">
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{ textAlign: "center" }}
        >
          <img src={Logo} alt="Logo" height={200} width={200} />
        </Col>
        <Col xs={24} sm={10} offset={1} className="blurBackground">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            style={{ width: "59%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputProps={{
              maxLength: 100,
            }}
            autoComplete="off"
          />
          <br />
          <br />

          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            style={{ width: "59%" }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{
              maxLength: 100,
            }}
            autoComplete="off"
          />

          <br />
          <br />

          <Button
            type="primary"
            disabled={spinnerState}
            onClick={() => checkInput()}
            style={{ width: "60%" }}
          >
            Log in
          </Button>
        </Col>
      </Row>

      <Modal
        visible={modal}
        onCancel={() => setModal(false)}
        footer={null}
        width={520}
        bodyStyle={{ padding: 24 }}
      >
        <div>
          <h1 className="modelTittle">Verify your account</h1>

          <h3 className="modelSubTittle">Passcode sent on your mail</h3>

          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            margin="normal"
            style={{ width: "80%" }}
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            inputProps={{
              maxLength: 100,
            }}
            autoComplete="off"
          />
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            style={{ textAlign: "end", paddingTop: 10 }}
          >
            <Button type="primary" onClick={verifyUser}>
              Ok
            </Button>
          </Col>
        </div>
      </Modal>
    </div>
  );
}
