import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../styles/signIn.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Button, message, Input, Card } from "antd";

function ChangePassword() {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {})
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
  }, []);

  const changePassword = async () => {
    try {
      await Auth.changePassword(Auth.user, oldPassword, newPassword);
      message.success("Password Changed");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      navigate("/");
    } catch (error) {
      console.log("error changing password", error);
    }
  };

  const checkInput = () => {
    if (oldPassword === "") return message.error("Please enter old password");
    if (newPassword === "") return message.error("Please enter new password");
    if (confirmPassword === "")
      return message.error("Please re-enter new password");
    if (oldPassword == newPassword)
      return message.error("New password is same as old password");
    if (newPassword != confirmPassword)
      return message.error("New password and conform password does not match");
    setSpinnerState(true);
    changePassword();
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Change Password</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            Change Password
          </Button>
        </Col>
        <div className="divider"></div>
      </Row>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Old Password:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                maxLength={100}
                type="password"
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                New Password:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
                maxLength={100}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Confirm New Password:
              </label>
              <Input
                style={{ flex: 1 }}
                placeholder="Enter Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                maxLength={100}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
