import React from "react";
import "../styles/homePage.css";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Menu } from "antd";
import {
  FileDoneOutlined,
  DashboardOutlined,
  MobileOutlined,
  PaperClipOutlined,
  DownloadOutlined,
  ShopOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  PartitionOutlined,
  IdcardOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";

function MenuLeft(props) {
  const navigate = useNavigate();

  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <>
      <Menu
        theme="light"
        style={{
          background: "#4087cb",
          color: "#fff",
          fontSize: 18,
          marginBottom: 15,
          top: 0,
        }}
        mode="inline"
        defaultSelectedKeys={[props.common.menuKey]}
      >
        {checkAccess("Dashboard") && (
          <Menu.Item
            key="1"
            style={{ marginTop: 5 }}
            icon={<DashboardOutlined />}
            onClick={() => {
              props.menuKey({ menuKey: "1" });
              navigate("/HomePage");
            }}
          >
            Dashboard
          </Menu.Item>
        )}
        {checkAccess("Analytics") && (
          <Menu.Item
            key="2"
            style={{ marginTop: 5 }}
            icon={<UnorderedListOutlined />}
            onClick={() => {
              props.menuKey({ menuKey: "2" });
              navigate("/Analytics");
            }}
          >
            Analytics
          </Menu.Item>
        )}
        <Menu.SubMenu
          title="CRM"
          mode="inline"
          icon={<FileDoneOutlined />}
          className="custom-submenu"
        >
          {checkAccess("Leads") && (
            <Menu.Item
              key="3"
              style={{ marginTop: 5 }}
              icon={<UnorderedListOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "3" });
                navigate("/ListLeads");
              }}
            >
              Leads
            </Menu.Item>
          )}
          {checkAccess("Quotation") && (
            <Menu.Item
              key="4"
              style={{ marginTop: 5 }}
              icon={<MobileOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "4" });
                navigate("/ListQuotations/homepage");
                refreshPage();
              }}
            >
              Quotation
            </Menu.Item>
          )}
          {checkAccess("Order") && (
            <Menu.Item
              key="5"
              style={{ marginTop: 5 }}
              icon={<FileDoneOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "5" });
                navigate("/ListOrder");
              }}
            >
              {props.common.shopType == "master" ? "Work-Order" : "Sales-Order"}
            </Menu.Item>
          )}
          {checkAccess("Production") && (
            <Menu.Item
              key="6"
              style={{ marginTop: 5 }}
              icon={<FileDoneOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "6" });
                navigate("/ListProduction");
              }}
            >
              Production
            </Menu.Item>
          )}
          {checkAccess("Installation") && (
            <Menu.Item
              key="7"
              style={{ marginTop: 5 }}
              icon={<FileDoneOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "7" });
                navigate("/ListInstallation");
              }}
            >
              Installation
            </Menu.Item>
          )}
          {checkAccess("Ticket") && (
            <Menu.Item
              key="8"
              style={{ marginTop: 5 }}
              icon={<FileDoneOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "8" });
                navigate("/ListTickets");
              }}
            >
              Tickets
            </Menu.Item>
          )}
        </Menu.SubMenu>
        {props.common.shopType == "master" &&
          props.common.role != "Production" && (
            <Menu.SubMenu
              title="Purchase"
              mode="inline"
              icon={<IdcardOutlined />}
              className="custom-submenu"
            >
              {checkAccess("PurchaseRequest") && (
                <Menu.Item
                  key="9"
                  style={{ marginTop: 5 }}
                  icon={<FileSyncOutlined />}
                  onClick={() => {
                    props.menuKey({ menuKey: "9" });
                    navigate("/ListPR");
                  }}
                >
                  Request
                </Menu.Item>
              )}
              {checkAccess("PurchaseOrder") && (
                <Menu.Item
                  key="10"
                  style={{ marginTop: 5 }}
                  icon={<FileSyncOutlined />}
                  onClick={() => {
                    props.menuKey({ menuKey: "10" });
                    navigate("/ListPO");
                  }}
                >
                  Order
                </Menu.Item>
              )}
              {checkAccess("Inventory") && (
                <Menu.Item
                  key="11"
                  style={{ marginTop: 5 }}
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    props.menuKey({ menuKey: "11" });
                    navigate("/ListInventory/homepage");
                    refreshPage();
                  }}
                >
                  Inventory
                </Menu.Item>
              )}
            </Menu.SubMenu>
          )}
        <Menu.SubMenu
          title="Product"
          mode="inline"
          icon={<PartitionOutlined />}
          className="custom-submenu"
        >
          {checkAccess("Materials") && (
            <Menu.Item
              key="12"
              style={{ marginTop: 5 }}
              icon={<PaperClipOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "12" });
                navigate("/ListComponents");
              }}
            >
              Materials
            </Menu.Item>
          )}
          {checkAccess("Product") && (
            <Menu.Item
              key="13"
              style={{ marginTop: 5 }}
              icon={<PartitionOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "13" });
                navigate("/ListGroups");
              }}
            >
              Product
            </Menu.Item>
          )}
        </Menu.SubMenu>
        {props.common.shopType == "master" && (
          <Menu.SubMenu
            title="Accounts"
            mode="inline"
            icon={<PartitionOutlined />}
            className="custom-submenu"
          >
            {checkAccess("Invoice") && (
              <Menu.Item
                key="14"
                style={{ marginTop: 5 }}
                icon={<FileDoneOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "14" });
                  navigate("/ListInvoice");
                }}
              >
                Sales Invoice
              </Menu.Item>
            )}
            {checkAccess("PurchaseInvoice") && (
              <Menu.Item
                key="15"
                style={{ marginTop: 5 }}
                icon={<FileSyncOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "15" });
                  navigate("/ListPI");
                }}
              >
                Purchase Invoice
              </Menu.Item>
            )}
            {checkAccess("SalesPayments") && (
              <Menu.Item
                key="16"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "16" });
                  navigate("/SalesPayments");
                }}
              >
                Sales Payments
              </Menu.Item>
            )}
            {checkAccess("PurchasePayments") && (
              <Menu.Item
                key="17"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "17" });
                  navigate("/PurchasePayments");
                }}
              >
                Purchase Payments
              </Menu.Item>
            )}
            {checkAccess("Expense") && (
              <Menu.Item
                key="18"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "18" });
                  navigate("/ListExpense");
                }}
              >
                Expenses
              </Menu.Item>
            )}
            {checkAccess("Invoice") && (
              <Menu.Item
                key="38"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "38" });
                  navigate("/InvoiceStmt");
                }}
              >
                Statement
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
        {(props.common.shopType == "master" ||
          props.common.shopType == "root") && (
          <Menu.SubMenu
            title="Manage"
            mode="inline"
            icon={<UserAddOutlined />}
            className="custom-submenu"
          >
            {checkAccess("ManageOutlet") && (
              <Menu.Item
                key="19"
                style={{ marginTop: 5 }}
                icon={<ShopOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "19" });
                  navigate("/ListOutlets");
                  refreshPage();
                }}
              >
                {props.common.shopType === "master" ? "Outlet" : "Company"}
              </Menu.Item>
            )}
            {checkAccess("ManageUser") && (
              <Menu.Item
                key="20"
                style={{ marginTop: 5 }}
                icon={<UserAddOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "20" });
                  navigate("/ListUsers/homepage/homePage");
                  refreshPage();
                }}
              >
                User
              </Menu.Item>
            )}
            {checkAccess("ManageEmployee") && (
              <Menu.Item
                key="21"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "21" });
                  navigate("/ListEmployee");
                }}
              >
                Employee
              </Menu.Item>
            )}
            {checkAccess("Attendance") && (
              <Menu.Item
                key="22"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "22" });
                  navigate("/ListAtndnc");
                }}
              >
                Attendance
              </Menu.Item>
            )}
            {checkAccess("Payroll") && (
              <Menu.Item
                key="23"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "23" });
                  navigate("/ListPayroll");
                }}
              >
                Payroll
              </Menu.Item>
            )}
            {checkAccess("Loan") && (
              <Menu.Item
                key="34"
                style={{ marginTop: 5 }}
                icon={<IdcardOutlined />}
                onClick={() => {
                  props.menuKey({ menuKey: "34" });
                  navigate("/ListLoan");
                }}
              >
                Loan
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
        {checkAccess("Reports") && (
          <Menu.SubMenu
            title="Reports"
            mode="inline"
            icon={<UserAddOutlined />}
            className="custom-submenu"
          >
            <Menu.Item
              key="24"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "24" });
                navigate("/ReportExpense");
              }}
            >
              Expense
            </Menu.Item>
            <Menu.Item
              key="25"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "25" });
                navigate("/ReportSales");
              }}
            >
              Sales
            </Menu.Item>
            <Menu.Item
              key="26"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "26" });
                navigate("/ReportPurchase");
              }}
            >
              Purchase
            </Menu.Item>
            <Menu.Item
              key="27"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "27" });
                navigate("/ReportLeads");
              }}
            >
              Leads
            </Menu.Item>
            <Menu.Item
              key="28"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "28" });
                navigate("/ReportProd");
              }}
            >
              Production
            </Menu.Item>
            <Menu.Item
              key="31"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "31" });
                navigate("/ReportProdGroup");
              }}
            >
              Product Group
            </Menu.Item>
            <Menu.Item
              key="29"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "29" });
                navigate("/OutletSales");
              }}
            >
              Outlet Sales Report
            </Menu.Item>
            <Menu.Item
              key="30"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "30" });
                navigate("/ReportFollowUp");
              }}
            >
              Follow Up
            </Menu.Item>
            <Menu.Item
              key="32"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "32" });
                navigate("/ReportSource");
              }}
            >
              Source
            </Menu.Item>
            <Menu.Item
              key="33"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "33" });
                navigate("/ReportBank");
              }}
            >
              Bank
            </Menu.Item>
            <Menu.Item
              key="36"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "36" });
                navigate("/ReportCoating");
              }}
            >
              Coating
            </Menu.Item>
            <Menu.Item
              key="37"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "37" });
                navigate("/ReportInvoice");
              }}
            >
              Invoice
            </Menu.Item>
            <Menu.Item
              key="39"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "39" });
                navigate("/ReportOutletInvc");
              }}
            >
              Outlet Invoice
            </Menu.Item>
            <Menu.Item
              key="40"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "40" });
                navigate("/ReportProfitLoss");
              }}
            >
              Profit & Loss
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {checkAccess("ReportType") && (
          <Menu.Item
            key="35"
            style={{ marginTop: 5 }}
            icon={<IdcardOutlined />}
            onClick={() => {
              props.menuKey({ menuKey: "35" });
              navigate("/ReportType");
            }}
          >
            Reports
          </Menu.Item>
        )}
        {/* <Menu.Item
              key="30"
              style={{ marginTop: 5 }}
              icon={<IdcardOutlined />}
              onClick={() => {
                props.menuKey({ menuKey: "30" });
                navigate("/ReportInstall");
              }}
            >
              Installation
            </Menu.Item> */}
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    menuKey: (data) => dispatch({ type: "MENU_KEY", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuLeft);
