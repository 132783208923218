import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, DatePicker, Button } from "antd";
import { getTypeAndDateShopIdPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import moment from "moment";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [outletShopId, setOutletShopId] = useState("");
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;

  const headers = [
    { label: "DC No.", key: "randomId" },
    { label: "Date", key: "addedDate" },
    { label: "Vendor Name", key: "vendorName" },
    { label: "Status", key: "status" },
  ];

  const Columns = [
    {
      title: "DC No.",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      render: (randomId, record) => (
        <a style={{ color: record.status == "Pending" ? "Red" : "Black" }}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = await getTypeAndDateShopIdPagination(
      "SendCoating",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    setReportList(allItems);
    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(outletShopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          {reportList.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={reportList}
                headers={headers}
                filename={
                  "Coating-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={reportList}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
