import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

// Create Document Component
export default function GeneratePI(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Text>Purchase Order</Text>
          </View>
          <View style={{ borderWidth: 1 }}>
            <View style={styles.topSection}>
              <View style={styles.topTextLeft}>
                <View style={styles.box}>
                  <Text style={{ fontWeight: "bold" }}>ROYAL FABRICS</Text>
                  <Text>312/44 THAMBU CHETTY STREET</Text>
                  <Text>MANNADY,CHENNAI -600001</Text>
                  <Text>8015611126 / 42043734</Text>
                  <Text>Email : royalmosquitonets@gmail.com</Text>
                  <Text>GSTIN/UIN: 33AMNPJ9237B1Z4</Text>
                </View>
                <View style={[styles.box]}>
                  <Text style={{ fontSize: 10 }}>Shipping Address</Text>
                  <Text style={{ fontWeight: "bold" }}>
                    1, Perumal Koil Street,
                  </Text>
                  <Text>Madhavaram, Chennai - 600060</Text>
                </View>
                <View style={[styles.box, { borderBottomWidth: 0 }]}>
                  <Text style={{ fontSize: 10 }}>To</Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {props.vendorDetails.shopName}
                  </Text>
                  <Text>{props.vendorDetails.address}</Text>
                  <Text>{props.vendorDetails.GSTNumber}</Text>
                </View>
              </View>
              <View style={styles.topTextRight}>
                <View style={[styles.box, { padding: 0 }]}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        width: "50%",
                        borderRightWidth: 1,
                      }}
                    >
                      <View style={{ padding: 2 }}>
                        <Text>PO No. {props.item.randomId}</Text>
                      </View>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>
                        Dated: {moment(new Date()).format("DD MMM YY")}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeading}>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  S No.
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Item Code
                </Text>
                <Text
                  style={{
                    width: "40%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Item Name
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Size
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Quantity
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Unit
                </Text>
                <Text
                  style={[styles.total, { width: "20%", textAlign: "center" }]}
                >
                  Color
                </Text>
              </View>

              {Object.entries(JSON.parse(props.item.componentList)).map(
                ([key, value], index) => {
                  return (
                    <>
                      <View style={styles.tableData}>
                        <Text
                          style={{
                            width: "5%",
                            borderRightWidth: 1,
                            textAlign: "center",
                            padding: 2,
                          }}
                        >
                          {" "}
                          {index + 1}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                          }}
                        >
                          {" "}
                          {value.itemCode}
                        </Text>
                        <Text
                          style={{
                            width: "40%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                          }}
                        >
                          {" "}
                          {value.itemName}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {value.itemSize}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {value.quantity}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {value.unit}
                        </Text>
                        <Text
                          style={[
                            styles.total,
                            {
                              width: "20%",
                              fontWeight: "bold",
                              textAlign: "center",
                            },
                          ]}
                        >
                          {" "}
                          {value.color}
                        </Text>
                      </View>
                    </>
                  );
                }
              )}

              <View style={[styles.tableHeading, { borderTopWidth: 1 }]}>
                <Text
                  style={{
                    width: "65%",
                    borderRightWidth: 1,
                    textAlign: "right",
                    padding: 2,
                  }}
                >
                  {" "}
                  Total
                </Text>
                <Text
                  style={{
                    width: "40%",
                    fontWeight: "bold",
                    padding: 2,
                  }}
                >
                  {" "}
                  {Object.values(JSON.parse(props.item.componentList)).reduce(
                    (sum, value) => sum + parseFloat(value.quantity),
                    0
                  )}
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}></View>
                <View
                  style={[
                    styles.signature,
                    { width: "50%", borderLeftWidth: 1 },
                  ]}
                >
                  <Text style={{ fontWeight: "bold" }}>for, Royal Fabrics</Text>
                  <Text>{"          "}</Text>
                  <Text>{"          "}</Text>
                  <Text>Authorised Signatory</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 40,
    marginRight: 40,
    borderBottom: "1px solid black",
    height: 70,
  },
  topSection: {
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 10,
    borderBottomWidth: 1,
  },
  topTextLeft: {
    textAlign: "left",
    width: "50%",
    borderRight: "1px solid black",
  },
  topTextRight: {
    textAlign: "left",
    width: "50%",
  },
  box: { borderBottom: "1px solid black", padding: 5 },
  table: {},
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataBlue: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#87CEEB",
  },
  name: {
    padding: 2,
    width: 250,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 55,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 80,
    textAlign: "right",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    fontSize: 10,
  },
  leftPadding: {
    paddingLeft: 20,
  },
  signature: {
    textAlign: "right",
    fontSize: 12,
    paddingRight: 10,
  },
  total: {
    textAlign: "right",
    paddingRight: 5,
  },
  inWords: {
    paddingLeft: 5,
    fontSize: 10,
    marginTop: 10,
  },
});
