import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Select } from "antd";
import { getInvoiceDateOVPagination, getItemsByTypeFunc } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";

function ReportExpense() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [from, setFrom] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const Columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
    },
    {
      title: "Invoice Count",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
    },
    // {
    //   title: "Paid",
    //   dataIndex: "paymentDone",
    //   key: "paymentDone",
    //   ellipsis: true,
    //   render: (paymentDone) => parseFloat(paymentDone).toFixed(2),
    // },
    // {
    //   title: "Due Amount",
    //   dataIndex: "paymentPending",
    //   key: "paymentPending",
    //   ellipsis: true,
    //   render: (paymentPending) => parseFloat(paymentPending).toFixed(2),
    // },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      render: (totalAmount) => totalAmount.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (from, shopId, outletShopId) => {
    let allItems = await getItemsByTypeFunc(from, shopId);

    let temp = {};

    allItems.map((item) => {
      item.location = from == "Outlet" ? item.location : item.shopName;
      let id = item.id;
      temp[id] = {};
      temp[id].id = id;
      temp[id].location = item.location;
      // temp[id].paymentDone = 0;
      // temp[id].paymentPending = 0;
      temp[id].totalAmount = 0;
      temp[id].total = 0;
    });

    console.log(JSON.stringify(temp));

    for (const value of Object.values(temp)) {
      let allItems = await getInvoiceDateOVPagination(
        from == "Outlet" ? "SalesInvoice" : "PurchaseInvoice",
        outletShopId,
        value.id,
        dateRange[0].format("YYYY-MM-DD"),
        dateRange[1].format("YYYY-MM-DD")
      );
      console.log(allItems.length);
      if (allItems.length > 0)
        allItems.map((item) => {
          // value.paymentDone = value.paymentDone + item.paymentDone;
          // value.paymentPending = value.paymentPending + item.paymentPending;
          value.totalAmount = value.totalAmount + item.totalAmount;
          value.total = value.total + 1;
        });
    }

    setAllInvoice(Object.values(temp));
    setSpinnerState(false);
  };

  useEffect(() => {
    const getInvoice = async () => {
      setAllInvoice([]);
      setSpinnerState(true);
      await getAllItemsByType(from, shopId, outletShopId);
    };
    if (shopId != "" && from != "" && outletShopId != "") getInvoice();
  }, [dateRange, from]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Select
            style={{ backgroundColor: "white", width: "15%" }}
            value={from}
            onChange={(value) => {
              setFrom(value);
            }}
          >
            <Option value="">Select Outlet/Vendor</Option>
            <Option value="Outlet">Outlet</Option>
            <Option value="Vendor">Vendor</Option>
          </Select>
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allInvoice}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
