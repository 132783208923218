import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create Document Component
export default function GenLeadReport(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.topSection}>
            <Text style={{ fontSize: 18, fontWeight: "bold", padding: 10 }}>
              Royal Fabrics
            </Text>
            <Text style={{ padding: 10 }}>Lead Report by outlet</Text>
            <Text style={{ padding: 10 }}>
              Period: {props.dateRange[0].format("MMM YY")} to{" "}
              {props.dateRange[1].format("MMM YY")}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <View style={{ width: 130 }}>
                <Text></Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: 390,
                  justifyContent: "space-around",
                }}
              >
                {props.allOutlets.map((item) => {
                  return (
                    <Text style={{ width: "10%" }}>
                      {item.month}-{item.yr}
                    </Text>
                  );
                })}
              </View>
            </View>
            <View style={styles.tableData}>
              <View style={{ width: 130 }}>
                {Object.entries(props.allOutlets[0].location).map(
                  ([key, value]) => {
                    return <Text style={{ fontWeight: "bold" }}>{key}</Text>;
                  }
                )}
              </View>
              <View
                style={{
                  width: 390,
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                {props.allOutlets.map((item) => {
                  return (
                    <>
                      <View style={{ width: "10%" }}>
                        {Object.entries(item.location).map(([key, value]) => {
                          return <Text>{value.total}</Text>;
                        })}
                      </View>
                    </>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  topSection: {
    textAlign: "left",
    marginLeft: 20,
    marginTop: 10,
    padding: 5,
    fontSize: 10,
    width: "515",
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    // justifyContent: "space-around",
    // alignItems: "center",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    // paddingTop: 2,
    // paddingBottom: 2,
    backgroundColor: "#D3D3D3",
  },
  tableData: {
    // justifyContent: "space-around",
    // alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  count: {
    width: "10%",
    // textAlign: "center",
  },
});
