import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

// Create Document Component
export default function GenReportInvoice(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Text>Invoice Report</Text>
          </View>
          <View style={{ borderWidth: 1, fontSize: 10, alignItems: "center" }}>
            <Text>ROYAL FABRICS</Text>
            <Text>312/44 THAMBU CHETTY STREET MANNADY,CHENNAI -600001</Text>
            <Text>
              Ph No. 8015611126 / 42043734 Email : royalmosquitonets@gmail.com
            </Text>
            <Text>GSTIN/UIN: 33AMNPJ9237B1Z4</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              fontSize: 10,
              paddingTop: 20,
              paddingBottom: 10,
              paddingLeft: 20,
            }}
          >
            <Text>Client Name: {props.location}</Text>
            <Text style={styles.balance}>Dated: {props.dateRange}</Text>
          </View>

          <View>
            <Text style={styles.balance}>
              Opening Balance {props.openingBlnc.toFixed(2)}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={styles.label}>Invoice Date</Text>
              <Text style={styles.label}>Invoice No.</Text>
              <Text style={styles.label}>Debit</Text>
              <Text style={styles.label}>Credit</Text>
            </View>
            {props.allInvoice.map((item) => {
              return (
                <>
                  <View style={styles.tableData}>
                    <Text style={styles.label}>
                      {" "}
                      {moment(item.date).format("DD MMM YY")}
                    </Text>
                    <Text style={styles.label}> {item.randomId}</Text>
                    <Text style={styles.amount}>
                      {" "}
                      {parseFloat(item.debit).toFixed(2)}
                    </Text>
                    <Text style={styles.amount}>
                      {" "}
                      {parseFloat(item.credit).toFixed(2)}
                    </Text>
                  </View>
                </>
              );
            })}
            <View style={styles.tableData}>
              <Text style={styles.label}></Text>
              <Text style={styles.label}></Text>
              <Text style={styles.amount}>
                {props.allInvoice.reduce(
                  (sum, value) => sum + parseFloat(value.debit),
                  0
                )}
              </Text>
              <Text style={styles.amount}>
                {props.allInvoice.reduce(
                  (sum, value) => sum + parseFloat(value.credit),
                  0
                )}
              </Text>
            </View>
          </View>
          <View>
            <Text style={styles.balance}>
              Closing Balance {props.closingBlnc.toFixed(2)}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 10 },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
    borderTopWidth: 1,
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  label: {
    padding: 2,
    width: "20%",
    textAlign: "center",
  },
  amount: {
    padding: 2,
    paddingRight: 10,
    width: "20%",
    textAlign: "right",
  },
  balance: {
    textAlign: "right",
    fontSize: 10,
    padding: 5,
    paddingRight: 20,
  },
});
