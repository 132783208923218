import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import numberToWords from "number-to-words";
import moment from "moment";

// Create Document Component
export default function GeneratePI(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Text>INVOICE</Text>
          </View>
          <View style={{ borderWidth: 1 }}>
            <View style={styles.topSection}>
              <View style={styles.topTextLeft}>
                <View style={styles.box}>
                  <Text style={{ fontWeight: "bold" }}>
                    {props.shopDetails.shopName}
                  </Text>
                  <Text>{props.shopDetails.phoneNumber}</Text>
                  <Text style={{ width: "70%" }}>
                    {props.shopDetails.address}
                  </Text>
                  <Text>GSTIN/UIN: {props.shopDetails.GSTNumber}</Text>
                  {/* <Text>State Name : </Text> */}
                </View>
                <View style={styles.box}>
                  <Text style={{ fontSize: 10 }}>Consignee (Ship to)</Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {props.outletDetails.shopName}
                  </Text>
                  <Text>{props.outletDetails.phoneNumber}</Text>
                  <Text>Bill To: {props.outletDetails.address}</Text>
                  <Text>Ship To: {props.item.shippingAddress}</Text>
                  <Text>State: {props.item.state}</Text>
                  <Text>GSTIN/UIN: {props.outletDetails.GSTNumber}</Text>
                  {/* <Text>State Name : </Text> */}
                </View>
                <View style={[styles.box, { borderBottomWidth: 0 }]}>
                  <Text style={{ fontSize: 10 }}>Supplier (Bill from)</Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {props.vendorDetails.shopName}
                  </Text>
                  <Text>{props.vendorDetails.phoneNumber}</Text>
                  <Text style={{ width: "70%" }}>
                    {props.vendorDetails.address}
                  </Text>
                  <Text>{props.vendorDetails.GSTNumber}</Text>
                  {/* <Text>State Name : </Text> */}
                </View>
              </View>
              <View style={styles.topTextRight}>
                <View style={[styles.box, { padding: 0 }]}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        width: "50%",
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                      }}
                    >
                      <View style={{ width: "50%", padding: 2 }}>
                        <Text>Invoice No.</Text>
                        <Text style={{ fontWeight: "bold" }}></Text>
                      </View>
                      <View style={{ width: "50%", padding: 2 }}>
                        <Text>e-Way Bill No.</Text>
                      </View>
                    </View>
                    <View
                      style={{ width: "50%", borderBottomWidth: 1, padding: 2 }}
                    >
                      <Text>Dated:</Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {moment(new Date()).format("DD MMM YY")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{ width: "50%", borderRightWidth: 1, padding: 2 }}
                    >
                      <Text>Supplier Invoice No. & Date:</Text>
                      <Text style={{ fontWeight: "bold" }}>
                        {props.item.PO_ID}-
                        {moment(props.item.addedDate).format("DD MMM YY")}
                      </Text>
                    </View>
                    <View style={{ width: "50%", padding: 2 }}>
                      <Text>Other References: </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeading}>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  S No.
                </Text>
                <Text
                  style={{
                    width: "55%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Description of Goods
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  Quantity
                </Text>
                <Text
                  style={[
                    styles.total,
                    { width: "10%", borderRightWidth: 1, textAlign: "center" },
                  ]}
                >
                  Rate
                </Text>
                <Text
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    textAlign: "center",
                  }}
                >
                  per
                </Text>
                <Text
                  style={[styles.total, { width: "15%", textAlign: "center" }]}
                >
                  Amount
                </Text>
              </View>

              {Object.entries(JSON.parse(props.item.componentList)).map(
                ([key, value], index) => {
                  return (
                    <>
                      <View style={styles.tableData}>
                        <Text
                          style={{
                            width: "5%",
                            borderRightWidth: 1,
                            textAlign: "center",
                            padding: 2,
                          }}
                        >
                          {" "}
                          {index + 1}
                        </Text>
                        <Text
                          style={{
                            width: "55%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                          }}
                        >
                          {" "}
                          {value.itemName}
                        </Text>
                        <Text
                          style={{
                            width: "10%",
                            borderRightWidth: 1,
                            fontWeight: "bold",
                            padding: 2,
                          }}
                        >
                          {" "}
                          {value.quantity}
                        </Text>
                        <Text
                          style={[
                            styles.total,
                            { width: "10%", borderRightWidth: 1, padding: 2 },
                          ]}
                        >
                          {" "}
                          {value.itemPrice}
                        </Text>
                        <Text
                          style={{
                            width: "5%",
                            borderRightWidth: 1,
                            padding: 2,
                          }}
                        >
                          {" "}
                          Qty
                        </Text>
                        <Text
                          style={[
                            styles.total,
                            { width: "15%", fontWeight: "bold", padding: 2 },
                          ]}
                        >
                          {" "}
                          {parseFloat(value.itemPrice * value.quantity).toFixed(
                            2
                          )}
                        </Text>
                      </View>
                    </>
                  );
                }
              )}

              <View style={[styles.tableHeading]}>
                <View
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "55%",
                    borderRightWidth: 1,
                    textAlign: "right",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                  }}
                >
                  <Text>Packing and Forwarding</Text>
                  <Text>INPUT - CGST</Text>
                  <Text>INPUT - SGST</Text>
                  <Text>INPUT - IGST</Text>
                  <Text>TCS COLLECTED @0.1%</Text>
                  {/* <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>Less:</Text>
                    <Text>Round Off</Text>
                  </View> */}
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    fontWeight: "bold",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "5%",
                    borderRightWidth: 1,
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                  }}
                >
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                  <Text> </Text>
                </View>
                <View
                  style={{
                    width: "15%",
                    textAlign: "right",
                    padding: 5,
                    paddingTop: 50,
                    paddingBottom: 100,
                  }}
                >
                  <Text>{parseFloat(props.item.extraCharges).toFixed(2)}</Text>
                  <Text>{parseFloat(props.item.GSTAmount / 2).toFixed(2)}</Text>
                  <Text>{parseFloat(props.item.GSTAmount / 2).toFixed(2)}</Text>
                  <Text>{parseFloat(props.item.IGSTAmount).toFixed(2)}</Text>
                  <Text>{parseFloat(props.item.TDSAmount).toFixed(2)}</Text>
                  {/* <Text>(-)0.28</Text> */}
                </View>
              </View>

              <View style={[styles.tableHeading]}>
                <Text style={{ width: "5%", borderRightWidth: 1, padding: 2 }}>
                  {" "}
                </Text>
                <Text
                  style={{
                    width: "55%",
                    borderRightWidth: 1,
                    textAlign: "right",
                    padding: 2,
                  }}
                >
                  {" "}
                  Total
                </Text>
                <Text
                  style={{
                    width: "10%",
                    borderRightWidth: 1,
                    fontWeight: "bold",
                    padding: 2,
                  }}
                >
                  {" "}
                  {Object.values(JSON.parse(props.item.componentList)).reduce(
                    (sum, value) => sum + parseFloat(value.quantity),
                    0
                  )}
                </Text>
                <Text style={{ width: "10%", borderRightWidth: 1, padding: 2 }}>
                  {" "}
                </Text>
                <Text style={{ width: "5%", borderRightWidth: 1, padding: 2 }}>
                  {" "}
                </Text>
                <Text
                  style={[
                    styles.total,
                    { width: "15%", fontWeight: "bold", padding: 2 },
                  ]}
                >
                  {" "}
                  {Object.values(JSON.parse(props.item.componentList))
                    .reduce(
                      (sum, value) => sum + value.itemPrice * value.quantity,
                      0
                    )
                    .toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={{}}>
              <View>
                <Text style={{ fontSize: 8, padding: 2 }}>
                  Amount Chargeable (in words)
                </Text>
                <Text style={[styles.inWords, { fontWeight: "bold" }]}>
                  INR{" "}
                  {numberToWords
                    .toWords(props.item.totalAmount)
                    .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                  Only
                </Text>
                <Text>{"          "}</Text>
                <Text>{"          "}</Text>
                <Text style={{ fontSize: 10, padding: 2 }}>
                  Company's GSTIN/UIN :{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    {props.vendorDetails.GSTNumber}
                  </Text>
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}></View>
                <View
                  style={[
                    styles.signature,
                    { width: "50%", borderLeftWidth: 1, borderTopWidth: 1 },
                  ]}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    for, {props.vendorDetails.shopName}
                  </Text>
                  <Text>{"          "}</Text>
                  <Text>{"          "}</Text>
                  <Text>Authorised Signatory</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 40,
    marginRight: 40,
    borderBottom: "1px solid black",
    height: 70,
  },
  topSection: {
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 10,
    borderBottomWidth: 1,
  },
  topTextLeft: {
    textAlign: "left",
    width: "50%",
    borderRight: "1px solid black",
  },
  topTextRight: {
    textAlign: "left",
    width: "50%",
  },
  box: { borderBottom: "1px solid black", padding: 5 },
  table: {},
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataBlue: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#87CEEB",
  },
  name: {
    padding: 2,
    width: 250,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 55,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 80,
    textAlign: "right",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    fontSize: 10,
  },
  leftPadding: {
    paddingLeft: 20,
  },
  signature: {
    textAlign: "right",
    fontSize: 12,
    paddingRight: 10,
  },
  total: {
    textAlign: "right",
    paddingRight: 5,
  },
  inWords: {
    paddingLeft: 5,
    fontSize: 10,
    marginTop: 10,
  },
});
