import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBill.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import { Row, Col, Button, message, Card, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import { getItemsByOutletPagination, getAttndByYearFunc } from "./functionCall";
import dayjs from "dayjs";
import moment from "moment";
import Excel from "exceljs";
const { Option } = Select;

function AddComponents(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [empId, setEmpId] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [timeRange, setTimeRange] = useState([
    dayjs("09:00", "HH:mm"),
    dayjs("18:00", "HH:mm"),
  ]);
  const [date, setDate] = useState(dayjs(new Date()));
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(moment().year());
  const [allItems, setAllItems] = useState({});
  const [mnthAttnd, setMnthAttnd] = useState({});
  const monthArray = [
    { key: "01", value: "Jan" },
    { key: "02", value: "Feb" },
    { key: "03", value: "Mar" },
    { key: "04", value: "Apr" },
    { key: "05", value: "May" },
    { key: "06", value: "Jun" },
    { key: "07", value: "Jul" },
    { key: "08", value: "Aug" },
    { key: "09", value: "Sep" },
    { key: "10", value: "Oct" },
    { key: "11", value: "Nov" },
    { key: "12", value: "Dec" },
  ];

  const yearArray = [moment().year() - 1, moment().year(), moment().year() + 1];

  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Attendance")) navigate("/homePage");
    getUser();
  }, []);

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      "Employee",
      "live",
      outletShopId
    );

    let temp = {};
    allItems.map((item) => {
      temp[item.id] = {};
      temp[item.id].id = item.id;
      temp[item.id].name = item.name;
    });
    setAllItems(temp);
  };

  const addItem = async () => {
    let attendance = {};
    attendance[month] = mnthAttnd[month];

    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          name: name,
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "Attendance",
          empId: empId,
          year: year,
          attendance: JSON.stringify(attendance),
        },
      });
      message.error("Attendance Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async (id, temp) => {
    let tempAttnd = temp;
    tempAttnd[month] = mnthAttnd[month];
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: id,
          updatedBy: updatedBy,
          attendance: JSON.stringify(tempAttnd),
        },
      });
      message.error("Attendance updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (name === "") return alert("Please enter employee name");
    setSpinnerState("true");
    const year = new Date(date).getFullYear();
    let allItems = await getAttndByYearFunc("Attendance", empId, year);
    if (allItems.length > 0)
      editItem(allItems[0].id, JSON.parse(allItems[0].attendance));
    else addItem();
  };

  async function handleChange(e) {
    console.log("1...");
    const file = e.target.files[0];
    try {
      const buffer = await readFileAsync(file);
      await processWorkbook(buffer);
      console.log("7...");
    } catch (error) {
      console.error("Error processing file:", error);
    }
  }

  async function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  async function processWorkbook(buffer) {
    console.log("2...");
    let temp = {};
    if (temp[month] == undefined) temp[month] = {};
    console.log("processWorkbook");
    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(buffer);
    console.log(workbook, "workbook instance");
    console.log("3...");
    for (const sheet of workbook.worksheets) {
      for (const row of sheet._rows) {
        // console.log(row.values, row.number); // Using row.number to get rowIndex
        console.log("4...");
        let day = "";
        let startTime = "";
        let endTime = "";
        let hours = "";
        let OT = "";
        row.eachCell((cell, colIndex) => {
          console.log(cell.value + " " + colIndex);
          // for (const cell of row._cells) {
          if (colIndex == "1") day = cell.value;
          else if (colIndex == "2") startTime = cell.value;
          else if (colIndex == "3") endTime = cell.value;
          else if (colIndex == "4") hours = cell.value;
          else if (colIndex == "5") OT = cell.value;
        });
        if (startTime != "" && endTime != "") {
          const attnd = year + "-" + month + "-" + day;
          if (temp[month][attnd] == undefined) temp[month][attnd] = {};
          temp[month][attnd].date = date.format("YYYY-MM-DD");
          temp[month][attnd].startTime = timeRange[0].format("HH:mm");
          temp[month][attnd].endTime = timeRange[1].format("HH:mm");
          temp[month][attnd].hours = hours;
          temp[month][attnd].OT = OT;
          temp[month][attnd].updatedBy = updatedBy;
        }
        console.log("5...");
      }
    }
    console.log("6...");
    console.log(JSON.stringify(temp));
    setMnthAttnd(temp);
  }

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 10,
              }}
            >
              <h3 className="bill-heading">Upload Attendance Details</h3>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: 10,
            }}
          >
            <Button
              style={{ marginRight: "8px", paddingRight: 25 }}
              onClick={() => checkInput()}
              disabled={spinnerState}
            >
              <PlusOutlined />
              {test.test === "addNewRecord"
                ? "Add Attendance"
                : "Update Attendance"}
            </Button>
          </Col>
        </Row>
        <div className="divider"></div>
        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    // marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Select
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      flex: 1,
                    }}
                    value={name}
                    onChange={(value) => {
                      setName(allItems[value].name);
                      setEmpId(value);
                    }}
                  >
                    <Option value="">Select a person</Option>
                    {Object.entries(allItems).map(([key, value]) => {
                      return <Option value={key}>{value.name}</Option>;
                    })}
                  </Select>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Select
                value={month}
                onChange={(value) => setMonth(value)}
                style={{ width: 150, marginTop: 10, marginBottom: 10 }}
              >
                {monthArray.map((item) => {
                  return <Option value={item.key}>{item.value}</Option>;
                })}
              </Select>
            </Col>
            <Col span={12}>
              <Select
                value={year}
                onChange={(value) => setYear(value)}
                style={{ width: 150, marginTop: 10, marginBottom: 10 }}
              >
                {yearArray.map((item) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <div>Upload Excel File</div>
                <input type="file" onChange={(e) => handleChange(e)} />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddComponents);
