import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, message, Card, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import {
  getItemsByOutletPagination,
  getAttndByYearFunc,
  getPayrollFunc,
} from "./functionCall";
import GeneratePayroll from "./generatePayroll";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
const { Option } = Select;

function AddPayroll(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [DOB, setDOB] = useState("");
  const [DOJ, setDOJ] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [documents, setDocuments] = useState({ PAN: "", aadhar: "" });
  const [randomId, setRandomId] = useState("");
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(moment().year());
  const [allItems, setAllItems] = useState([]);
  const [id, setId] = useState("");
  const [emgDetails, setEmgDetails] = useState({
    name: "",
    phoneNumber: "",
  });
  const [bankDetailsMap, setBankDetailsMap] = useState({
    name: "",
    IFSC: "",
    acNo: "",
    branch: "",
  });
  const [salaryDetails, setSalaryDetails] = useState({
    basicSalary: 0,
    HRA: 0,
    transport: 0,
    medical: 0,
    PF: 0,
    ESI: 0,
    empPF: 0,
    empESI: 0,
    loanAdvance: "",
  });
  const [salaryCredited, setSalaryCredited] = useState({
    basicSalary: 0,
    HRA: 0,
    transport: 0,
    medical: 0,
    incentive: "",
    PF: 0,
    ESI: 0,
    empPF: 0,
    empESI: 0,
    loanAdvance: "",
  });
  const [attndDetails, setAttndDetails] = useState({
    workingDays: 0,
    daysInMonth: 0,
    leaves: 0,
    holidays: 0,
    netPaidDays: 0,
  });
  const [earning, setEarnings] = useState(0);
  const [deductions, setDeduction] = useState(0);
  const [salary, setSalary] = useState(0);
  let test = useParams();

  const monthArray = [
    { key: "01", value: "Jan" },
    { key: "02", value: "Feb" },
    { key: "03", value: "Mar" },
    { key: "04", value: "Apr" },
    { key: "05", value: "May" },
    { key: "06", value: "Jun" },
    { key: "07", value: "Jul" },
    { key: "08", value: "Aug" },
    { key: "09", value: "Sep" },
    { key: "10", value: "Oct" },
    { key: "11", value: "Nov" },
    { key: "12", value: "Dec" },
  ];

  const yearArray = [moment().year() - 1, moment().year(), moment().year() + 1];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewRecord") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Payroll")) navigate("/homePage");
    getUser();
  }, []);

  const getItemsByOutlet = async (outletShopId) => {
    let allPayroll = await getPayrollFunc("Payroll", month, year);
    const payrollEmpIDs = allPayroll.map((payroll) => payroll.empId);
    let empList = await getItemsByOutletPagination(
      "Employee",
      "live",
      outletShopId
    );

    const employeesWithoutPayroll = empList.filter(
      (emp) => !payrollEmpIDs.includes(emp.id)
    );

    setAllItems(employeesWithoutPayroll);
  };

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setName(res.name);
        setDOB(res.DOB);
        setDOJ(res.DOJ);
        setGender(res.gender);
        setRole(res.role);
        setDocuments(JSON.parse(res.documents));
        setEmgDetails(JSON.parse(res.emgDetails));
        setBankDetailsMap(JSON.parse(res.bankDetailsMap));
        setSalaryDetails(JSON.parse(res.salaryDetails));
        setSalaryCredited(JSON.parse(res.salaryCredited));
        setRandomId(res.randomId);
        setAttndDetails(JSON.parse(res.attndDetails));
        setEarnings(res.earning);
        setDeduction(res.deductions);
        setSalary(res.salary);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const calculateSalary = () => {
    let days = attndDetails.daysInMonth;
    let daysPaid = attndDetails.netPaidDays;
    let basicTemp = (salaryDetails.basicSalary / days) * daysPaid;
    let HRATemp = (salaryDetails.HRA / days) * daysPaid;
    let transTemp = (salaryDetails.transport / days) * daysPaid;
    let medTemp = (salaryDetails.medical / days) * daysPaid;
    let tempEarnings =
      basicTemp +
      HRATemp +
      transTemp +
      medTemp +
      (salaryDetails.incentive == "" || salaryDetails.incentive == undefined
        ? 0
        : parseFloat(salaryDetails.incentive));
    let PFonTotal = basicTemp + transTemp + medTemp;
    let tempPF = (PFonTotal * salaryDetails.PF) / 100;
    let tempESI = (tempEarnings * salaryDetails.ESI) / 100;
    let tempEmpPF = (PFonTotal * salaryDetails.empPF) / 100;
    let tempEmpESI = (tempEarnings * salaryDetails.empESI) / 100;
    let tempDeduction =
      tempPF +
      tempESI +
      (salaryDetails.loanAdvance == ""
        ? 0
        : parseFloat(salaryDetails.loanAdvance));

    let temp = {
      basicSalary: basicTemp,
      HRA: HRATemp,
      transport: transTemp,
      medical: medTemp,
      incentive: salaryDetails.incentive,
      PF: tempPF,
      ESI: tempESI,
      empPF: tempEmpPF,
      empESI: tempEmpESI,
      loanAdvance: salaryDetails.loanAdvance,
    };

    setEarnings(tempEarnings.toFixed(2));
    setDeduction(tempDeduction.toFixed(2));
    setSalary((tempEarnings - tempDeduction).toFixed(2));
    setSalaryCredited(temp);
  };

  useEffect(() => {
    if (id != "") calculateSalary();
  }, [salaryDetails, attndDetails]);

  useEffect(() => {
    if (outletShopId != "") getItemsByOutlet(outletShopId);
  }, [month, year]);

  const addItem = async () => {
    let tempId = randomId + "-" + year + "-" + month;
    try {
      let addPayroll = await API.graphql({
        query: mutations.addItem,
        variables: {
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: tempId,
          addedBy: addedBy,
          type: "Payroll",
          empId: id,
          name: name,
          emgDetails: JSON.stringify(emgDetails),
          DOB: DOB,
          role: role,
          DOJ: DOJ,
          gender: gender,
          bankDetailsMap: JSON.stringify(bankDetailsMap),
          documents: JSON.stringify(documents),
          month: month,
          year: year,
          salaryDetails: JSON.stringify(salaryDetails),
          salaryCredited: JSON.stringify(salaryCredited),
          attndDetails: JSON.stringify(attndDetails),
          earning: earning,
          deductions: deductions,
          salary: salary,
        },
      });
      message.error("Payroll Added");
      if (salaryCredited.loanAdvance != "")
        loanPayment(addPayroll.data.addItem.id);
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const loanPayment = async (id) => {
    try {
      await API.graphql({
        query: mutations.loanPayment,
        variables: {
          id: id,
          updatedBy: updatedBy,
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          updatedBy: updatedBy,
          salaryDetails: JSON.stringify(salaryDetails),
          salaryCredited: JSON.stringify(salaryCredited),
          attndDetails: JSON.stringify(attndDetails),
          earning: earning,
          deductions: deductions,
          salary: salary,
        },
      });
      message.error("Payroll updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (name == "") return message.error("Please select employee");
    if (salaryDetails.basicSalary == 0)
      return message.error("Please enter basic pay");
    if (salaryDetails.HRA == 0) return message.error("Please enter HRA");
    if (salaryDetails.transport == 0)
      return message.error("Please enter transport allowance");
    if (salaryDetails.medical == 0)
      return message.error("Please enter medical allowance");
    if (salaryCredited.basicSalary == 0)
      return message.error("Please enter basic pay");
    if (salaryCredited.HRA == 0) return message.error("Please enter HRA");
    if (salaryCredited.transport == 0)
      return message.error("Please enter transport allowance");
    if (salaryCredited.medical == 0)
      return message.error("Please enter medical allowance");
    setSpinnerState("true");

    if (test.test === "addNewRecord") addItem();
    else editItem();
  };

  const getAttndByYear = async (id, year, month) => {
    let daysArray = getDaysOfMonthArray(year, month);
    let sunday = daysArray.filter((day) => moment(day).day() === 0);
    let holidays = props.common.holidayList.filter((value) =>
      daysArray.includes(value)
    );
    let holidayArray = sunday.concat(holidays);
    let temp = {};
    let tempDetails = {};
    tempDetails.workingDays = daysArray.length - holidayArray.length;
    tempDetails.daysInMonth = daysArray.length;
    tempDetails.holidays = holidayArray.length;

    let allItems = [];
    allItems = await getAttndByYearFunc("Attendance", id, year);
    if (allItems.length > 0 && JSON.parse(allItems[0].attendance)[month]) {
      temp = JSON.parse(allItems[0].attendance)[month];
      tempDetails.leaves =
        daysArray.length -
        Object.keys(temp).length -
        holidayArray.length +
        holidayArray.filter((value) => Object.keys(temp).includes(value))
          .length;
      tempDetails.netPaidDays =
        Object.keys(temp).length +
        holidayArray.length -
        holidayArray.filter((value) => Object.keys(temp).includes(value))
          .length;
      setAttndDetails(tempDetails);
    } else {
      tempDetails.leaves = daysArray.length - 0 - holidayArray.length;
      tempDetails.netPaidDays = 0 + holidayArray.length;
      setAttndDetails(tempDetails);
    }
  };

  function getDaysOfMonthArray(year, month) {
    const firstDayOfMonth = moment(`${year}-${month}-01`, "YYYY-MM-DD");
    const daysInMonth = firstDayOfMonth.daysInMonth();
    return Array.from({ length: daysInMonth }, (_, index) =>
      firstDayOfMonth.clone().add(index, "days").format("YYYY-MM-DD")
    );
  }

  const downloadPDF = async () => {
    await pdf(
      <GeneratePayroll
        randomId={randomId}
        name={name}
        emgDetails={emgDetails}
        DOB={DOB}
        role={role}
        DOJ={DOJ}
        gender={gender}
        bankDetailsMap={bankDetailsMap}
        documents={documents}
        month={month}
        year={year}
        salaryDetails={salaryDetails}
        salaryCredited={salaryCredited}
        attndDetails={attndDetails}
        earning={earning}
        deductions={deductions}
        salary={salary}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${randomId}.pdf`);
      });
  };

  const handleSalary = (e, field) => {
    // const value = parseFloat(e.target.value);
    const value = e.target.value;
    if (!isNaN(value) && value != "") {
      setSalaryDetails((prevSalary) => ({
        ...prevSalary,
        [field]: value,
      }));
    }
  };

  const handleSalCredit = (e, field) => {
    // const value = parseFloat(e.target.value);
    const value = e.target.value;
    if (!isNaN(value) && value != "") {
      setSalaryCredited((prevSalary) => ({
        ...prevSalary,
        [field]: value,
      }));
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#E2E2E2",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="bill-heading">Payroll</h3>
          </div>
        </Col>
        <Col
          span={7}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          offset={1}
        >
          {test.test != "addNewRecord" && (
            <Button
              style={{ marginRight: "8px", paddingRight: 25 }}
              disabled={spinnerState}
              onClick={() => downloadPDF()}
            >
              Download PDF
            </Button>
          )}
          <Button
            style={{ marginRight: "8px", paddingRight: 25 }}
            disabled={spinnerState}
            onClick={() => checkInput()}
          >
            {" "}
            <PlusOutlined />
            {test.test === "addNewRecord" ? "Add Payroll" : "Update Payroll"}
          </Button>
        </Col>
      </Row>
      <div className="divider"></div>

      <Card
        style={{
          backgroundColor: "white", // Set the desired background color with reduced opacity
          backdropFilter: "blur(8px)",
          marginTop: 20,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Select
              value={name}
              onChange={(value) => {
                let temp = JSON.parse(value);
                setId(temp.id);
                setName(temp.name);
                setRandomId(temp.randomId);
                setEmgDetails(JSON.parse(temp.emgDetails));
                setDOB(temp.DOB);
                setRole(temp.role);
                setDOJ(temp.DOJ);
                setGender(temp.gender);
                setBankDetailsMap(JSON.parse(temp.bankDetailsMap));
                setDocuments(JSON.parse(temp.documents));
                setSalaryDetails(JSON.parse(temp.salaryDetails));
                getAttndByYear(temp.id, year, month);
              }}
              style={{ width: 250 }}
            >
              {allItems.map((item) => {
                return (
                  <Option value={JSON.stringify(item)}>{item.name}</Option>
                );
              })}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              value={month}
              onChange={(value) => {
                setMonth(value);
                getAttndByYear(id, year, value);
              }}
              style={{ width: 250 }}
            >
              {monthArray.map((item) => {
                return <Option value={item.key}>{item.value}</Option>;
              })}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              value={year}
              onChange={(value) => {
                setYear(value);
                getAttndByYear(id, value, month);
              }}
              style={{ width: 250 }}
            >
              {yearArray.map((item) => {
                return <Option value={item}>{item}</Option>;
              })}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employee Name:
              </label>
              <label>{name}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employee Code:
              </label>
              <label>{randomId}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Father's Name:
              </label>
              <label>{emgDetails.name}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                DOB:
              </label>
              <label>{DOB}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Department
              </label>
              <label>{role}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                DOJ
              </label>
              <label>{DOJ}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Gender
              </label>
              <label>{gender}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Bank Name
              </label>
              <label>{bankDetailsMap.name}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                PAN Card
              </label>
              <label>{documents.PAN}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Bank Account Number
              </label>
              <label>{bankDetailsMap.acNo}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Aadhar Number
              </label>
              <label>{documents.aadhar}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                IFSC Code
              </label>
              <label>{bankDetailsMap.IFSC}</label>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="divider"></div>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Month
              </label>
              <label>
                {moment(month, "MM").format("MMMM")}-{year}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Working Days
              </label>
              <label>{attndDetails.workingDays}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Days in Month
              </label>
              <label>{attndDetails.daysInMonth}</label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Leaves of month
              </label>
              <label>{attndDetails.leaves}</label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Holidays in Month
              </label>
              <label>{attndDetails.holidays}</label>
            </div>
          </Col>

          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Net days paid
              </label>
              <label>{attndDetails.netPaidDays}</label>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="divider"></div>
      <Card>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Basic Salary
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="Basic Salary"
                value={salaryDetails.basicSalary}
                onChange={(e) => handleSalary(e, "basicSalary")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.basicSalary.toFixed(2)}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                PF
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="PF"
                value={salaryDetails.PF}
                onChange={(e) => handleSalary(e, "PF")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.PF.toFixed(2)}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                HRA
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="HRA"
                value={salaryDetails.HRA}
                onChange={(e) => handleSalary(e, "HRA")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.HRA.toFixed(2)}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                ESI
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="ESI"
                value={salaryDetails.ESI}
                onChange={(e) => handleSalary(e, "ESI")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.ESI.toFixed(2)}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Transport Allowance
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="Transport Allowance"
                value={salaryDetails.transport}
                onChange={(e) => handleSalary(e, "transport")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.transport.toFixed(2)}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Loan and Advance
              </label>
              {(test.test === "addNewRecord" && (
                <Input
                  style={{ flex: 1, textAlign: "right" }}
                  placeholder="Loan and advance"
                  value={salaryDetails.loanAdvance}
                  onChange={(e) => handleSalary(e, "loanAdvance")}
                  maxLength={10}
                />
              )) || (
                <label
                  style={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  {salaryDetails.loanAdvance}
                </label>
              )}
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.loanAdvance}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Medical Allowance
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="Medical Allowance"
                value={salaryDetails.medical}
                onChange={(e) => handleSalary(e, "medical")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.medical.toFixed(2)}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Incentive
              </label>
              <Input
                style={{ flex: 1, textAlign: "right" }}
                placeholder="Incentive"
                value={salaryDetails.incentive}
                onChange={(e) => handleSalary(e, "incentive")}
                maxLength={10}
              />
              <label
                style={{
                  flex: 1,
                  marginRight: 100,
                  textAlign: "right",
                }}
              >
                {salaryCredited.incentive}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Earnings
              </label>
              <label style={{ flex: 1, textAlign: "right", marginRight: 100 }}>
                {earning}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Deduction
              </label>
              <label style={{ flex: 1, textAlign: "right", marginRight: 100 }}>
                {deductions}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Total Earnings
              </label>
              <label style={{ flex: 1, textAlign: "right", marginRight: 100 }}>
                {salary}
              </label>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employer PF Contribution
              </label>
              <label style={{ flex: 1, textAlign: "right", marginRight: 100 }}>
                {salaryCredited.empPF.toFixed(2)}
              </label>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <label
                style={{
                  width: "120px",
                  marginRight: "8px",
                  fontWeight: "bold",
                }}
              >
                Employer PF ESI
              </label>
              <label style={{ flex: 1, textAlign: "right", marginRight: 100 }}>
                {salaryCredited.empESI.toFixed(2)}
              </label>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPayroll);
