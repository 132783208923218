import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Switch } from "antd";
import { TextField, InputAdornment, MenuItem } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllItemsPagination,
  getAllItemsPagination100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ListGroups(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [status, setStatus] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [outletShopId, setOutletShopId] = useState("");
  const [showCount, setShowCount] = useState({});
  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "17%",
      render: (randomId, record) => (
        <a onClick={() => navigate(`../ReadQuotation/${record.id}/order`)}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "14%",
      render: (name, record) => (
        <a
          style={{
            marginTop: -5,
            marginBottom: -5,
            color: "Black",
            fontWeight: record.status === "live" ? "bold" : "normal",
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
      width: "12%",
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "7%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      width: "11%",
      render: (status) => (status == "live" ? "New" : status),
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Order Location",
            dataIndex: "orderLocation",
            key: "orderLocation",
            ellipsis: true,
            width: "10%",
            render: (orderLocation) => orderLocation ?? "-",
          },
        ]
      : []),
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      width: "12%",
      render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "7%",
      render: (id) => (
        <a onClick={() => navigate(`../SqftCompQuot/${id}/order`)}>View</a>
      ),
    },
    {
      title: "URL",
      dataIndex: "tinyURL",
      key: "tinyURL",
      width: "6%",
      ellipsis: true,
      render: (tinyURL) =>
        (tinyURL && (
          <Link target="_blank" to={`${tinyURL}`}>
            URL
          </Link>
        )) || <a style={{ color: "Black" }}>NA</a>,
    },
    {
      title: "Whatsapp",
      dataIndex: "id",
      key: "id",
      width: "9%",
      ellipsis: true,
      render: (id, record) => (
        <Link
          target="_blank"
          to={`https://wa.me/${record.phoneNumber}?text=Hello ${
            record.name
          }, Thank you for your order. Your Bill amount is ${parseFloat(
            record.totalAmount
          ).toFixed(2)}. To view order details click: ${record.tinyURL}.`}
        >
          Whatsapp
        </Link>
      ),
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Sales Order",
            dataIndex: "orderId",
            key: "orderId",
            ellipsis: true,
            width: "10%",
            render: (orderId) =>
              orderId ? (
                <a onClick={() => navigate(`../SqftCompQuot/${orderId}/order`)}>
                  View
                </a>
              ) : (
                <a style={{ color: "Black" }}>NA</a>
              ),
          },
        ]
      : []),
    ...(props.common.shopType == "outlet" && props.common.role == "Manager"
      ? [
          {
            title: "Work Order",
            dataIndex: "masterOrderId",
            key: "masterOrderId",
            ellipsis: true,
            width: "10%",
            render: (masterOrderId) =>
              masterOrderId ? (
                <a
                  onClick={() =>
                    navigate(`../SqftCompQuot/${masterOrderId}/order`)
                  }
                >
                  View
                </a>
              ) : (
                <a style={{ color: "Black" }}>NA</a>
              ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Order")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = [];
    if (showAll) allItems = await getAllItemsPagination("Order", outletShopId);
    else allItems = await getAllItemsPagination100("Order", outletShopId);
    if (status != "")
      allItems = allItems.filter((item) => item.status == status);

    let temp = { New: 0, Production: 0 };
    temp.New = allItems.filter((item) => item.status == "live").length;
    temp.Production = allItems.filter(
      (item) => item.status == "In-Production"
    ).length;
    setShowCount(temp);
    setAllItems(allItems);
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      setShowCount({});
      if (outletShopId != "") await getAllItemsByType(outletShopId);
    };
    if (outletShopId != "") getData();
  }, [showAll, status]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 15,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search order"
            name="search order"
            variant="outlined"
            size="small"
            style={{ width: "40%", backgroundColor: "white", marginRight: 10 }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <TextField
            select
            margin="normal"
            label="Select Status"
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white", marginRight: 10 }}
            value={status}
            size="small"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            autoComplete="off"
          >
            <MenuItem key="live" value="live">
              New
            </MenuItem>
            <MenuItem key="In-Production" value="In-Production">
              In-Production
            </MenuItem>
            <MenuItem key="Assign To RF" value="Assign To RF">
              Assign To RF
            </MenuItem>
          </TextField>
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all Orders"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          {Object.entries(showCount).map(([key, value]) => {
            return (
              <p style={{ fontWeight: "bold" }}>
                {key} : {value}
              </p>
            );
          })}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
