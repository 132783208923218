import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import GenerateInvoice from "./generateInvoice";
import { pdf } from "@react-pdf/renderer";
import {
  Row,
  Col,
  Table,
  Button,
  message,
  Card,
  Select,
  Input,
  DatePicker,
  Modal,
} from "antd";
import "../styles/addBill.css";
import { checkAccess } from "./AuthorizationService";
import { saveAs } from "file-saver";
import { getItemsByTypeFunc, getAllItemsPagination10 } from "./functionCall";
import dayjs from "dayjs";
import moment from "moment";
const { Option } = Select;

function AddSQFTCompQuot(props) {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [paymentDueDate, setPaymentDueDate] = useState(
    dayjs(moment(new Date()).add(15, "day"))
  );
  const [outletId, setOutletId] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [allOutlets, setAllOutlets] = useState({});
  const [componentList, setComponentList] = useState({});
  const [allGroup, setAllGroup] = useState([]);
  const [modal, setModal] = useState("");
  const [term, setTerm] = useState("");
  const [creditPeriod, setCreditPeriod] = useState("15");
  const [addedBy, setAddedBy] = useState("");
  const [from, setFrom] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [paymentDone, setPaymentDone] = useState(0);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [state, setState] = useState("");

  let test = useParams();

  const paymentMargin = ["0", "7", "10", "15", "30", "45"];

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "HSN Code",
      dataIndex: "HSN",
      key: "HSN",
      ellipsis: true,
    },
    {
      title: "Area in sqft",
      dataIndex: "areaInSqFt",
      key: "areaInSqFt",
      ellipsis: true,
      render: (areaInSqFt, record) => (
        <Input
          value={areaInSqFt}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.id, "areaInSqFt", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.id, "areaInSqFt", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Price per sqft",
      dataIndex: "pricePerSQFT",
      key: "pricePerSQFT",
      ellipsis: true,
      render: (pricePerSQFT, record) => (
        <Input
          value={pricePerSQFT}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.id, "pricePerSQFT", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.id, "pricePerSQFT", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "itemTotal",
      key: "itemTotal",
      ellipsis: true,
      render: (itemTotal) => (
        <a>
          {props.common.symbol} {parseFloat(itemTotal).toFixed(2)}
        </a>
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => removeFromOrder(record.id)}
          danger={true}
          style={{ width: "90%" }}
        >
          Remove
        </Button>
      ),
    },
  ];

  const columnsListGroups = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (key, record) => (
        <Button type="primary" onClick={() => addToOrder(record)}>
          Add
        </Button>
      ),
    },
  ];

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            setAddedBy(user.attributes.email);
            setOutletShopId(user.attributes["custom:outletShopId"]);
            setShopId(user.attributes["custom:shopId"]);
            await getAllProducts(user.attributes["custom:shopId"]);
            if (test.test != "addNewRecord") {
              await getAllOutlets();
              await getItemById();
            } else
              props.setBillItems({
                subTotal: 0,
                totalAmount: 0,
                extraCharges: 0,
                GST: 0,
                GSTAmount: 0,
                IGST: 0,
                IGSTAmount: 0,
                TDS: 0,
                TDSAmount: 0,
              });
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (!checkAccess("Invoice")) navigate("/homePage");
  }, []);

  useEffect(() => {
    const getOutlet = async () => {
      setLocation("");
      setOutletId("");
      setPhoneNumber("");
      setGSTNumber("");
      setAddress("");
      setName("");
      setCategory("");
      await getAllOutlets();
    };
    if (from != "") getOutlet();
  }, [from]);

  const getAllOutlets = async () => {
    let temp = {};

    let allItems = await getItemsByTypeFunc(from, shopId);
    allItems.map((item) => {
      let key = from == "Outlet" ? item.location : item.shopName;
      temp[key] = {};
      temp[key].location = key;
      temp[key].id = item.id;
      temp[key].phoneNumber = item.phoneNumber;
      temp[key].address = item.address;
      temp[key].GSTNumber = item.GSTNumber;
      temp[key].name = item.name ?? "";
      temp[key].category = item.category ?? "";
    });

    setAllOutlets(temp);
  };

  const getAllProducts = async (shopId) => {
    let allGroup = await getItemsByTypeFunc("Group", shopId);

    setAllGroup(
      allGroup.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) {
        navigate(-1);
      } else {
        setItemDetails(res);
        setLocation(res.location);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setDate(dayjs(moment(res.date).toDate()));
        setPaymentDueDate(dayjs(moment(res.paymentDueDate).toDate()));
        setAddress(res.address);
        setGSTNumber(res.GSTNumber);
        setFrom(res.from);
        setComponentList(JSON.parse(res.componentList));
        setPaymentDone(res.paymentDone);
        setCategory(res.category ?? "");
        setName(res.name ?? "");
        setShippingAddress(res.shippingAddress ?? "");
        setState(res.state ?? "");
        props.setBillItems({
          subTotal: res.subTotal,
          totalAmount: res.totalAmount,
          extraCharges: res.extraCharges,
          GST: res.GST,
          GSTAmount: res.GSTAmount,
          IGST: res.IGST,
          IGSTAmount: res.IGSTAmount,
          TDS: res.TDS,
          TDSAmount: res.TDSAmount,
        });
      }
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (location === "") return message.error("Please select outlet name");
    if (phoneNumber === "") return message.error("Please enter outlet phone");
    if (address === "") return message.error("Please enter address");
    if (date === "") return message.error("Please select invoice date");
    if (category === "") return message.error("Please enter category");
    if (name === "") return message.error("Please enter name");
    if (state === "") return message.error("Please select state");
    if (paymentDueDate === "")
      return message.error("Please select payment due date");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");
    if (Object.entries(componentList).length == 0)
      return message.error("Item list cannot be empty");
    if (props.PI.GST === "") return message.error("Please select GST");
    if (isNaN(props.PI.total)) return;
    if (props.PI.total == 0) return alert("Total cannot be 0");
    setSpinnerState(true);
    addItem();
  };

  const addItem = async (image) => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10("SalesInvoice", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "SI-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "SI-" + year + "-" + tempCounter;

    try {
      let PI = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          location: location,
          date: date.format("YYYY-MM-DD"),
          paymentDueDate: paymentDueDate.format("YYYY-MM-DD"),
          address: address,
          phoneNumber: "+91" + phoneNumber,
          outletId: outletId,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "SalesInvoice",
          paymentStatus: false,
          paymentDone: 0,
          paymentPending: props.PI.total,
          totalAmount: props.PI.total,
          subTotal: props.PI.subTotal,
          extraCharges: props.PI.extraCharges,
          GST: props.PI.GST,
          GSTAmount: props.PI.GSTAmount,
          IGST: props.PI.IGST,
          IGSTAmount: props.PI.IGSTAmount,
          TDS: props.PI.TDS,
          TDSAmount: props.PI.TDSAmount,
          from: from,
          GSTNumber: GSTNumber,
          category: category,
          name: name,
          shippingAddress: shippingAddress,
          state: state,
        },
      });

      message.error("Sales Invoice Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const addToOrder = (item) => {
    let tempList = { ...componentList };
    let price = JSON.parse(item.outletSQFTMap);
    let key = item.id;
    tempList[key] = {};
    tempList[key].id = key;
    tempList[key].itemName = item.itemName;
    tempList[key].HSN = item.HSN;
    tempList[key].areaInSqFt = 0;
    tempList[key].pricePerSQFT = price[outletShopId].pricePerSQFT ?? 0;
    tempList[key].itemTotal = 0;
    setComponentList(tempList);
    console.log(JSON.stringify(tempList));
  };

  const removeFromOrder = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
    props.changeComponent({
      componentList: tempList,
    });
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    temp[key][value] = data;
    if (value == "pricePerSQFT" || value == "areaInSqFt") {
      let total = 0;
      if (temp[key].pricePerSQFT != "" && temp[key].areaInSqFt != "")
        total = temp[key].pricePerSQFT * parseFloat(temp[key].areaInSqFt);
      temp[key].itemTotal = parseFloat(total).toFixed(2);

      props.changeComponent({
        componentList: temp,
      });
    }

    setComponentList(temp);
  };

  const downloadPDF = async () => {
    await pdf(<GenerateInvoice item={itemDetails} />)
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${itemDetails.randomId}.pdf`);
      });
  };

  return (
    <div className="bill">
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <div>
              <h3 className="bill-heading">
                Invoice Details {itemDetails.randomId}
              </h3>
              {itemDetails.location && <h3>Outlet : {itemDetails.location}</h3>}
            </div>
          </Col>
          <Col span={6}>
            {test.test == "addNewRecord" && (
              <Button
                disabled={spinnerState}
                style={{ marginTop: 15 }}
                onClick={() => setModal(true)}
              >
                Add Product
              </Button>
            )}
            {test.test == "addNewRecord" && (
              <Button style={{ marginTop: 15 }} onClick={() => checkInput()}>
                Add Invoice
              </Button>
            )}
            {test.test != "addNewRecord" && (
              <Button style={{ marginTop: 15 }} onClick={() => downloadPDF()}>
                Download Invoice
              </Button>
            )}
          </Col>
        </Row>
        <div>
          <Card
            style={{
              backgroundColor: "white", // Set the desired background color with reduced opacity
              backdropFilter: "blur(8px)",
              marginTop: 20,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={from}
                  onChange={(value) => {
                    setFrom(value);
                  }}
                >
                  <Option value="">Select outlet/dealer</Option>
                  <Option value="Outlet">Outlet</Option>
                  <Option value="Dealer">Dealer</Option>
                </Select>
              </Col>
              <Col span={12}>
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={location}
                  onChange={(value) => {
                    let temp = JSON.parse(value);
                    setLocation(temp.location);
                    setOutletId(temp.id);
                    setPhoneNumber(temp.phoneNumber.slice(3, 13));
                    setGSTNumber(temp.GSTNumber);
                    setAddress(temp.address);
                    setName(temp.name);
                    setCategory(temp.category);
                  }}
                >
                  <Option value="">Select {from}</Option>
                  {Object.values(allOutlets).map((value) => {
                    return (
                      <Option value={JSON.stringify(value)}>
                        {value.location}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: "white" }}
                    margin="normal"
                    value={phoneNumber}
                    size="small"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    inputProps={{
                      maxLength: 10,
                    }}
                    autoComplete="off"
                  />
                  <TextField
                    label="GST Number"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: "white" }}
                    margin="normal"
                    value={GSTNumber}
                    size="small"
                    onChange={(e) => setGSTNumber(e.target.value)}
                    inputProps={{
                      maxLength: 16,
                    }}
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col span={12}>
                <TextField
                  label="Address"
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "white" }}
                  margin="normal"
                  value={address}
                  size="small"
                  onChange={(e) => setAddress(e.target.value)}
                  inputProps={{
                    maxLength: 500,
                  }}
                  multiline
                  rows={4}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Invoice Date: </label>
                    <DatePicker
                      placeholder="Date of expense"
                      onChange={(date) => setDate(date)}
                      value={date}
                      style={{ width: "80%", height: 40, marginTop: 15 }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Credit Period: </label>
                    <Select
                      style={{
                        width: "80%",
                        backgroundColor: "white",
                        height: 40,
                        marginTop: 15,
                      }}
                      value={creditPeriod}
                      onChange={(value) => {
                        setCreditPeriod(value);
                        setPaymentDueDate(moment(date).add(value, "day"));
                      }}
                    >
                      <Option value="">Days for payment</Option>
                      {paymentMargin.map((value) => {
                        return <Option value={value}>{value} Days</Option>;
                      })}
                    </Select>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <TextField
                  label="Shipping Address"
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "white" }}
                  margin="normal"
                  value={shippingAddress}
                  size="small"
                  onChange={(e) => setShippingAddress(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label>Payment Due Date: </label>
                  <DatePicker
                    placeholder="Payment Due Date"
                    onChange={(date) => setPaymentDueDate(date)}
                    value={paymentDueDate}
                    style={{ width: "80%", height: 40, marginTop: 15 }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label>State: </label>
                  <Select
                    showSearch
                    style={{
                      width: "80%",
                      backgroundColor: "white",
                      height: 40,
                      marginTop: 15,
                    }}
                    value={state}
                    onChange={(value) => setState(value)}
                  >
                    <Option value="">Select State</Option>
                    {props.common.indianStatesAndUTs.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label>Company/Outlet Name: </label>
                  <TextField
                    label="Company/Outlet Name"
                    variant="outlined"
                    style={{ width: "80%", backgroundColor: "white" }}
                    margin="normal"
                    value={name}
                    size="small"
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{
                      maxLength: 100,
                    }}
                    autoComplete="off"
                  />
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label>Category: </label>
                  <Select
                    style={{
                      width: "80%",
                      backgroundColor: "white",
                      height: 40,
                      marginTop: 15,
                    }}
                    value={category}
                    onChange={(value) => setCategory(value)}
                  >
                    <Option value="">Select Category</Option>
                    {props.common.outletCategoryArray.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </Card>
        </div>

        <div className="divider2"></div>
        {Object.keys(componentList).length > 0 && (
          <div style={{ marginTop: 30, marginBottom: 20 }}>
            <Table
              dataSource={Object.values(componentList)}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
        <Row gutter={[16, 16]}>
          <Col span={12}></Col>
          <Col span={12}>
            <Card style={{ width: "100%" }}>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginLeft: 0,
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "8px", width: "30%" }}>Sub Total:</td>
                    <td style={{ padding: "8px", width: "55%" }}></td>
                    <td
                      style={{
                        padding: "8px",
                        width: "80%",
                        textAlign: "right",
                      }}
                    >
                      {props.common.symbol}
                      {parseFloat(props.PI.subTotal).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", width: "30%" }}>
                      Transportation Charges:
                    </td>
                    <td style={{ padding: "8px", width: "55%" }}>
                      <Input
                        style={{ width: "80%" }}
                        placeholder="Enter extra charges"
                        value={props.PI.extraCharges}
                        onChange={(e) => {
                          if (e.target.value < 0)
                            return message.error("Please enter positive value");
                          props.setExtraCharges({
                            extraCharges: e.target.value,
                            GST: props.PI.GST,
                            IGST: props.PI.IGST,
                            TDS: props.PI.TDS,
                          });
                        }}
                        maxLength={10}
                      />
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        width: "80%",
                        textAlign: "right",
                      }}
                    >
                      + {parseFloat(props.PI.extraCharges).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", width: "30%" }}>GST:</td>
                    <td style={{ padding: "8px", width: "55%" }}>
                      <Select
                        style={{ width: "80%" }}
                        value={props.PI.GST}
                        onChange={(value) => {
                          props.setExtraCharges({
                            extraCharges: props.PI.extraCharges,
                            GST: value,
                            IGST: props.PI.IGST,
                            TDS: props.PI.TDS,
                          });
                        }}
                      >
                        <Option value="0">0%</Option>
                        <Option value="18">18%</Option>
                        <Option value="12">12%</Option>
                        <Option value="5">5%</Option>
                      </Select>
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        width: "80%",
                        textAlign: "right",
                      }}
                    >
                      + {parseFloat(props.PI.GSTAmount).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", width: "30%" }}>IGST:</td>
                    <td style={{ padding: "8px", width: "55%" }}>
                      <Select
                        style={{ width: "80%" }}
                        value={props.PI.IGST}
                        onChange={(value) => {
                          props.setExtraCharges({
                            extraCharges: props.PI.extraCharges,
                            GST: props.PI.GST,
                            IGST: value,
                            TDS: props.PI.TDS,
                          });
                        }}
                      >
                        <Option value="0">0%</Option>
                        <Option value="18">18%</Option>
                        <Option value="12">12%</Option>
                        <Option value="5">5%</Option>
                      </Select>
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        width: "80%",
                        textAlign: "right",
                      }}
                    >
                      + {parseFloat(props.PI.IGSTAmount).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "8px", width: "30%" }}>TDS:</td>
                    <td style={{ padding: "8px", width: "55%" }}>
                      <Select
                        style={{ width: "80%" }}
                        value={props.PI.TDS}
                        onChange={(value) => {
                          props.setExtraCharges({
                            extraCharges: props.PI.extraCharges,
                            GST: props.PI.GST,
                            IGST: props.PI.IGST,
                            TDS: value,
                          });
                        }}
                      >
                        <Option value="0">0%</Option>
                        <Option value="0.1">0.1%</Option>
                      </Select>
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        width: "80%",
                        textAlign: "right",
                      }}
                    >
                      + {parseFloat(props.PI.TDSAmount).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
        <div className="divider2"></div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Card style={{ width: "50%" }}>
            <Row>
              <Col span={7} offset={17}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>Total Amount:</div>
                  <div>
                    {props.common.symbol}{" "}
                    {isNaN(props.PI.total)
                      ? ""
                      : parseFloat(props.PI.total).toFixed(2)}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <Modal
        visible={modal}
        style={{ width: "100%" }}
        onCancel={() => setModal(false)}
        footer={null}
      >
        <div className="bill">
          <div className="bill-container">
            <TextField
              label="Search by product name"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              size="small"
              style={{ width: "90%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
            <Row>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term !== ""
                      ? allGroup.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                      : allGroup
                  }
                  columns={columnsListGroups}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  style={{ marginTop: 15 }}
                  onClick={() => setModal(false)}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    PI: state.PI,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setBillItems: (data) => dispatch({ type: "SET_PI_ITEMS", payload: data }),
    changeComponent: (data) =>
      dispatch({ type: "CHANGE_PI_COM", payload: data }),
    setExtraCharges: (data) =>
      dispatch({ type: "SET_PI_EXTRA_CRG", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSQFTCompQuot);
