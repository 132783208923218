import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table } from "antd";
import { getAllItemsPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);

  const Columns = [
    {
      title: "VendorName",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      width: "15%",
      render: (total) => total.toFixed(2),
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      ellipsis: true,
      width: "15%",
      render: (paid) => paid.toFixed(2),
    },
    {
      title: "Due",
      dataIndex: "due",
      key: "due",
      ellipsis: true,
      width: "15%",
      render: (due) => due.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let temp = {};

    // let allItems = await getItemsdatePagination(
    //   "PurchaseInvoice",
    //   outletShopId,
    //   moment(startDate).format("YYYY-MM-DD"),
    //   moment(endDate).format("YYYY-MM-DD")
    // );

    let allItems = await getAllItemsPagination("PurchaseInvoice", outletShopId);

    if (allItems.length > 0)
      allItems.map((item) => {
        let vendor = item.vendorName;
        if (temp[vendor] == undefined) {
          temp[vendor] = {};
          temp[vendor].vendorName = vendor;
          temp[vendor].total = 0;
          temp[vendor].due = 0;
          temp[vendor].paid = 0;
        }
        temp[vendor].total = temp[vendor].total + parseFloat(item.totalAmount);
        temp[vendor].paid = temp[vendor].paid + item.paymentDone;
        if (item.paymentStatus == "false") {
          temp[vendor].due = temp[vendor].due + item.paymentPending;
        }
      });

    setReportList(Object.values(temp));
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={reportList}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
