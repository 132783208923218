import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBill.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, message, Checkbox, Card, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { api } from "../index";
import { v4 as uuidv4 } from "uuid";
import { checkAccess } from "./AuthorizationService";
const { Option } = Select;

function AddComponents(props) {
  const navigate = useNavigate();
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [masterPP, setMasterPP] = useState("");
  const [itemUOM, setItemUOM] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [calBasedOn, setCalBasedOn] = useState("");
  const [unitPerPanel, setUnitPerPanel] = useState("");
  const [formula, setFormula] = useState("");
  const [explaination, setExplaination] = useState("");
  const [example, setExample] = useState("");
  const [images, setImages] = useState([]);
  const [imagesURI, setImagesURI] = useState([]);
  const [imagesBase64, setImagesBase64] = useState("");
  const [HSN, setHSN] = useState("");

  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then((user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewComponent")
            getItemById(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (!checkAccess("Materials")) navigate("/homePage");
    getUser();
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setItemName(res.itemName);
        setItemDescription(res.itemDescription);
        setMasterPP(res.masterPP);
        setItemUOM(res.itemUOM);
        setCalBasedOn(res.calBasedOn);
        setUnitPerPanel(res.unitPerPanel);
        setFormula(res.formula);
        setExplaination(res.explaination);
        setExample(res.example);
        setImages(res.itemImages ?? []);
        setImagesURI(res.itemImages ?? []);
        setHSN(res.HSN ?? "");
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const addItem = async (image) => {
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          itemName: itemName,
          itemDescription: itemDescription,
          masterPP: masterPP,
          itemUOM: itemUOM,
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "Component",
          calBasedOn: calBasedOn,
          unitPerPanel: unitPerPanel,
          formula: formula,
          explaination: explaination,
          example: example,
          itemImages: image ?? images,
          HSN: HSN,
        },
      });
      message.error("Material Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async (image) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          itemName: itemName,
          itemDescription: itemDescription,
          masterPP: masterPP,
          itemUOM: itemUOM,
          updatedBy: updatedBy,
          calBasedOn: calBasedOn,
          unitPerPanel: unitPerPanel,
          formula: formula,
          explaination: explaination,
          example: example,
          itemImages: image ?? images,
          HSN: HSN,
        },
      });
      message.error("Material updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (itemName === "") return alert("Please enter material name");
    if (itemDescription === "")
      return message.error("Please enter material description");
    if (masterPP != "" && isNaN(masterPP))
      return message.error("Please enter valid purchase price");
    if (itemUOM === "")
      return message.error("Please select unit of measurment");
    if (calBasedOn === "panel count" && unitPerPanel === "")
      return alert("Please enter unit per panel");
    if (isNaN(unitPerPanel)) return alert("Please enter valid panel per unit");
    if (HSN === "") return alert("Please enter HSN code");

    setSpinnerState("true");
    var tempImage = undefined;
    if (
      (test.test === "addNewComponent" && imagesURI.length > 0) ||
      (test.test != "addNewComponent" && imagesURI != images)
    )
      try {
        const response = await fetch(api + "uploadImage", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            base64: imagesBase64,
            fileName: uuidv4() + ".jpeg",
          }),
        });
        const responseJson = await response.json();
        tempImage = responseJson.Location;
      } catch (error) {
        console.error(error);
      }

    if (test.test === "addNewComponent") addItem(tempImage);
    else editItem(tempImage);
  };

  const onImageChange = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setImagesURI(URL.createObjectURL(files[0]));
    reader.onload = (e) => {
      setImagesBase64(e.target.result.replace(/^data:image\/\w+;base64,/, ""));
    };
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 10,
              }}
            >
              <h3 className="bill-heading">Enter Material Details</h3>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: 10,
            }}
          >
            <Button
              style={{ marginRight: "8px", paddingRight: 25 }}
              onClick={() => checkInput()}
              disabled={spinnerState}
            >
              <PlusOutlined />
              {test.test === "addNewComponent"
                ? "Add Material"
                : "Update Material"}
            </Button>
          </Col>
        </Row>
        <div className="divider"></div>
        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </label>
                {(props.common.shopType === "master" && (
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Name"
                    onChange={(e) => setItemName(e.target.value)}
                    maxLength={100}
                    value={itemName}
                  />
                )) || <span style={{ flex: 1 }}>{itemName}</span>}
              </div>
            </Col>
            {props.common.shopType === "master" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Description
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Description"
                    value={itemDescription}
                    onChange={(e) => setItemDescription(e.target.value)}
                    maxLength={300}
                  />
                </div>
              </Col>
            )}
          </Row>
          {props.common.shopType === "master" && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Purchase Price
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Purchase Price"
                    value={masterPP}
                    onChange={(e) => setMasterPP(e.target.value)}
                    maxLength={10}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            {props.common.shopType === "master" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Calculation basics
                  </label>
                  <Select
                    style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                    value={calBasedOn}
                    onChange={(value) => {
                      let temp = props.common.calBasedOn[value];
                      setCalBasedOn(temp.key);
                      setItemUOM(temp.UOM);
                      setFormula(temp.formula);
                      setExplaination(temp.explaination);
                      setExample(temp.example);
                    }}
                  >
                    <Option value="">Select</Option>
                    {Object.keys(props.common.calBasedOn).map((key) => (
                      <Option value={key}>{key}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
            )}
            {(calBasedOn === "" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Select UOM
                  </label>

                  <Select
                    style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                    value={itemUOM}
                    onChange={(value) => {
                      setItemUOM(value);
                    }}
                  >
                    <Option value="">Select UOM</Option>
                    {Object.entries(props.common.calBasedOn).map(
                      ([key, value]) => (
                        <Option value={key}>{key}</Option>
                      )
                    )}
                  </Select>
                </div>
              </Col>
            )) || (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Formula
                  </label>
                  <label style={{ width: "100%" }}>{formula}</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Explaination
                  </label>
                  <label style={{ width: "100%" }}>{explaination}</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Example
                  </label>
                  <label style={{ width: "100%" }}>{example}</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    UOM
                  </label>
                  <label style={{ width: "100%" }}>{itemUOM}</label>
                </div>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  HSN Code
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Name"
                  onChange={(e) => setHSN(e.target.value)}
                  maxLength={100}
                  value={HSN}
                />
              </div>
            </Col>

            {props.common.shopType === "master" &&
              calBasedOn === "panel count" && (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0",
                    }}
                  >
                    <label
                      style={{
                        width: "120px",
                        marginRight: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Unit per panel
                    </label>
                    <Input
                      style={{ flex: 1, width: "100%" }}
                      placeholder="Unit per panel"
                      value={unitPerPanel}
                      onChange={(e) => {
                        setUnitPerPanel(e.target.value);
                      }}
                      maxLength={10}
                    />
                  </div>
                </Col>
              )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={10} offset={1} style={{ marginTop: 20 }}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="myImage"
                onChange={onImageChange}
              />
              <div style={{ marginTop: -25, marginLeft: "58%" }}>
                <img src={imagesURI} width={180} />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddComponents);
