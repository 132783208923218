import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, DatePicker, Button } from "antd";
import { getItemsByDatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";
import { TextField, MenuItem } from "@mui/material";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [outletShopId, setOutletShopId] = useState("");
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [type, setType] = useState("Order");
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;

  const typeArray = ["Order", "Quotation", "Lead", "Ticket"];

  const headers = [
    { label: "Month", key: "month" },
    { label: "Year", key: "year" },
    { label: "Total", key: "total" },
    { label: "Count", key: "count" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportType")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let temp = {};

    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].total = 0;
      temp[key].count = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getItemsByDatePagination(
        type,
        outletShopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + item.totalAmount;
          value.count = value.count + 1;
        });
    }
    setSpinnerState(false);
    let sortedArray = Object.values(temp).sort((a, b) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      }
      return b.mnth - a.mnth;
    });
    setReportList(sortedArray);
  };

  useEffect(() => {
    if (outletShopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(outletShopId);
    }
  }, [dateRange, type]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
          <TextField
            select
            margin="normal"
            label="Select Type"
            variant="outlined"
            style={{
              width: "15%",
              backgroundColor: "#ffff",
              marginRight: 10,
            }}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
            autoComplete="off"
          >
            {typeArray.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          {reportList.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={reportList}
                headers={headers}
                filename={
                  type +
                  "-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <h2>{type} Report</h2>
              <div style={{ display: "flex" }}>
                <div style={{ width: 200 }}>
                  <p style={{ fontWeight: "bold" }}>Month-Year</p>
                  {(type == "Order" || type == "Quotation") && <p>Total</p>}
                  <p>Count</p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div style={{ width: 200 }}>
                        <p style={{ fontWeight: "bold" }}>
                          {item.month}-{item.year}
                        </p>
                        {(type == "Order" || type == "Quotation") && (
                          <p>{item.total}</p>
                        )}
                        <p>{item.count}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
