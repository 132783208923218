import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table } from "antd";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import { getItemIdByTypeFunc } from "./functionCall";

function ListComponents() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState({});
  const { test, from } = useParams();
  const [incom, setIncom] = useState(0);
  const [out, setOut] = useState(0);

  const Columns = [
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      ellipsis: true,
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      ellipsis: true,
    },
    {
      title: "Tracking ID",
      dataIndex: "trackingID",
      key: "trackingID",
      ellipsis: true,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
    },
    {
      title: "Raw Material",
      dataIndex: "rawMaterial",
      key: "rawMaterial",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Inventory")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = await getItemIdByTypeFunc("InventoryLog", test);
    allItems = allItems.filter((item) => item.outletShopId == outletShopId);
    let incom = 0;
    let out = 0;
    let tempLog = {};
    allItems.map((item) => {
      if (tempLog[item.color] == undefined) {
        tempLog[item.color] = {};
        tempLog[item.color].incomingQuantity = 0;
        tempLog[item.color].remainingQuantity = 0;
        tempLog[item.color].soldQuantity = 0;
        tempLog[item.color].lowStockValue = 0;
      }
      if (item.action == "Added") {
        incom = incom + parseFloat(item.quantity);
        tempLog[item.color].incomingQuantity =
          tempLog[item.color].incomingQuantity + parseFloat(item.quantity);
        tempLog[item.color].remainingQuantity =
          tempLog[item.color].remainingQuantity + parseFloat(item.quantity);
      }
      if (item.action == "Used") {
        tempLog[item.color].soldQuantity =
          tempLog[item.color].soldQuantity + parseFloat(item.quantity);
        tempLog[item.color].remainingQuantity =
          tempLog[item.color].remainingQuantity - parseFloat(item.quantity);
        out = out + parseFloat(item.quantity);
      }
    });
    console.log(JSON.stringify(tempLog));
    setIncom(incom);
    setOut(out);
    setAllItems(allItems);
  };

  return (
    <div style={{ padding: "20px" }}>
      <span
        style={{
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        {from}
      </span>
      <div
        style={{
          display: "flex",
          fontSize: 18,
          margin: 10,
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <span>In: {incom}</span>
        <span>Out: {out}</span>
        <span>Remaining: {incom - out}</span>
      </div>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={Object.values(allItems)}
                  scroll={{ y: 450 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponents);
