import { Outlet } from "react-router-dom";
import MenuItem from "./component/menu";
import Header from "./component/header";
import { Layout, theme } from "antd";

const { Content, Sider } = Layout;

export default function SidebarLayout() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header />
      <Layout>
        <Sider
          width={230}
          style={{
            overflowY: "scroll",
            height: "calc(100vh - 90px)",
            position: "fixed",
            left: 0,
            top: 90,
            background: "#4087CB",
          }}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <MenuItem />
        </Sider>
        <Layout style={{ marginLeft: 230 }}>
          <Content
            style={{
              overflowY: "auto",
              padding: 20,
              marginTop: 20,
            }}
          >
            <div style={{ marginTop: 64 }}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
