import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card } from "antd";
import { getTypeAndDateShopIdPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          getAllItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId) => {
    let temp = {};

    let newDate = moment();
    for (let i = 0; i < 3; i++) {
      let month = newDate.format("MMM");
      temp[month] = {};
      temp[month].month = month;
      temp[month].mnth = newDate.format("MM");
      temp[month].year = newDate.format("YYYY");
      temp[month].total = 0;
      temp[month].new = 0;
      temp[month].cutting = 0;
      temp[month].mesh = 0;
      temp[month].packed = 0;
      temp[month].dispatch = 0;
      temp[month].delivered = 0;
      temp[month].install = 0;
      newDate = newDate.subtract(1, "months");
    }
    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getTypeAndDateShopIdPagination(
        "Production",
        shopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + 1;
          if (item.status == "live") value.new = value.new + 1;
          else if (item.status == "Cutting Complete")
            value.cutting = value.cutting + 1;
          else if (item.status == "Mesh Process") value.mesh = value.mesh + 1;
          else if (item.status == "Packed") value.packed = value.packed + 1;
          else if (item.status == "Dispatched")
            value.dispatch = value.dispatch + 1;
          else if (item.status == "Delivered")
            value.delivered = value.delivered + 1;
          else if (item.status == "Installed")
            value.install = value.install + 1;
        });
    }
    setReportList(Object.values(temp));
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <h2>Production count by status for all outlets</h2>
              <div style={{ display: "flex" }}>
                <div style={{ width: 200 }}>
                  <p style={{ fontWeight: "bold" }}>Status</p>
                  <p style={{ fontWeight: "bold" }}>Total</p>
                  <p>New</p>
                  <p>Cutting Complete</p>
                  <p>Mesh Process</p>
                  <p>Packed</p>
                  <p>Dispatched</p>
                  <p>Delivered</p>
                  <p>Installed</p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div style={{ width: 200 }}>
                        <p style={{ fontWeight: "bold" }}>
                          {item.month}-{item.year}
                        </p>
                        <p style={{ fontWeight: "bold" }}>{item.total}</p>
                        <p>{item.new}</p>
                        <p>{item.cutting}</p>
                        <p>{item.mesh}</p>
                        <p>{item.packed}</p>
                        <p>{item.dispatch}</p>
                        <p>{item.delivered}</p>
                        <p>{item.install}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
