import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Card } from "antd";
import GenerateOrderDetailsPDF from "./generateOrderDetailsPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReadQuotation(props) {
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  let { test, from } = useParams();

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            await getItemById();
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (props.common.shopType === "root") navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setDetails(res);
        setOrderDetails(JSON.parse(res.orderDetails));
        setPaymentDetails(JSON.parse(res.paymentDetails));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const downloadPDF = async () => {
    await pdf(<GenerateOrderDetailsPDF item={details} />)
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${details.randomId}_OrderDetails.pdf`);
      });
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
        id="report"
      >
        <p>
          {from === "quotation" ? "Quotation" : "Order"} Details:{" "}
          {details.randomId}
        </p>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>Customer Name: {details.name}</p>
            <p>Billing Address: {details.address}</p>
            <p>Shipping Address: {details.shippingAddress}</p>
          </div>
          <div style={{ marginRight: 300 }}>
            {details.status === "visitScheduled" &&
              details.type === "Quotation" && (
                <p>
                  Customer visit is on{" "}
                  {moment(details.visitDate, "YYYY-MM-DD").format("DD MMM")} at{" "}
                  {moment(details.visitTime, "HH:mm:ss").format("hh:mm A")}
                </p>
              )}

            {details.status != "delivered" && details.estimatedDelivery && (
              <p>
                Estimated delivery date:{" "}
                {moment(details.estimatedDelivery, "YYYY-MM-DD").format(
                  "DD MMM"
                )}
              </p>
            )}
            {details.type === "Quotation" && (
              <p>
                Enquiry Date:{" "}
                {moment(details.addedDate, "YYYY-MM-DD").format("DD MMM")}
              </p>
            )}

            <p>Notes: {details.notes}</p>
            <p>Delivery Notes: {details.deliveryNotes}</p>
          </div>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderBottom: "1px ",
              backgroundColor: "#d1d6dc",
            }}
          >
            <p style={{ fontWeight: "bold", width: "10%" }}>Product Name</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Instructions</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Area in SqFt</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Profile Color</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Mesh Color</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Area</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Width (cm)</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Height (cm)</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Panel Count</p>
            <p style={{ fontWeight: "bold", width: "10%" }}>Quantity</p>
          </div>

          {Object.entries(orderDetails).length > 0 &&
            Object.entries(orderDetails).map(([key, value]) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  <p style={{ width: "10%" }}>
                    {value.itemName}({value.itemDescription})
                  </p>
                  <p style={{ width: "10%" }}>
                    {value.instructions != "" ? value.instructions : "NA"}
                  </p>
                  <p style={{ width: "10%" }}>{value.areaInSqFt}</p>
                  <p style={{ width: "10%" }}>{value.profileColor}</p>
                  <p style={{ width: "10%" }}>{value.meshColor}</p>
                  <div
                    style={{
                      flexDirection: "row",
                      width: "53%",
                    }}
                  >
                    {Object.values(value.dimensionMap)
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((value1) => {
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                borderBottom: "1px solid lightgray",
                              }}
                            >
                              <p style={{ width: "10%" }}>{value1.location}</p>
                              <p style={{ width: "10%" }}>{value1.width}</p>
                              <p style={{ width: "10%" }}>{value1.height}</p>
                              <p style={{ width: "10%" }}>
                                {value1.panelCount}
                              </p>
                              <p style={{ width: "10%" }}>{value1.quantity}</p>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Button style={{ margin: 10 }} onClick={downloadPDF} type="button">
        PDF
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReadQuotation);
