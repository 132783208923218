import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "antd";
import "../styles/dashBoard.css";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { TextField, MenuItem } from "@mui/material";
import moment from "moment";
import { getItemsByDatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function Dashboard(props) {
  const [allQuot, setAllQuot] = useState([]);
  const [totalQuot, setTotalQuot] = useState(0);
  const [allOrder, setAllOrder] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [allLeads, setAllLeads] = useState([]);
  const [allTckt, setAllTckt] = useState([]);
  const [dates, setDates] = useState("Month");
  const [outletShopId, setOutletShopId] = useState("");
  const navigate = useNavigate();

  const typeArray = ["Month", "Quarter", "Year"];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getDashboardDetails(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("Dashboard")) navigate("/homePage");
  }, []);

  useEffect(() => {
    if (outletShopId != "") getDashboardDetails(outletShopId);
  }, [dates]);

  const columnsQuot = [
    {
      title: "New Quotation",
      dataIndex: "id",
      key: "id",
      render: () => allQuot.filter((item) => item.status === "live").length,
    },
    {
      title: "Scheduled Visit",
      dataIndex: "id",
      key: "id",
      render: () =>
        allQuot.filter((item) => item.status === "Follow Up").length,
    },
    {
      title: "Convert to Order",
      dataIndex: "id",
      key: "id",
      render: () =>
        allQuot.filter((item) => item.status === "Convert to Order").length,
    },
    {
      title: "Rejected",
      dataIndex: "id",
      key: "id",
      render: () => allQuot.filter((item) => item.status === "Rejected").length,
    },
  ];

  const columnsOrder = [
    {
      title: "New Order",
      dataIndex: "id",
      key: "id",
      render: () => allOrder.filter((item) => item.status === "live").length,
    },
    {
      title: "In-Production",
      dataIndex: "id",
      key: "id",
      render: () =>
        allOrder.filter((item) => item.status === "In-Production").length,
    },
    {
      title: "Out for Delivery",
      dataIndex: "id",
      key: "id",
      render: () =>
        allOrder.filter((item) => item.status === "Out for Delivery").length,
    },
    {
      title: "Delivered",
      dataIndex: "id",
      key: "id",
      render: () =>
        allOrder.filter((item) => item.status === "Delivered").length,
    },
    {
      title: "Installed",
      dataIndex: "id",
      key: "id",
      render: () =>
        allOrder.filter((item) => item.status === "Installed").length,
    },
    {
      title: "Rejected",
      dataIndex: "id",
      key: "id",
      render: () =>
        allOrder.filter((item) => item.status === "Rejected").length,
    },
  ];

  const columnsLeads = [
    {
      title: "New Lead",
      dataIndex: "id",
      key: "id",
      render: () => allLeads.filter((item) => item.status === "live").length,
    },
    {
      title: "Follow Up",
      dataIndex: "id",
      key: "id",
      render: () =>
        allLeads.filter((item) => item.status === "Follow Up").length,
    },
    {
      title: "Convert to Quote",
      dataIndex: "id",
      key: "id",
      render: () =>
        allLeads.filter((item) => item.status === "Convert to Quote").length,
    },
    {
      title: "Cancelled",
      dataIndex: "id",
      key: "id",
      render: () =>
        allLeads.filter((item) => item.status === "Cancelled").length,
    },
  ];

  const columnsTckt = [
    {
      title: "New Ticket",
      dataIndex: "id",
      key: "id",
      render: () => allTckt.filter((item) => item.status === "live").length,
    },
    {
      title: "Pending",
      dataIndex: "id",
      key: "id",
      render: () => allTckt.filter((item) => item.status === "Pending").length,
    },
    {
      title: "Resolved",
      dataIndex: "id",
      key: "id",
      render: () => allTckt.filter((item) => item.status === "Resolved").length,
    },
    {
      title: "Closed",
      dataIndex: "id",
      key: "id",
      render: () => allTckt.filter((item) => item.status === "Closed").length,
    },
    {
      title: "Need more information",
      dataIndex: "id",
      key: "id",
      render: () =>
        allTckt.filter((item) => item.status === "Need more information")
          .length,
    },
    {
      title: "Waiting for Customer Response",
      dataIndex: "id",
      key: "id",
      render: () =>
        allTckt.filter(
          (item) => item.status === "Waiting for Customer Response"
        ).length,
    },
  ];

  const getDashboardDetails = async (outletShopId) => {
    let startDate = "";
    let endDate = moment(new Date()).format("YYYY-MM-DD");
    if (dates == "Month")
      startDate = moment().startOf("month").format("YYYY-MM-DD");
    else if (dates == "Quarter")
      startDate = moment().startOf("quarter").format("YYYY-MM-DD");
    else startDate = moment().startOf("year").format("YYYY-MM-DD");

    let allItems = await getItemsByDatePagination(
      "Quotation",
      outletShopId,
      startDate,
      endDate
    );

    allItems = allItems.filter((item) => item.status != "Rejected");
    let total1 = 0;
    allItems.map((item) => {
      total1 += item.totalAmount;
    });
    setTotalQuot(total1.toFixed(2));
    setAllQuot(allItems);

    let total = 0;

    let temp = await getItemsByDatePagination(
      "Order",
      outletShopId,
      startDate,
      endDate
    );

    temp.map((item) => {
      total += item.totalAmount;
    });

    setTotalOrder(total.toFixed(2));
    setAllOrder(
      temp.sort(
        (a, b) =>
          moment(b.addedDate + " " + b.time, "YYYY-MM-DD  HH:mm:ss") -
          moment(a.addedDate + " " + a.time, "YYYY-MM-DD  HH:mm:ss")
      )
    );

    let tempLead = await getItemsByDatePagination(
      "Lead",
      outletShopId,
      startDate,
      endDate
    );
    setAllLeads(tempLead);

    let tempTckt = await getItemsByDatePagination(
      "Ticket",
      outletShopId,
      startDate,
      endDate
    );
    setAllTckt(tempTckt);
  };

  return (
    <div className="Dashboard">
      <div className="dashboard-container">
        <div>
          <h3 className="card-heading">Application Statistics</h3>
          <Row>
            <Col xs={12} sm={7} md={5} lg={4}>
              <Card style={{ width: "80%", height: "80%" }} hoverable>
                <div style={{ marginTop: -30 }}>
                  <h1 className="static-count">
                    {props.common.symbol} {totalOrder}
                  </h1>
                  <h2 className="static-subheading">Order Value</h2>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={7} md={5} lg={4}>
              <Card style={{ width: "80%", height: "80%" }} hoverable>
                <div style={{ marginTop: -30 }}>
                  <h1 className="static-count">{allOrder.length}</h1>
                  <h2 className="static-subheading">Orders</h2>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={7} md={5} lg={4}>
              <Card style={{ width: "80%", height: "80%" }} hoverable>
                <div style={{ marginTop: -30 }}>
                  <h1 className="static-count">
                    {props.common.symbol} {totalQuot}
                  </h1>
                  <h2 className="static-subheading">Quotation Value</h2>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={7} md={5} lg={4}>
              <Card style={{ width: "80%", height: "80%" }} hoverable>
                <div style={{ marginTop: -30 }}>
                  <h1 className="static-count">{allQuot.length}</h1>
                  <h2 className="static-subheading">Quotation</h2>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={7} md={5} lg={4}>
              <Card style={{ width: "80%", height: "80%" }} hoverable>
                <div style={{ marginTop: -30 }}>
                  <h1 className="static-count">{allLeads.length}</h1>
                  <h2 className="static-subheading">Lead Count</h2>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={7} md={5} lg={4}>
              <Card style={{ width: "80%", height: "80%" }} hoverable>
                <div style={{ marginTop: -30 }}>
                  <h1 className="static-count">{allTckt.length}</h1>
                  <h2 className="static-subheading">Ticket Count</h2>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <h3 className="card-heading">Outlet Sales</h3>
          <Row>
            <TextField
              select
              margin="normal"
              variant="outlined"
              style={{ width: "40%" }}
              value={dates}
              size="small"
              onChange={(e) => {
                setDates(e.target.value);
              }}
              autoComplete="off"
            >
              {typeArray.map((item) => (
                <MenuItem key={item} value={item}>
                  This {item}
                </MenuItem>
              ))}
            </TextField>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Card>
                <Table
                  dataSource={[
                    {
                      key: "1",
                      name: "Mike",
                      age: 32,
                      address: "10 Downing Street",
                    },
                  ]}
                  columns={columnsLeads}
                  className="my-table"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card>
                <Table
                  dataSource={[
                    {
                      key: "1",
                      name: "Mike",
                      age: 32,
                      address: "10 Downing Street",
                    },
                  ]}
                  columns={columnsQuot}
                  className="my-table"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: 20 }}></div>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Card>
                <Table
                  dataSource={[
                    {
                      key: "1",
                      name: "Mike",
                      age: 32,
                      address: "10 Downing Street",
                    },
                  ]}
                  columns={columnsOrder}
                  className="my-table"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card>
                <Table
                  dataSource={[
                    {
                      key: "1",
                      name: "Mike",
                      age: 32,
                      address: "10 Downing Street",
                    },
                  ]}
                  columns={columnsTckt}
                  className="my-table"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
