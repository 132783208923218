import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Select, Button } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { getPayrollFunc } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import moment from "moment";
const { Option } = Select;

function ListPayroll(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(moment().year());

  const monthArray = [
    { key: "01", value: "Jan" },
    { key: "02", value: "Feb" },
    { key: "03", value: "Mar" },
    { key: "04", value: "Apr" },
    { key: "05", value: "May" },
    { key: "06", value: "Jun" },
    { key: "07", value: "Jul" },
    { key: "08", value: "Aug" },
    { key: "09", value: "Sep" },
    { key: "10", value: "Oct" },
    { key: "11", value: "Nov" },
    { key: "12", value: "Dec" },
  ];

  const yearArray = [moment().year() - 1, moment().year(), moment().year() + 1];

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Edit",
      dataIndex: "id",
      key: "id",
      width: "10%",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddPayroll/${id}`)}>Edit</a>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getItemsByOutlet();
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Payroll")) navigate("/homePage");
  }, []);

  const getItemsByOutlet = async () => {
    let allItems = await getPayrollFunc("Payroll", month, year);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  useEffect(() => {
    const getData = async () => {
      getItemsByOutlet();
    };
    getData();
  }, [month, year]);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            autoComplete="off"
            id="outlined-basic"
            label="Search by name"
            name="search by name"
            variant="outlined"
            size="small"
            style={{ width: "60%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={month}
            onChange={(value) => setMonth(value)}
            style={{ width: 150, marginTop: 10, marginBottom: 10 }}
          >
            {monthArray.map((item) => {
              return <Option value={item.key}>{item.value}</Option>;
            })}
          </Select>
          <Select
            value={year}
            onChange={(value) => setYear(value)}
            style={{ width: 150, marginTop: 10, marginBottom: 10 }}
          >
            {yearArray.map((item) => {
              return <Option value={item}>{item}</Option>;
            })}
          </Select>
          <Button type="primary">
            <Link to="../AddPayroll/addNewRecord">
              <PlusOutlined />
              Generate Payroll
            </Link>
          </Button>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListPayroll);
