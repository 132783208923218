import React from "react";
import "../styles/dashBoard.css";
import { Modal, Button } from "antd";
import { saveAs } from "file-saver";

export default function ZoomImage(props) {
  const downloadImage = async () => {
    const url = props.zoomURL;
    const uuidRegex = /\/images\/([a-f\d-]+)\.jpeg/i;
    const match = url.match(uuidRegex);
    const uuid = match && match[1];
    const response = await fetch(props.zoomURL + "/");
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    saveAs(blobUrl, uuid + ".jpeg"); // Put your image URL here.
  };

  return (
    <Modal
      visible={props.zoomURL != ""}
      onCancel={() => {
        props.setZoomURL("");
      }}
      width={720}
      height={720}
      footer={null}
    >
      <img
        src={props.zoomURL}
        style={{
          width: "650px",
          height: "650px",
          objectFit: "cover",
          border: "1px solid #ccc",
        }}
      />
      <Button style={{ marginRight: "10px" }} onClick={() => downloadImage()}>
        Download Image
      </Button>
    </Modal>
  );
}
