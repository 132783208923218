import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../images/Logo.jpg";
import moment from "moment";
import customFont from "../fonts/arial.ttf";
Font.register({ family: "Arial", src: customFont });

// Create Document Component
export default function GenerateSQFTPDF(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image src={Logo} style={{ width: 70, height: 70 }} />
              <Text style={styles.shopName}>
                {props.outletDetails.shopName.toUpperCase()}
              </Text>
              <Text>{props.outletDetails.address}</Text>
              <Text>GSTIN: {props.outletDetails.GSTNumber}</Text>
              <Text>Email: {props.outletDetails.email}</Text>
              <Text>Website: www.royalmosquitonets.com</Text>
            </View>
            <View style={styles.headerRight}>
              <Text style={styles.from}>
                {props.from == "quotation" ? "Quotation" : "Order"}
              </Text>
              <View style={styles.headerInnerRight}>
                <View>
                  <Text style={styles.borderLeft}>Reference ID:</Text>
                  <Text style={styles.borderLeft}>Date:</Text>
                  {props.from == "order" && (
                    <Text style={styles.borderLeft}>Delivery Date:</Text>
                  )}
                  {props.SOTotal != null && (
                    <Text style={styles.borderLeft}>SO Value:</Text>
                  )}
                </View>
                <View>
                  <Text style={styles.borderRight}>{props.item.randomId}</Text>
                  <Text style={styles.borderRight}>
                    {moment(props.item.addedDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    )}
                  </Text>
                  {props.from == "order" && (
                    <Text style={styles.borderRight}>
                      {moment(
                        props.item.estimatedDelivery,
                        "YYYY-MM-DD"
                      ).format("DD-MM-YYYY")}
                    </Text>
                  )}
                  {props.SOTotal != null && (
                    <Text style={styles.borderRight}>
                      {props.SOTotal.toFixed(2)}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={styles.topSection}>
            <Text>To, </Text>
            <Text>{props.item.name}</Text>
            {props.from != "production" && (
              <Text>{props.item.phoneNumber}</Text>
            )}
            <Text>{props.item.email}</Text>
            <Text>Bill To: {props.item.address}</Text>
            <Text>Ship To: {props.item.shippingAddress}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={styles.sNo}>S No.</Text>
              <Text style={styles.image}>Image</Text>
              <Text style={styles.countBold}>Name</Text>
              <Text style={styles.count}>Description</Text>
              <Text style={styles.count}>Profile Color</Text>
              <Text style={styles.count}>Mesh Color</Text>
              <Text style={styles.number}>Qty</Text>
              {props.from != "production" && (
                <Text style={styles.amount}>Price/sqft</Text>
              )}
              <Text style={styles.amount}>Total</Text>
            </View>

            {Object.entries(JSON.parse(props.item.orderDetails)).map(
              ([key, value], index) => {
                return (
                  <>
                    <View style={styles.tableData}>
                      <Text style={styles.sNo}>{index + 1}</Text>
                      <Image
                        src={
                          value.itemImages.length > 0
                            ? value.itemImages[0]
                            : Logo
                        }
                        style={styles.image}
                      />
                      <Text style={styles.countBold}>{value.itemName}</Text>
                      <Text style={styles.count}>{value.itemDescription}</Text>
                      <Text style={styles.count}>{value.profileColor}</Text>
                      <Text style={styles.count}>{value.meshColor}</Text>
                      <Text style={styles.number}>{value.areaInSqFt}</Text>
                      {props.from != "production" && (
                        <Text style={styles.amount}>{value.pricePerSQFT}</Text>
                      )}
                      <Text style={styles.amount}>
                        {/* {props.symbol} */}
                        {parseFloat(value.itemTotal).toFixed(2)}
                      </Text>
                    </View>
                  </>
                );
              }
            )}
            {props.item.subTotal ? (
              <View style={styles.tableData}>
                <Text style={styles.additionalCharges}>Sub-Total:</Text>
                <Text style={styles.amount}>
                  {/* {props.symbol}  */}
                  {parseFloat(props.item.subTotal).toFixed(2)}
                </Text>
              </View>
            ) : (
              <></>
            )}
            {props.item.discountAmount ? (
              <View style={styles.tableData}>
                <Text style={styles.additionalCharges}>Discount:</Text>
                <Text style={styles.amount}>
                  {/* {props.symbol}{" "} */}
                  {parseFloat(props.item.discountAmount).toFixed(2)}
                </Text>
              </View>
            ) : (
              <></>
            )}
            {props.item.GSTAmount ? (
              <View style={styles.tableData}>
                <Text style={styles.additionalCharges}>GST:</Text>
                <Text style={styles.amount}>
                  {/* {props.symbol}  */}
                  {parseFloat(props.item.GSTAmount).toFixed(2)}
                </Text>
              </View>
            ) : (
              <></>
            )}
            {props.item.extraCharges ? (
              <View style={styles.tableData}>
                <Text style={styles.additionalCharges}>Transportation:</Text>
                <Text style={styles.amount}>
                  {/* {props.symbol}  */}
                  {parseFloat(props.item.extraCharges).toFixed(2)}
                </Text>
              </View>
            ) : (
              <></>
            )}
            <View style={styles.tableDataGrey}>
              <Text style={styles.additionalCharges}>Total:</Text>
              <Text style={styles.amount}>
                {/* {props.symbol}  */}
                {parseFloat(props.item.totalAmount).toFixed(2)}
              </Text>
            </View>
            {props.from == "order" && (
              <>
                <View style={styles.tableData}>
                  <Text style={styles.additionalCharges}>Amount Paid:</Text>
                  <Text style={styles.amount}>
                    {/* {props.symbol}  */}
                    {parseFloat(props.paymentDetails.paymentDone).toFixed(2)}
                  </Text>
                </View>
                <View style={styles.tableData}>
                  <Text style={styles.additionalCharges}>Amount Pending:</Text>
                  <Text style={styles.amount}>
                    {/* {props.symbol}  */}
                    {parseFloat(props.paymentDetails.paymentPending).toFixed(2)}
                  </Text>
                </View>
              </>
            )}
          </View>
          <View style={styles.notes}>
            <Text>Notes:</Text>
            <Text>{props.PDFNotes}</Text>
          </View>
          {props.outletDetails.bankDetails && (
            <View style={styles.notes}>
              <Text>Bank Details:</Text>
              <Text>{props.outletDetails.bankDetails}</Text>
            </View>
          )}
          <View style={styles.belowDetailsBold}>
            <Text>PAYMENT:</Text>
            <Text style={styles.leftPadding}>{props.paymentConditions}</Text>
          </View>
          <View style={styles.belowDetails}>
            <Text>{props.tAndC}</Text>
          </View>
          <View style={styles.signature}>
            <Text>For {props.outletDetails.shopName.toUpperCase()},</Text>
            <Text>{"                  "}</Text>
            <Text>{"                  "}</Text>
            <Text>{"                  "}</Text>
            <Text>Authorized Signatory</Text>
            <Text>
              Thanking you and assuring you of our best services at all times.
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 30,
    marginRight: 30,
    fontSize: 8,
  },
  headerLeft: {
    border: "1px solid black",
    borderRightWidth: 1.3,
    padding: 5,
    width: 300,
  },
  headerRight: {
    border: "1px solid black",
    borderLeftWidth: 1.3,
    width: 300,
  },
  headerInnerRight: {
    flexDirection: "row",
    alignContent: "space-between",
    fontSize: 9,
  },
  borderLeft: {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    paddingLeft: 2,
    width: 120,
    textAlign: "left",
  },
  borderRight: {
    borderBottom: "1px solid black",
    paddingRight: 5,
    width: 126,
    textAlign: "right",
  },
  from: {
    borderBottom: "1px solid black",
    textTransform: "uppercase",
    textAlign: "center",
    padding: 5,
    backgroundColor: "#D3D3D3",
    fontWeight: "bold",
  },
  topSection: {
    textAlign: "left",
    marginLeft: 20,
    marginTop: 10,
    padding: 5,
    fontSize: 9,
    border: "1px solid black",
    width: "515",
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataGrey: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#D3D3D3",
  },
  name: {
    padding: 2,
    width: 150,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  countBold: {
    padding: 2,
    width: 80,
    textAlign: "center",
    fontWeight: "bold",
  },
  number: {
    padding: 2,
    width: 20,
    textAlign: "center",
  },
  sNo: {
    padding: 2,
    width: 10,
    textAlign: "center",
  },
  image: {
    padding: 2,
    width: 70,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 60,
    textAlign: "right",
    fontFamily: "Arial",
  },
  color: {
    padding: 2,
    width: 65,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  notes: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
  },
  belowDetails: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
    fontFamily: "Times-Roman",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    fontSize: 8,
    fontFamily: "Times-Bold",
  },
  leftPadding: {
    paddingLeft: 10,
  },
  signature: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "right",
    fontSize: 8,
    paddingRight: 30,
  },
  shopName: {
    fontSize: 12,
    fontWeight: "bold",
  },
});
