import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table } from "antd";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import {
  getItemByVendorPagination,
  getItemByOutletIdPagination,
} from "./functionCall";

function ListComponents(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState({});
  const test = useParams();

  const Columns = [
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      ellipsis: true,
    },
    {
      title: "Payment Id",
      dataIndex: "paymentId",
      key: "paymentId",
      ellipsis: true,
    },
    {
      title: "Installment Amount",
      dataIndex: "installmentAmount",
      key: "installmentAmount",
      ellipsis: true,
    },
    {
      title: "Payment Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date).format("DD MMM YY"),
    },
    {
      title: "Payment Time",
      dataIndex: "time",
      key: "time",
      ellipsis: true,
      render: (time) => moment(time, "HH:mm:ss").format("hh:mm A"),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Inventory")) navigate("/homePage");
  }, []);

  const getItemsByType = async (outletShopId) => {
    let allItems = [];

    if (test.from == "purchase")
      allItems = await getItemByVendorPagination(
        "PurchasePayment",
        "live",
        outletShopId,
        test.test
      );
    else
      allItems = await getItemByOutletIdPagination(
        "SalesPayment",
        "live",
        outletShopId,
        test.test
      );
    setAllItems(allItems);
  };

  return (
    <div style={{ padding: "20px" }}>
      <p
        style={{
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        Payment History
      </p>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={Object.values(allItems)}
                  scroll={{ y: 450 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponents);
