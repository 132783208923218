import React from "react";
import "../styles/addBanner.css";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Modal } from "antd";
import "../styles/addBill.css";
import { CloseOutlined } from "@ant-design/icons";

function HistoryModal(props) {
  return (
    <Modal
      visible={props.historyModal}
      width={900}
      onCancel={() => props.setHistoryModal(false)}
      footer={null}
      title={<h2 style={{ fontSize: "20px" }}>Quotation History</h2>}
      closeIcon={
        <CloseOutlined
          style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
        />
      }
    >
      <div>
        {Object.entries(props.quotationTrail)
          .sort(
            (a, b) =>
              moment(b[1].date, "YYYY-MM-DD HH:mm:ss") -
              moment(a[1].date, "YYYY-MM-DD HH:mm:ss"),
          )
          .map(([key, value]) => {
            return (
              <>
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {value.updatedBy} changed on{" "}
                    {moment(value.date, "YYYY-MM-DD HH:mm:ss").format(
                      "DD MMM hh:mm",
                    )}
                  </p>
                  {value.history.map((item) => (
                    <p>
                      {item.name} : {item.oldValue}
                      {item.oldValue != "" && item.newValue != ""
                        ? "-->"
                        : ""}{" "}
                      {item.newValue}
                    </p>
                  ))}
                </div>
              </>
            );
          })}

        <div className="fieldStyle">
          <Button
            className="button"
            type="primary"
            onClick={() => props.setHistoryModal(false)}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(HistoryModal);
