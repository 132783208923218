import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, DatePicker, Button } from "antd";
import {
  getTypeAndDateShopIdPagination,
  getItemsByTypeFunc,
  getItemsByDatePagination,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import * as queries from "../graphql/queries";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { CSVLink } from "react-csv";
import GenLeadReportPDF from "./genLeadReportPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReportExpense() {
  const navigate = useNavigate();
  const [shopId, setShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [allOutlets, setAllOutlets] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(true);
  const { RangePicker } = DatePicker;

  const headersStatus = [
    { label: "Month", key: "mnth" },
    { label: "Year", key: "year" },
    { label: "Total", key: "total" },
    { label: "New", key: "new" },
    { label: "Follow Up", key: "followUp" },
    { label: "Converted To Order", key: "converted" },
    { label: "Cancelled", key: "cancelled" },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getAllItemsByType(user.attributes["custom:shopId"]);
          await getLeadsByOutlet(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId) => {
    let temp = {};
    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].total = 0;
      temp[key].new = 0;
      temp[key].followUp = 0;
      temp[key].converted = 0;
      temp[key].cancelled = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getTypeAndDateShopIdPagination(
        "Lead",
        shopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + 1;
          if (item.status == "live") value.new = value.new + 1;
          else if (item.status == "Follow Up")
            value.followUp = value.followUp + 1;
          else if (item.status == "Convert to Quote")
            value.converted = value.converted + 1;
          else if (item.status == "Cancelled")
            value.cancelled = value.cancelled + 1;
        });
    }

    // Convert to an array and sort it
    let sortedArray = Object.values(temp).sort((a, b) => {
      // Compare years first
      if (a.year !== b.year) {
        return a.year - b.year;
      }
      // If years are the same, compare months
      return a.mnth - b.mnth;
    });

    setReportList(sortedArray);
  };

  const getLeadsByOutlet = async (shopId) => {
    let temp = {};
    let res = [];
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      res = [allTodos.data.getItemById];
    } catch (error) {
      alert(JSON.stringify(error));
    }

    let res1 = await getItemsByTypeFunc("Outlet", shopId);
    let allOutlets = [...res, ...res1];
    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].yr = EDDate.format("YY");
      temp[key].total = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let tempLocation = {};
      for (const item of allOutlets) {
        if (tempLocation[item.location] == undefined) {
          tempLocation[item.location] = {};
          tempLocation[item.location].total = 0;
        }
        let listLeads = await getItemsByDatePagination(
          "Lead",
          item.id,
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD")
        );
        // console.log(item.id + " item.id " + listLeads.length);
        tempLocation[item.location].total = listLeads.length;
      }
      let key = value.mnth + value.year;
      temp[key].location = tempLocation;
    }
    // Convert to an array and sort it
    let sortedArray = Object.values(temp).sort((a, b) => {
      // Compare years first
      if (a.year !== b.year) {
        return a.year - b.year;
      }
      // If years are the same, compare months
      return a.mnth - b.mnth;
    });

    setAllOutlets(sortedArray);
    setSpinnerState(false);
  };

  const downloadPDF = async () => {
    await pdf(
      <GenLeadReportPDF allOutlets={allOutlets} dateRange={dateRange} />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Leads-Outlet-${dateRange[0].format("MMM YY")}-${dateRange[1].format(
            "MMM YY"
          )}.pdf`
        );
      });
  };

  useEffect(() => {
    if (shopId != "") {
      setReportList([]);
      setAllOutlets([]);
      setSpinnerState(true);
      getAllItemsByType(shopId);
      getLeadsByOutlet(shopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ marginRight: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
          {reportList.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={reportList}
                headers={headersStatus}
                filename={
                  "Leads-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card style={{ width: "100%" }}>
              <h2>Leads count by status for all outlets</h2>
              <div style={{ display: "flex" }}>
                <div style={{ width: 150 }}>
                  <p style={{ fontWeight: "bold" }}>Status</p>
                  <p style={{ fontWeight: "bold" }}>Total</p>
                  <p>New</p>
                  <p>Follow Up</p>
                  <p>Converted to Quote</p>
                  <p>Cancelled</p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div style={{ width: 150 }}>
                        <p style={{ fontWeight: "bold" }}>
                          {item.month}-{item.year}
                        </p>
                        <p style={{ fontWeight: "bold" }}>{item.total}</p>
                        <p>{item.new}</p>
                        <p>{item.followUp}</p>
                        <p>{item.converted}</p>
                        <p>{item.cancelled}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>Leads count by all outlets</h2>
                {allOutlets.length > 0 && (
                  <Button
                    style={{ paddingRight: 25 }}
                    onClick={() => downloadPDF()}
                  >
                    Download PDF
                  </Button>
                )}
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <p style={{ width: 250 }}></p>
                  {allOutlets.map((item) => {
                    return (
                      <p style={{ width: 100, fontWeight: "bold" }}>
                        {item.month}-{item.yr}
                      </p>
                    );
                  })}
                </div>
                <div style={{ display: "flex" }}>
                  {allOutlets.length > 0 && (
                    <div style={{ width: 250 }}>
                      {Object.entries(allOutlets[0].location).map(
                        ([key, value]) => {
                          return <p style={{ fontWeight: "bold" }}>{key}</p>;
                        }
                      )}
                    </div>
                  )}
                  {allOutlets.map((item) => {
                    return (
                      <>
                        <div style={{ width: 100 }}>
                          {Object.entries(item.location).map(([key, value]) => {
                            return <p>{value.total}</p>;
                          })}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
