import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  message,
  Table,
  Modal,
  Divider,
  Select,
  Card,
  Input,
} from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { api } from "../index";
import { v4 as uuidv4 } from "uuid";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";
const { Option } = Select;

function AddProductGroups(props) {
  const navigate = useNavigate();
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [term, setTerm] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [itemTags, setItemTag] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesURI, setImagesURI] = useState([]);
  const [imagesBase64, setImagesBase64] = useState("");
  const [pricePerSQFT, setPricePerSQFT] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [outletSQFTMap, setOutletSQFTMap] = useState({});
  const [HSN, setHSN] = useState("");
  const [profileList, setProfileList] = useState([]);
  const [profile, setProfile] = useState("");
  const [formula, setFormula] = useState("");
  const [less, setLess] = useState(0);
  const [more, setMore] = useState(0);

  const OPTIONS = props.common.tagArray;
  const filteredOptions = OPTIONS.filter((o) => !itemTags.includes(o));
  let test = useParams();

  const columns = [
    {
      title: "Material Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    ...(props.common.shopType === "master"
      ? [
          {
            title: "Delete",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id) => (
              <Button type="primary" danger onClick={() => removeComponent(id)}>
                {Object.keys(componentList).includes(id) ? "Remove" : "Add"}
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "masterPP",
      key: "masterPP",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addComponent(record)}
          style={{
            width: "100%",
            backgroundColor: Object.keys(componentList).includes(id)
              ? "#D22B2B"
              : "",
          }}
        >
          {Object.keys(componentList).includes(id) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewGroup")
            await getItemById(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("Product")) navigate("/homePage");
  }, []);

  const getItemById = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        let map = JSON.parse(res.outletSQFTMap);
        setItemName(res.itemName);
        setItemDescription(res.itemDescription);
        setComponentList(JSON.parse(res.componentList));
        setItemTag(res.itemTags);
        setImages(res.itemImages ?? []);
        setImagesURI(res.itemImages ?? []);
        setOutletSQFTMap(map);
        setPricePerSQFT(
          map[outletShopId] != undefined ? map[outletShopId].pricePerSQFT : 0
        );
        setItemCode(res.itemCode);
        setHSN(res.HSN ?? "");
        setProfileList(res.profileList ? JSON.parse(res.profileList) : []);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };
  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };
  const addItem = async (image) => {
    let temp = {
      [outletShopId]: { pricePerSQFT: pricePerSQFT },
    };
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          itemName: itemName,
          itemDescription: itemDescription,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "Group",
          itemTags: itemTags,
          lockForUpdate: "false",
          itemImages: image ?? images,
          itemCode: itemCode,
          outletSQFTMap: JSON.stringify(temp),
          HSN: HSN,
          profileList: JSON.stringify(profileList),
        },
      });
      message.error("Product Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
      // message.error(JSON.stringify(error));
    }
  };

  const editItem = async (image) => {
    try {
      await API.graphql({
        query: mutations.updateGroup,
        variables: {
          id: test.test,
          itemName: itemName,
          itemDescription: itemDescription,
          outletShopId: outletShopId,
          componentList: JSON.stringify(componentList),
          updatedBy: updatedBy,
          itemTags: itemTags,
          itemImages: image ?? images,
          pricePerSQFT: pricePerSQFT,
          shopId: shopId,
          itemCode: itemCode,
          outletSQFTMap: JSON.stringify(outletSQFTMap),
          HSN: HSN,
          profileList: JSON.stringify(profileList),
        },
      });
      message.error("Product updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (itemName === "") return message.error("Please enter name");
    if (itemDescription === "")
      return message.error("Please enter description");
    if (Object.keys(componentList).length == 0)
      return message.error("Please add materials list to the product");
    if (isNaN(pricePerSQFT))
      return message.error("Please enter valid price per sqft");
    if (HSN === "") return alert("Please enter HSN code");
    setSpinnerState("true");

    var tempImage = undefined;
    if (
      (test.test === "addNewGroup" && imagesURI.length > 0) ||
      (test.test != "addNewGroup" && imagesURI != images)
    )
      try {
        const response = await fetch(api + "uploadImage", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            base64: imagesBase64,
            fileName: uuidv4() + ".jpeg",
          }),
        });
        const responseJson = await response.json();
        tempImage = responseJson.Location;
      } catch (error) {
        console.error(error);
      }

    if (test.test === "addNewGroup") addItem(tempImage);
    else editItem(tempImage);
  };

  const onImageChange = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setImagesURI(URL.createObjectURL(files[0]));
    reader.onload = (e) => {
      setImagesBase64(e.target.result.replace(/^data:image\/\w+;base64,/, ""));
    };
  };

  const addComponent = (item) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(item.id)) {
      delete tempList[item.id];
    } else {
      tempList[item.id] = {};
      tempList[item.id].id = item.id;
      tempList[item.id].itemName = item.itemName;
      tempList[item.id].itemUOM = item.itemUOM;
    }
    setComponentList(tempList);
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
  };

  const addProfile = () => {
    if (profile == "" || formula == "")
      return alert("Please select both profile and formula");
    let tempList = [...profileList];
    let item = {};
    item.profile = profile;
    item.formula = formula;
    item.less = less;
    item.more = more;
    tempList.push(item);
    setProfileList(tempList);
    setProfile("");
    setFormula("");
    setLess(0);
    setMore(0);
  };

  const removeProfile = (index) => {
    let tempList = [...profileList];
    tempList.splice(index, 1);
    setProfileList(tempList);
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1200px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: 20,
              }}
            >
              <h3 className="bill-heading">Enter Product Details</h3>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {props.common.shopType === "master" && (
              <Button
                style={{ marginRight: "8px", paddingRight: 25 }}
                onClick={() => setModalAddComponents(true)}
              >
                {" "}
                <PlusOutlined /> Add Materials
              </Button>
            )}
            <Button
              style={{ paddingRight: 25 }}
              disabled={spinnerState}
              onClick={() => checkInput()}
            >
              {" "}
              <PlusOutlined />
              {test.test === "addNewGroup" ? `Add Product` : `Update Product`}
            </Button>
          </Col>
        </Row>
        <div className="divider"></div>
        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "220px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Product Name:
                </label>
                {(props.common.shopType === "master" && (
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Product Name"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    maxLength={100}
                  />
                )) || (
                  <label
                    style={{
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    {itemName}
                  </label>
                )}
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "220px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Product Description:
                </label>
                {(props.common.shopType === "master" && (
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Product Description"
                    value={itemDescription}
                    onChange={(e) => setItemDescription(e.target.value)}
                    maxLength={300}
                  />
                )) || (
                  <label
                    style={{
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    {itemDescription}
                  </label>
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            {props.common.shopType === "master" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "220px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Tag for product:
                  </label>
                  <Select
                    mode="multiple"
                    placeholder="Tags for product"
                    value={itemTags}
                    onChange={setItemTag}
                    style={{ width: "56%", backgroundColor: "white" }}
                    options={filteredOptions.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </div>
              </Col>
            )}
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "220px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Price per sqft:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Price Per Sqft"
                  value={pricePerSQFT}
                  onChange={(e) => setPricePerSQFT(e.target.value)}
                  maxLength={10}
                />
              </div>
            </Col>
          </Row>
          {props.common.shopType === "master" && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "220px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Product Code:
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Price Code"
                    value={itemCode}
                    onChange={(e) => setItemCode(e.target.value)}
                    maxLength={10}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    HSN Code
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter Name"
                    onChange={(e) => setHSN(e.target.value)}
                    maxLength={100}
                    value={HSN}
                  />
                </div>
              </Col>
            </Row>
          )}
          {props.common.shopType === "master" && (
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1} style={{ marginTop: 20 }}>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  name="myImage"
                  onChange={onImageChange}
                />
                <div style={{ marginTop: -25, marginLeft: "58%" }}>
                  <img src={imagesURI} width={180} />
                </div>
              </Col>
            </Row>
          )}
        </Card>
        <div className="divider2"></div>
        {props.common.shopType === "master" && (
          <div style={{ marginTop: 20 }}>
            <Table
              dataSource={Object.values(componentList)}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
        <div className="divider2"></div>
        {props.common.shopType === "master" && (
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: "86%",
            }}
          >
            <Select
              style={{
                backgroundColor: "white",
                width: "20%",
                marginRight: 10,
              }}
              value={profile}
              onChange={(value) => setProfile(value)}
            >
              <Option value="">Select Profile</Option>
              {props.common.profileNames.map((key) => (
                <Option value={key}>{key}</Option>
              ))}
            </Select>
            <Select
              style={{
                backgroundColor: "white",
                width: "40%",
                marginRight: 10,
              }}
              value={formula}
              onChange={(value) => setFormula(value)}
            >
              <Option value="">Select Formula</Option>
              {props.common.formulaList.map((value) => (
                <Option value={value.formula}>{value.name}</Option>
              ))}
            </Select>
            <Input
              style={{
                backgroundColor: "white",
                width: "10%",
                marginRight: 10,
              }}
              placeholder="Enter less"
              value={less}
              onChange={(e) => setLess(e.target.value)}
              maxLength={10}
            />
            <Input
              style={{
                backgroundColor: "white",
                width: "10%",
                marginRight: 10,
              }}
              placeholder="Enter more"
              value={more}
              onChange={(e) => setMore(e.target.value)}
              maxLength={10}
            />
            <Button style={{ width: "10%" }} onClick={() => addProfile()}>
              Add
            </Button>
          </div>
        )}

        {props.common.shopType === "master" &&
          profileList.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "8px",
                  width: "80%",
                }}
              >
                <p style={{ width: "20%" }}>{item.profile}</p>
                <p style={{ width: "50%" }}>{item.formula}</p>
                <p style={{ width: "10%" }}>{item.less}</p>
                <p style={{ width: "10%" }}>{item.more}</p>
                <Button
                  style={{ width: "10%" }}
                  onClick={() => removeProfile(index)}
                >
                  Remove
                </Button>
              </div>
            );
          })}
      </div>

      <Modal
        visible={modalAddComponents}
        width="60%"
        style={customStylesAddProducts}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        <div className="bill">
          {/* <h1 className="bill-heading">Select Components</h1> */}
          <div className="bill-container">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={16} lg={12} offset={2}>
                <TextField
                  label="Search by name"
                  variant="outlined"
                  style={{ width: "80%" }}
                  margin="normal"
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} md={16} lg={5} style={{ paddingTop: "2%" }}>
                <Button
                  type="primary"
                  block
                  onClick={() => setModalAddComponents(false)}
                >
                  Add Materials
                </Button>
              </Col>

              <Col xs={24} md={16} lg={18} offset={2}>
                <Table
                  dataSource={
                    term === ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                  pagination={{ pageSize: 10 }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProductGroups);
