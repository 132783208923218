import { combineReducers } from "redux";

import commonReducer from "./common.reducer";
import sqftCompQuot from "./sqftComp.reducer";
import PIReducer from "./PI.reducer";

const rootReducer = combineReducers({
  common: commonReducer,
  sqft: sqftCompQuot,
  PI: PIReducer,
});

// export default rootReducer

export default (state, action) =>
  rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action);
