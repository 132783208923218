import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button, Switch } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getAllItemsPagination,
  getItemsByShopTypeFunc,
  getAllItemsPagination100,
  getItemsByShopTypeFunc100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ListGroups(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [dashboard, setDashboard] = useState({});
  const [followUpMap, setFollowUpMap] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      ellipsis: true,
      render: (name, record) => (
        <p
          style={{
            marginTop: -5,
            marginBottom: -5,
            fontWeight: record.status === "live" ? "bold" : "normal",
          }}
        >
          {name}
        </p>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      ellipsis: true,
      render: (status) => (status == "live" ? "Open" : status),
    },
    {
      title: "Assign To User",
      dataIndex: "assignToUser",
      key: "assignToUser",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Date",
      dataIndex: "addedDate",
      key: "addedDate",
      width: "8%",
      ellipsis: true,
      render: (addedDate) =>
        moment(addedDate, "YYYY-MM-DD").format("DD MMM YY"),
    },
    {
      title: "Follow Up",
      dataIndex: "followUpDate",
      key: "followUpDate",
      width: "8%",
      ellipsis: true,
      render: (followUpDate) =>
        moment(followUpDate, "YYYY-MM-DD").format("DD MMM YY"),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: "7%",
      ellipsis: true,
      render: (time) => moment(time, "HH:mm:ss").format("hh:mm A"),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      width: "7%",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddTicket/${id}`)}>View</a>
      ),
    },
  ];

  const ColumnsDashboard = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Open",
      dataIndex: "open",
      key: "open",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Closed",
      dataIndex: "closed",
      key: "closed",
      width: "10%",
      ellipsis: true,
    },
  ];

  const ColumnsFollow = [
    {
      title: "Follow Up Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date, "YYYY-MM-DD").format("DD MMM YY"),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          setShopId(user.attributes["custom:shopId"]);
          setAddedBy(user.attributes.email);
          if (props.common.shopType === "master")
            await getAllItemsByShopType(
              user.attributes["custom:shopId"],
              user.attributes.email
            );
          else await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Ticket")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = [];
    if (showAll) allItems = await getAllItemsPagination("Ticket", outletShopId);
    else allItems = await getAllItemsPagination100("Ticket", outletShopId);
    setAllItems(allItems);
  };

  const getAllItemsByShopType = async (shopId, addedBy) => {
    let temp = {};
    let tempFollow = {};

    for (let i = 0; i < 5; i++) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + i);
      const formattedDate = currentDate.toISOString().split("T")[0]; // Format to 'YYYY-MM-DD'
      tempFollow[formattedDate] = { date: formattedDate, count: 0 };
    }

    let allItems = [];
    if (showAll) allItems = await getItemsByShopTypeFunc("Ticket", shopId);
    else allItems = await getItemsByShopTypeFunc100("Ticket", shopId);
    if (props.common.shopType == "master" && props.common.role == "Telecallers")
      allItems = allItems.filter((item) => item.addedBy == addedBy);
    allItems.map((item) => {
      let user = item.assignToUser;
      if (temp[user] == undefined) {
        temp[user] = {};
        temp[user].user = user;
        temp[user].count = 0;
        temp[user].open = 0;
        temp[user].closed = 0;
      }
      temp[user].count = temp[user].count + 1;
      if (item.status == "live") temp[user].open = temp[user].open + 1;
      if (item.status == "Closed") temp[user].closed = temp[user].closed + 1;
      if (Object.keys(tempFollow).includes(item.followUpDate))
        tempFollow[item.followUpDate].count =
          tempFollow[item.followUpDate].count + 1;
    });

    setAllItems(allItems);
    setDashboard(temp);
    setFollowUpMap(tempFollow);
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      if (props.common.shopType === "master")
        await getAllItemsByShopType(shopId, addedBy);
      else await getAllItemsByType(outletShopId);
    };
    if (shopId != "" && outletShopId != "" && addedBy != "") getData();
  }, [showAll]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search Ticket"
            name="Search Ticket"
            variant="outlined"
            size="small"
            style={{ width: "40%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />

          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all Tickets"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />

          <Button type="primary">
            <Link to="../AddTicket/addNewTicket">
              <PlusOutlined /> New Ticket
            </Link>
          </Button>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <div
          style={{
            padding: 10,
          }}
        ></div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={ColumnsDashboard}
                  dataSource={Object.values(dashboard)}
                  scroll={{ y: 520 }}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={ColumnsFollow}
                  dataSource={Object.values(followUpMap)}
                  scroll={{ y: 520 }}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
