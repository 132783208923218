import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "../images/Logo.jpg";
import moment from "moment";

// Create Document Component
export default function GenerateDeliveryChalan(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Image src={Logo} style={{ width: 70, height: 70 }} />
            <Text>Delivery Challan</Text>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Name: {props.item.name}</Text>
              <Text>Phone: {props.item.phoneNumber}</Text>
              <Text>Bill to,</Text>
              <Text>{props.item.address}</Text>
              <Text>Ship to,</Text>
              <Text>{props.item.shippingAddress}</Text>
            </View>
            <View style={styles.topTextRight}>
              <Text>Reference ID: {props.item.randomId}</Text>
              <Text>Shop GST: {props.outletDetails.GSTNumber}</Text>
              <Text>
                Challan Date:{" "}
                {moment(new Date(), "YYYY-MM-DD").format("DD-MM-YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={{ width: "10%" }}>S No.</Text>
              <Text style={{ width: "20%" }}>Name</Text>
              <Text style={{ width: "20%" }}>Instructions</Text>
              <Text style={{ width: "20%" }}>Profile Clr</Text>
              <Text style={{ width: "20%" }}>Mesh Clr</Text>
              <Text style={{ width: "20%" }}>W (cm)</Text>
              <Text style={{ width: "20%" }}>H (cm)</Text>
              <Text style={{ width: "10%" }}>PC</Text>
              <Text style={{ width: "10%" }}>Qty</Text>
            </View>

            {Object.entries(JSON.parse(props.item.orderDetails)).map(
              ([key, value], index) => {
                return (
                  <>
                    <View style={styles.tableData}>
                      <Text style={{ width: "10%" }}>{index + 1}</Text>
                      <Text style={{ width: "20%" }}>{value.itemName}</Text>
                      <Text style={{ width: "20%" }}>{value.instructions}</Text>
                      <Text style={{ width: "20%" }}>{value.profileColor}</Text>
                      <Text style={{ width: "20%" }}>{value.meshColor}</Text>

                      <View style={{ width: "60%" }}>
                        {Object.values(value.dimensionMap)
                          .sort((a, b) => a.timestamp - b.timestamp)
                          .map((value1) => {
                            return (
                              <>
                                <View style={styles.tableData}>
                                  <Text style={{ width: "20%" }}>
                                    {value1.width}
                                  </Text>
                                  <Text style={{ width: "20%" }}>
                                    {value1.height}
                                  </Text>
                                  <Text style={{ width: "10%" }}>
                                    {value1.panelCount}
                                  </Text>
                                  <Text style={{ width: "10%" }}>
                                    {value1.quantity}
                                  </Text>
                                </View>
                              </>
                            );
                          })}
                      </View>
                    </View>
                  </>
                );
              }
            )}
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Recieved By,</Text>
              <Text>Name</Text>
              <Text>Phone</Text>
              <Text>Date</Text>
              <Text>Signature</Text>
            </View>
            <View style={styles.topTextRight}></View>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>Delivered By,</Text>
              <Text>Name</Text>
              <Text>Phone</Text>
              <Text>Vehicle Number</Text>
              <Text>Signature</Text>
            </View>
            <View style={styles.topTextRight}>
              <Text></Text>
              <Text>{props.driverDetails.name}</Text>
              <Text>{props.driverDetails.phone}</Text>
              <Text>{props.driverDetails.vehicleNumber}</Text>
              <Text></Text>
            </View>
          </View>
          <View style={styles.belowDetailsBold}>
            <Text>
              Thanking you and assuring you of our best services at all times.
            </Text>
            <Text>Yours faithfully,</Text>
            <Text>{"          "}</Text>
            <Text>{"          "}</Text>
            <Text>Signature</Text>
            <Text>{props.outletDetails.shopName}</Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 40,
    marginRight: 40,
    borderBottom: "1px solid black",
    height: 70,
  },
  headerText: {
    paddingTop: 10,
    paddingLeft: 10,
    fontSize: 9,
    alignContent: "flex-end",
    width: 400,
    color: "grey",
  },
  headerTextLeft: {
    width: 70,
  },
  headerTextRight: {
    width: 330,
  },
  topSection: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    padding: 10,
    fontSize: 10,
    border: "1px solid black",
  },
  topTextLeft: {
    textAlign: "left",
    width: "250",
    paddingBottom: 5,
  },
  topTextRight: {
    textAlign: "left",
    width: "200",
    paddingBottom: 5,
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#87CEEB",
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataBlue: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#87CEEB",
  },
  name: {
    padding: 2,
    width: 250,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 55,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 80,
    textAlign: "right",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    fontSize: 10,
    fontFamily: "Times-Bold",
  },
  leftPadding: {
    paddingLeft: 20,
  },
});
