import {
  ADD_SQFT_ITEM,
  REMOVE_SQFT_ITEM,
  SET_SQFT_BILL_ITEMS,
  SET_SQFT_COMPONENTS,
  ADD_SQFT_COMPONENT,
  REMOVE_SQFT_COMPONENT,
  CHANGE_SQFT_COM_COUNT,
  CHANGE_SQFT_COM_COLOR,
  SET_SQFT_EXTRA_CHARGES,
} from "../action";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  total: 0,
  subTotal: 0,
  items: {},
  groupTotal: 0,
  componentList: {},
  GST: 0,
  GSTAmount: 0,
  discountValue: 0,
  discountType: "",
  discountAmount: 0,
  extraCharges: 0,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SQFT_ITEM:
      //add
      var tempObj = state.items;
      var amount = state.total;
      var subTotal = state.subTotal;
      var id = "";
      var discount = 0;
      var GSTAmount = 0;

      if (Object.keys(state.items).includes(action.payload.key)) {
        id = action.payload.key;
        subTotal =
          subTotal +
          parseFloat(action.payload.total) -
          parseFloat(action.payload.item.itemTotal);
      } else {
        id = action.payload.type == "Component" ? action.payload.key : uuidv4();
        tempObj[id] = {};
        tempObj[id].key = id;
        tempObj[id].id = action.payload.item.id;
        tempObj[id].itemName = action.payload.item.itemName;

        subTotal = subTotal + parseFloat(action.payload.total);
      }
      tempObj[id].itemDescription = action.payload.item.itemDescription;
      tempObj[id].itemImages = action.payload.item.itemImages;
      tempObj[id].HSN = action.payload.item.HSN ?? "";
      tempObj[id].componentList = action.payload.componentList;
      tempObj[id].itemPrice = action.payload.price;
      tempObj[id].itemTotal = action.payload.total;
      tempObj[id].areaInSqFt = action.payload.areaInSqFt;
      tempObj[id].meshColor = action.payload.meshColor;
      tempObj[id].profileColor = action.payload.profileColor;
      tempObj[id].pricePerSQFT = action.payload.pricePerSQFT;
      tempObj[id].dimensionMap = action.payload.dimensionMap;
      tempObj[id].instructions = action.payload.instructions;
      tempObj[id].profileList = action.payload.item.profileList;
      tempObj[id].count = action.payload.count;
      tempObj[id].color = action.payload.color;
      tempObj[id].type = action.payload.type;

      if (state.discountValue != "")
        if (state.discountType === "flat") discount = state.discountValue;
        else
          discount =
            (parseFloat(state.discountValue) / 100) * parseFloat(subTotal);

      amount =
        parseFloat(subTotal) -
        parseFloat(discount) +
        parseFloat(state.extraCharges);

      if (state.GST != "")
        GSTAmount = (parseFloat(state.GST) / 100) * parseFloat(amount);
      amount = parseFloat(amount) + parseFloat(GSTAmount);

      return {
        ...state,
        items: tempObj,
        total: amount,
        subTotal: subTotal,
        discountAmount: discount,
        GSTAmount: GSTAmount,
      };

    // break;
    case REMOVE_SQFT_ITEM:
      //remove
      var tempObj = state.items;
      var amount = state.total;
      var subTotal = state.subTotal;
      var discount = 0;
      var GSTAmount = 0;

      subTotal = subTotal - parseFloat(tempObj[action.payload].itemTotal);
      delete tempObj[action.payload];

      if (state.discountValue != "")
        if (state.discountType === "flat") discount = state.discountValue;
        else
          discount =
            (parseFloat(state.discountValue) / 100) * parseFloat(subTotal);

      amount =
        parseFloat(subTotal) -
        parseFloat(discount) +
        parseFloat(state.extraCharges);
      if (state.GST != "")
        GSTAmount = (parseFloat(state.GST) / 100) * parseFloat(amount);
      amount = parseFloat(amount) + parseFloat(GSTAmount);

      return {
        ...state,
        items: tempObj,
        total: amount,
        subTotal: subTotal,
        discountAmount: discount,
        GSTAmount: GSTAmount,
      };

    case SET_SQFT_BILL_ITEMS:
      return {
        ...state,
        items: action.payload.orderDetails,
        total: action.payload.totalAmount,
        subTotal: action.payload.subTotal,
        GST: action.payload.GST,
        GSTAmount: action.payload.GSTAmount,
        discountValue: action.payload.discountValue,
        discountType: action.payload.discountType,
        discountAmount: action.payload.discountAmount,
        extraCharges: action.payload.extraCharges,
      };

    case SET_SQFT_COMPONENTS:
      return {
        ...state,
        componentList: action.payload.componentList,
        groupTotal: action.payload.groupTotal,
      };

    case ADD_SQFT_COMPONENT:
      //add
      var tempObj = state.componentList;
      var id = uuidv4();
      var item = action.payload.value;

      tempObj[id] = {};
      tempObj[id].id = action.payload.value.id;
      tempObj[id].key = id;
      tempObj[id].itemName = item.itemName;
      tempObj[id].itemUOM = item.itemUOM;
      tempObj[id].color = "Other";
      tempObj[id].count = 1;

      return {
        ...state,
        componentList: tempObj,
      };

    case REMOVE_SQFT_COMPONENT:
      var tempObj = state.componentList;
      delete tempObj[action.payload.id];

      return {
        ...state,
        componentList: tempObj,
      };

    case CHANGE_SQFT_COM_COUNT:
      var tempObj = state.componentList;
      if (action.payload.count != "" && action.payload.count != ".") {
        tempObj[action.payload.id].count = action.payload.count;
      } else {
        tempObj[action.payload.id].count = action.payload.count;
      }

      return {
        ...state,
        componentList: tempObj,
      };

    case CHANGE_SQFT_COM_COLOR:
      var tempObj = state.componentList;
      tempObj[action.payload.id].color = action.payload.color;

      return {
        ...state,
        componentList: tempObj,
      };

    case SET_SQFT_EXTRA_CHARGES:
      var amount = state.total;
      var subTotal = state.subTotal;
      var discount = 0;
      var GSTAmount = 0;
      if (action.payload.discountValue != "")
        if (action.payload.discountType === "flat")
          discount = action.payload.discountValue;
        else discount = (action.payload.discountValue / 100) * subTotal;
      amount =
        parseFloat(subTotal) -
        parseFloat(discount) +
        parseFloat(action.payload.extraCharges);

      if (action.payload.GST != "") {
        GSTAmount = (parseFloat(action.payload.GST) / 100) * parseFloat(amount);
        amount = parseFloat(amount) + parseFloat(GSTAmount);
      }

      return {
        ...state,
        total: amount,
        subTotal: subTotal,
        GST: action.payload.GST,
        GSTAmount: GSTAmount,
        discountValue: action.payload.discountValue,
        discountType: action.payload.discountType,
        discountAmount: discount,
        extraCharges: action.payload.extraCharges,
      };

    default:
      return state;
  }
}
