import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { store, persistor } from "./component/store";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";

// dev
// Amplify.configure({
//   Auth: {
//     region: "ap-south-1",
//     userPoolId: "ap-south-1_PiN0tLxnS",
//     userPoolWebClientId: "2sv7b6176t7jv7lqm93itrfiak",
//   },
//   aws_appsync_graphqlEndpoint:
//     "https://okgwmpqqxffzbbhcs3w4jgia2a.appsync-api.ap-south-1.amazonaws.com/graphql",
//   aws_appsync_region: "ap-south-1",
//   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//   ssr: true,
// });

// export const api =
//   "https://t1oyp28nnd.execute-api.ap-south-1.amazonaws.com/dev/";

// prod;
Amplify.configure({
  Auth: {
    region: "ap-south-1",
    userPoolId: "ap-south-1_fXJUJ5ooS",
    userPoolWebClientId: "6ablhmom20b0ambe34he2lg72h",
  },
  aws_appsync_graphqlEndpoint:
    "https://celcdww3enfgvjhc3w4zkgqwze.appsync-api.ap-south-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-south-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  ssr: true,
});

export const api =
  "https://wvqbxn1cvb.execute-api.ap-south-1.amazonaws.com/prod/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Encode Sans', sans-serif",
        },
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
