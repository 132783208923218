import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Select, Switch } from "antd";
import { getAllItemsPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import { SpinnerCircular } from "spinners-react";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [outletShopId, setOutletShopId] = useState("");
  const [term, setTerm] = useState("");
  const [date, setDate] = useState("Today");
  const [spinnerState, setSpinnerState] = useState(false);
  const [type, setType] = useState("Lead");
  const { Option } = Select;
  const dateArray = ["Past", "Today", "Up-Coming"];

  const today = moment(new Date());

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: type + " Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "12%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
    },
    {
      title: "Follow Up Date",
      dataIndex: "followUpDate",
      key: "followUpDate",
      ellipsis: true,
      render: (followUpDate) => moment(followUpDate).format("DD-MMM-YY"),
    },
    {
      title: "Lead Source",
      dataIndex: "leadSource",
      key: "leadSource",
      ellipsis: true,
    },
    ...(type == "Quotation"
      ? [
          {
            title: "Total",
            dataIndex: "totalAmount",
            key: "totalAmount",
            ellipsis: true,
            width: "10%",
            render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Reports")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    setSpinnerState(true);
    let allItems = await getAllItemsPagination(type, outletShopId);
    allItems = allItems.filter((item) => item.status == "Follow Up");
    if (type == "Lead") {
      allItems = allItems.filter((item) => item.followUpDate);
      if (date == "Today")
        allItems = allItems.filter(
          (item) =>
            moment(item.followUpDate).diff(
              today.format("YYYY-MM-DD"),
              "days"
            ) == 0
        );
      else if (date == "Past")
        allItems = allItems.filter(
          (item) =>
            moment(item.followUpDate).diff(today.format("YYYY-MM-DD"), "days") <
            0
        );
      else if (date == "Up-Coming")
        allItems = allItems.filter(
          (item) =>
            moment(item.followUpDate).diff(today.format("YYYY-MM-DD"), "days") >
            0
        );
    }
    setReportList(allItems);
    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "") {
      setReportList([]);
      getAllItemsByType(outletShopId);
    }
  }, [date, type]);

  const toggleButton = () => {
    setType(type == "Lead" ? "Quotation" : "Lead");
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search leads"
            variant="outlined"
            size="small"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          {type == "Lead" && (
            <Select
              value={date}
              onChange={(value) => setDate(value)}
              style={{
                width: 150,
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 20,
              }}
            >
              {dateArray.map((item) => {
                return <Option value={item}>{item}</Option>;
              })}
            </Select>
          )}
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 100,
              marginRight: -150,
              width: 250,
            }}
          >
            {type}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={type}
            onChange={toggleButton}
          />
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? reportList
                      : reportList.filter(
                          (item) =>
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.location
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
